  import { createApi } from "@reduxjs/toolkit/query/react";
import  baseQueryWithReauth  from "../baseQuery";

export const feedsApi = createApi({
  reducerPath: "feedApi",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    listFeedData: builder.query({
      query: (page = 1) => ({
        url: `/posts`,
        method: "GET",
        params: {
          sortBy: "createdAt:desc",
          limit: 10,
          page: page,
        },
      }),
    }),
    getAllSuggestedUsers: builder.query({
      query: () => ({
        url: `/users/suggested-users/list?limit=10`,
        method: "GET",
      }),
    }),
    getUpComingEvents: builder.query({
      query: () => ({
        url: `/crew/event/all/user-joined?event=HAPPENINGSOON`,
        method: "GET",
      }),
    }),
    followUser: builder.mutation({
      query: (value) => ({
        url: `/users/follow/user`,
        method: "POST",
        body: value,
      }),
    }),
    reactionUserList: builder.query({
      query: (postId) => ({
        url: `/Reactions/${postId}`,
        method: "GET",
      }),
    }),
    feedStories: builder.query({
      query: () => ({
        url: `/story/get-story/list`,
        method: "GET",
        // params: {
        //   ownStory: true,
        // },
      }),
    }),
    deleteFeedStories: builder.mutation({
      query: (id) => ({
        url: `/posts/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useListFeedDataQuery,
  useGetAllSuggestedUsersQuery,
  useFollowUserMutation,
  useReactionUserListQuery,
  useGetUpComingEventsQuery,
  useFeedStoriesQuery,
  useDeleteFeedStoriesMutation,
} = feedsApi;
