import React from "react";
import { useLocation } from "react-router";
import _debounce from "lodash/debounce";
import AllNearCrews from "./AllNearCrews";
import AllRecomendedCrew from "./AllRecomendedCrew";

const CrewLists = ({ isMobile, isTablet }) => {
  const { state } = useLocation();
  // console.log("state---->>>", state);
  return (
    <>
      {state && state?.nearYou ? (
        <AllNearCrews isMobile={isMobile} isTablet={isTablet} />
      ) : (
        <AllRecomendedCrew isMobile={isMobile} isTablet={isTablet} />
      )}
    </>
  );
};

export default CrewLists;
