import React, { useState, useEffect, useCallback } from "react";
import Comments from "@components/Comments/Comments";
import "./FeedsPage.scss";
import InstagramFeedMain from "@components/Skeleton/InstagramFeedMain";
import {
  useFeedStoriesQuery,
  useListFeedDataQuery,
} from "@redux/services/mainFeed/feedService";
import FeedHeader from "./subComponents/feedHeader";
import MediaShow from "./subComponents/mediaShow";
import LikeModal from "@components/Modal/LikeModal";
import SpinnerLoader from "@components/Skeleton/SpinnerLoader";
// import InfiniteScroll from "react-infinite-scroll-component";
import useInfiniteScroll from "react-infinite-scroll-hook";
import Stories from "./subComponents/Stories";
import Spotlight from "./components/Spotlight";
import MainFeedStoriesSkeleton from "@components/Skeleton/MainFeedStoriesSkeleton";
import { useDispatch, useSelector } from "react-redux";
import { handleLatestPost, setFeedItems } from "@redux/slices/feeds/feedSlice";

const FeedsPage = ({ isTablet, isMobile, socket }) => {
  const items = useSelector((state) => state?.feed.items);
  const loginUserInfo = useSelector((state) => state?.auth?.loginUserData);

  const dispatch = useDispatch();
  /**
   * USESTATES
   */
  const [page, setPage] = useState(1);
  const [shouldRefetch, setShouldRefetch] = useState(false);

  // const [items, setItems] = useState([]);
  // console.log("res---->>>", items);

  const [isOpen, setIsOpen] = useState(false);
  const [latestPost, setLatestPost] = useState("");

  /**
   * API CALLS
   */
  // const [listHomeFeed, { data: { data } = [], isFetching, isLoading }] =
  //   useLazyListFeedDataQuery();
  const {
    data,
    error,
    isLoading,
    isFetching,
    refetch: refetchLatest,
  } = useListFeedDataQuery(shouldRefetch ? 1 : page);

  const {
    data: { data: { docs: feedStories } = {} } = [],
    refetch,
    isFetching: isStoriesLoading,
  } = useFeedStoriesQuery();

  /**
   * FUNCTIONS
   */
  // const fetchFeedResult = useCallback(async () => {
  //   try {
  //     const res = await listHomeFeed(page).unwrap();
  //     setItems((prev) => [...prev, ...res?.data?.docs]);
  //   } catch (error) {
  //     console.log("error", error);
  //   }
  // }, [page]);

  // const loadMore = useCallback(() => {
  //   if (!isFetching && data?.nextPage) {
  //     setPage(data?.nextPage);
  //   }
  // }, [isFetching, data?.nextPage]);

  const loadMore = () => {
    if (!isFetching && data?.data?.nextPage) {
      setPage(data?.data?.nextPage);
    }
  };

  const [sentryRef] = useInfiniteScroll({
    loading: isFetching,
    hasNextPage: !!data?.data?.nextPage,
    onLoadMore: loadMore,
    disabled: !!error,
    rootMargin: "0px 0px 400px 0px",
  });
  /**
   * FUNCTIONS
   */
  const handleNewPost = (data) => {
    setLatestPost(data);
    setShouldRefetch(true);
  };
  // Behavior: smooth keeps it smooth!
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const hanldeLatest = () => {
    setShouldRefetch(false);
    refetchLatest();
    setLatestPost("");
    scrollToTop();
  };

  /**
   * USEEFFECT
   */
  useEffect(() => {
    if (data) {
      if (page < 2) {
        dispatch(setFeedItems(data?.data?.docs));
      } else {
        dispatch(setFeedItems([...items, ...data?.data?.docs]));
      }
    }
  }, [data]);

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    socket.on("new-post", handleNewPost);
    return () => {
      socket.off("new-post", handleNewPost);
    };
  }, [socket]);

  return (
    <>
      {isStoriesLoading ? (
        <MainFeedStoriesSkeleton />
      ) : (
        (feedStories?.[0]?.stories?.length > 0 || feedStories?.length > 1) && (
          <Stories feedStories={feedStories} />
        )
      )}
      {isLoading ? (
        <>
          {Array.from({ length: 2 }).map((_, index) => (
            <InstagramFeedMain key={index} />
          ))}

          {/* ...Loading */}
        </>
      ) : (
        <>
          {/* {items?.length > 0 && ( */}
          <>
            {/* <InfiniteScroll
                style={{ overflow: "hidden" }}
                dataLength={items?.length}
                next={loadMore}
                hasMore={!!data?.nextPage}
                loader={<SpinnerLoader />}
              > */}
            <div>
              {latestPost.length > 0 && latestPost !== loginUserInfo?._id && (
                <div className="latest">
                  <span onClick={hanldeLatest}>Latest Post</span>
                </div>
              )}

              {items?.map((post, index) => (
                <>
                  <div
                    className="feed-block"
                    key={post?._id}
                    style={{
                      borderBottom:
                        items?.length - 1 !== index && "1px solid #323334",
                    }}
                  >
                    {post?.spotLight?.length > 0 ? (
                      <Spotlight
                        spotlight={post?.spotLight}
                        isUserProfile={false}
                      />
                    ) : (
                      <>
                        {/* HEADER SHOW */}
                        <FeedHeader
                          feedPost={post}
                          isMobile={isMobile}
                          isTablet={isTablet}
                          index={index}
                        />

                        {/* IMAGES AND CAROUSEL */}
                        <MediaShow feedPost={post} />
                        {/* COMMENTS SHOW */}
                        <Comments
                          post={post}
                          commentsCount={post?.commentsCount}
                          postId={post?._id}
                          isTablet={isTablet}
                          isComponent={true}
                        />
                      </>
                    )}

                    {/* TITLE AND DESCRIPTION */}
                    {/* {post?.title && (
                      <div className="show-more-title-post mt-2">
                        <ReadMore sliceText={70}>{post?.title}</ReadMore>
                      </div>
                    )}
                    {post?.description && (
                      <div className="show-more-title">
                        <ReadMore sliceText={140}>{post?.description}</ReadMore>
                      </div>
                    )} */}
                  </div>
                </>
              ))}
            </div>
            {/* </InfiniteScroll> */}

            <div ref={sentryRef} />
            {isFetching && <SpinnerLoader />}
            {isOpen && (
              <LikeModal open={isOpen} closeModal={() => setIsOpen(false)} />
            )}
          </>
          {/* )} */}
        </>
      )}
    </>
  );
};

export default FeedsPage;
