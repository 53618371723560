import React, { useState, useEffect } from "react";
import "./SuggestedYou.scss";
import { Link } from "react-router-dom";
import FollowButton from "./FollowButton";
import { useSelector } from "react-redux";
import { isValidUrl } from "@utils/validUrl";
import { BannerImg, DefaultUserImg } from "@utils/defaultImages";
import { sliceStringAtWord } from "@utils/sliceStringWord";
import {
  useFollowUserMutation,
  useGetAllSuggestedUsersQuery,
  useGetUpComingEventsQuery,
} from "@redux/services/mainFeed/feedService";
import { toast } from "react-toastify";
import { getOnlyDate, getOnlyMonth } from "@utils/DateFormatter";

export default function SuggestedYou() {
  const userData = useSelector((state) => state?.auth?.loginUserData);
  const { data: { data: { docs: suggestedUsers } = {} } = {} } =
    useGetAllSuggestedUsersQuery();
  const { data: { data: { events: { docs: upcomings } = {} } = {} } = {} } =
    useGetUpComingEventsQuery();

  const [followUser] = useFollowUserMutation();
  const [loadingState, setLoadingState] = useState({});
  const [followedUserIds, setFollowedUserIds] = useState([]);

  const followSuggestAccount = async (id) => {
    const body = {
      followToUserId: id,
    };
    setLoadingState((prev) => ({ ...prev, [id]: true }));
    try {
      await followUser(body).unwrap();
      setLoadingState((prev) => ({ ...prev, [id]: false }));
      setFollowedUserIds((prev) => [...prev, id]);
    } catch (error) {
      toast.error(error?.data?.message);
      setLoadingState((prev) => ({ ...prev, [id]: false }));
    }
  };

  const firstName = userData?.firstName || "";
  const lastName = userData?.lastName || "";
  const formattedFirstName =
    firstName.length > 15 ? sliceStringAtWord(firstName, 4) : firstName;
  const formattedLastName =
    lastName.length > 15 ? sliceStringAtWord(lastName, 5) : lastName;
  const fullName = formattedFirstName + " " + formattedLastName;

  const filteredSuggestedUsers = suggestedUsers?.filter(
    (user) => !followedUserIds.includes(user._id)
  );
  const checkLength =
    upcomings?.length > 0 || filteredSuggestedUsers?.length > 0;

  return (
    checkLength && (
      <div className="sticky-clm">
        {upcomings?.length > 0 && (
          <>
            <h2 className="upcomingEvents">Your Upcoming Events</h2>
            {upcomings?.map((event) => {
              // console.log("event-----???", event);

              return (
                <Link
                  to={`/crew-event/detail/${event?._id}`}
                  className="eventDetails"
                  key={event?._id}
                >
                  <div className="eventDiscription">
                    <div className="namee">

                      {/* {event?.crew?.name?.length > 15
                        ? `${event?.crew?.name?.slice(0, 15)}..`
                        : event?.crew?.name} */}
                        {event?.crew?.name}
                    </div>
                    <div className="eventTitle">
                      {/* {event?.title?.length > 12
                        ? `${event?.title.slice(0, 12)}..`
                        : event?.title} */}
                        {event?.title}
                    </div>
                    <div className="eventLocation">
                      {event?.location[0]?.name.length > 30
                        ? `${event?.location[0]?.name.slice(0, 30)}..`
                        : event?.location[0]?.name}
                    </div>
                    {/* { event?.location?.map((item2) => (
                  <div className="eventLocation">{item2?.name}</div>
                )) } */}
                    {event?.attendeeList?.length > 0 && (
                      <div className="eventAttendees">
                        {event?.attendeeList?.map((attendee, index) => (
                          <img
                            key={index}
                            src={
                              isValidUrl(attendee?.profileImage)
                                ? attendee?.profileImage
                                : BannerImg
                            }
                            alt={`Attendee ${index + 1}`}
                            className={`attendeeImage${index === 0 ? "1" : ""}`}
                          />
                        ))}
                        <span>{event?.attendeeList?.length} attending</span>
                      </div>
                    )}
                  </div>
                  <div style={{ borderLeft: "1px solid #323334" }}></div>
                  <div className="date">
                    <div className="month">
                      {getOnlyMonth(event?.startDateTime)}
                    </div>
                    <div className="datee">
                      {getOnlyDate(event?.startDateTime)}
                    </div>
                  </div>
                </Link>
              );
            })}
          </>
        )}
        {filteredSuggestedUsers?.length > 0 && (
          <div className="for-you-list">
            <h2>Suggested For You</h2>

            <>
              {filteredSuggestedUsers.map((user) => (
                <div className="user-profile-suggest" key={user?._id}>
                  <div>
                    <Link to={`/profile/${user?._id}`} className="profile-link">
                      <div className="foryou-user">
                        <div className="for-avtar">
                          <img
                            src={
                              isValidUrl(user?.profileImage)
                                ? user?.profileImage
                                : DefaultUserImg
                            }
                            alt=""
                            className="main-img"
                          />
                          <div className="name">
                            {`${user?.username?.slice(0, 6)}` ||
                              user?.firstName}
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div>
                    <FollowButton
                      userInfo={user}
                      followSuggestAccount={followSuggestAccount}
                      isLoading={loadingState[user?._id] || false}
                    />
                  </div>
                </div>
              ))}
            </>
          </div>
        )}
      </div>
    )
  );
}
