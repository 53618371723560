import CommentLoader from "@components/Skeleton/CommentLoader";
import {
  useCommentOnPostMutation,
  useLazyListSingleCommentQuery,
  useLazyMentionFollowersListQuery,
} from "@redux/services/commentsService/commentService";
import { Close, SendComment } from "@utils/defaultImages";
import { MentionsInput, Mention } from "react-mentions";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import SuggestedUser from "./SuggestedUser";
import { useDebounce, useDebouncedCallback } from "use-debounce";
import { sliceStringAtWord } from "@utils/sliceStringWord";
import SpinnerLoader from "@components/Skeleton/SpinnerLoader";
import { toast } from "react-toastify";

const tweetFieldStyle = {
  suggestions: {
    width: "100%",
    list: {
      backgroundColor: "#555",
      border: "1px solid rgba(0,0,0,0.15)",
      fontSize: 14,
      width: "100%",
    },
    item: {
      padding: "5px 15px",
      borderBottom: "1px solid rgba(0,0,0,0.15)",
      "&focused": {
        // backgroundColor: '#cee4e5',
        color: "#000",
      },
    },
  },
};
const mentionStyle = {
  color: "#387aff",
};

const CommentInput = ({
  post_id,
  setAllComments,
  commentsCount,
  setCommentsCount,
  replyData,
  setreplyData,
  commentList,
  selected,
  setSelected,
  addedReplyComment,
  setAddedREplyComment,
}) => {
  // Global Data
  const userData = useSelector((state) => state?.auth?.loginUserData);

  // USESTATES
  const [commentAdd, setCommentAdd] = useState("");
  const [storeMentionList, setStoreMentionList] = useState([]);
  const [mentionSelectId, setMentionSelectId] = useState([]);
  const [showUserReply, setShowUserReply] = useState(false);
  const MentionInputRef = useRef();
  /**
   * DEBOUNCE
   */
  // API calling
  const [mentionList, { isFetching }] = useLazyMentionFollowersListQuery();
  const [commentOnPost, { isLoading }] = useCommentOnPostMutation();
  const [listComment] = useLazyListSingleCommentQuery();
  // Store comments function
  const storeComments = async (e) => {
    e.preventDefault();
    const body = {
      commentText: commentAdd,
      mentionList: mentionSelectId,
      modelId: post_id,
      model: "post",
    };
    if (replyData) {
      if (!replyData.parentCommentId) {
        body.parentCommentId = replyData.id;
      } else {
        body.parentCommentId = replyData.parentCommentId;
      }
    }

    try {
      const response = await commentOnPost(body).unwrap();
      setCommentsCount((prevCount) => prevCount + 1);
      setStoreMentionList([]);
      setShowUserReply(false);

      if (response?.code === 200) {
        setCommentAdd("");
        if (!replyData) {
          const body = { commentId: response.data.id };
          const res = await listComment(body).unwrap();
          if (res?.code === 200) {
            // let data = res.data.docs[0];
            let newData = {
              ...res.data.docs[0],
              reactionCount: 0,
              replyCount: 0,
              isReacted: false,
            };
            setAllComments((prevComments) => [newData, ...prevComments]);
          }
        } else {
          if (selected.includes(response.data.parentCommentId)) {
            const body = { commentId: response.data.id };
            const res = await listComment(body).unwrap();
            setAddedREplyComment(res?.data?.docs[0]);
          }
          const updatedComments = commentList.map((item) => {
            if (item.id === response.data.parentCommentId) {
              return {
                ...item,
                replyCount: item.replyCount + 1,
              };
            }
            return item;
          });
          setAllComments(updatedComments);

          setreplyData(null);
        }
      }
    } catch (error) {
      setCommentsCount((prevCount) => prevCount + 1);
      toast.error(error?.data?.message)
    }
  };

  useEffect(() => {
    if (replyData) {
      if (replyData?.user?._id === userData?._id) {
        setCommentAdd("");
        setShowUserReply(true);
        setTimeout(() => {
          MentionInputRef.current?.focus();
        }, 0);
      } else {
        const formattedMention = `@[${replyData?.user?.username}](${replyData?.user?._id})`;
        setShowUserReply(false);
        setMentionSelectId([replyData?.user?._id]);
        setCommentAdd(formattedMention);
        setTimeout(() => {
          MentionInputRef.current?.focus();
        }, 0);
      }
    }
  }, [replyData]);

  // Handle fetching mention users
  const handleFetchMentionUsers = async (query, callback) => {
    const body = {
      userId: userData?._id,
      username: query,
      page: 1,
    };
    try {
      const response = await mentionList(body).unwrap();
      if (response?.code === 200) {
        const fetchedUsers = response?.data?.docs;
        setStoreMentionList(fetchedUsers);

        // Pass the fetched data to the callback for the mentions input
        const mentions = fetchedUsers.map((user) => ({
          id: user._id,
          display: user.username,
          profile: user?.profileImage,
        }));
        callback(mentions); // Trigger callback after the data is fetched
      }
    } catch (error) {
      toast.error(error?.data?.message)

    }
  };
  // Debounced fetchUsernames function
  // const fetchUsernames = useCallback(
  //   useDebouncedCallback(async (query, callback) => {
  //     const body = {
  //       userId: userData?._id,
  //       username: query,
  //       page: 1,
  //     };
      
  //     try {
  //       const response = await mentionList(body).unwrap();
  //       if (response?.code === 200) {
  //         const fetchedUsers = response?.data?.docs;
  //         setStoreMentionList(fetchedUsers);
  //         // Pass the fetched data to the callback for the mentions input
  //         const mentions = fetchedUsers.map((user) => ({
  //           id: user._id,
  //           display: user.username,
  //           profile: user?.profileImage,
  //         }));
  //         callback(mentions);
  //       }
  //     } catch (error) {
  //       console.log("Error fetching mention list", error);
  //     }
  //   }, 1000), // Adjust the debounce delay as needed
  //   [mentionList, userData]
  // );
  // Handle storing selected mentions
  const handleStoreMention = (mention) => {
    setMentionSelectId((prevMentions) => [...prevMentions, mention]);
  };
  const handleCloseReply = () => {
    setShowUserReply(false);
    setreplyData(null);
  };
  const handleChange = (e) => {
    const value = e.target.value;
    setCommentAdd(value);
    if (e.target.value === "") {
      if (replyData && replyData?.user?._id !== userData._id) {
        setreplyData(null);
      }
    }
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey && commentAdd?.trim()?.length > 0) {
      storeComments(e);
    }
  };
  return (
    <>
      <div className="comment-box-modal" style={{ position: "relative" }}>
        <MentionsInput
          value={commentAdd}
          onChange={(e) => handleChange(e)}
          // singleLine
          // style={{ height: "40px" }}
          style={tweetFieldStyle}
          inputRef={MentionInputRef}
          placeholder="Add a comment..."
          className="mentions-user"
          onKeyDown={(e)=>handleKeyDown(e)}
          forceSuggestionsAboveCursor={true}
          // style={defaultstyle}
          // allowSuggestionsAboveCursor={true}
          // suggestionsPortalHost={container}
        >
          <Mention
            // trigger="@"
            data={async(query, callback) => {
             await handleFetchMentionUsers(query, callback);
            }}
            // isLoading={isFetching}
            // style={mentionStyle}

            className="testing-check"
            markup="@[__display__](__id__)"
            renderSuggestion={(suggestion, search, highlightedDisplay) =>
              storeMentionList && <SuggestedUser suggestion={suggestion} />
            }
            onAdd={(e) => handleStoreMention(e)}
            displayTransform={(id, username) => `@${username}`}
          />
        </MentionsInput>
        <div className="sending">
          {isLoading ? (
            <CommentLoader />
          ) : (
            <button
              style={{ backgroundColor: "transparent", border: "none" }}
              disabled={commentAdd?.length === 0}
              onClick={storeComments}
            >
              {" "}
              <SendComment />
            </button>
          )}
        </div>
        {showUserReply && replyData && (
          <div
            style={{
              position: "absolute",
              right: "0",
              top: "-24px",
              fontSize: "small",
            }}
            onClick={() => setShowUserReply(false)}
          >
            Reply to{" "}
            {`${sliceStringAtWord(replyData?.user?.username, 20)}..           `}
            {
              <Close
                style={{ width: "20px", height: "20px", cursor: "pointer" }}
                onClick={handleCloseReply}
              />
            }
          </div>
        )}
      </div>
    </>
  );
};

export default CommentInput;
