import React, { useState } from "react";
import "./Comments.scss";
import { Link, useNavigate } from "react-router-dom";
import { dateTimeFromNow } from "@utils/DateFormatter";
import { sliceStringAtWord } from "@utils/sliceStringWord";
import { ThumbsUp, CommentIcon, LocationIcon } from "@utils/defaultImages";
import { useLazyListAllCommentsQuery } from "@redux/services/commentsService/commentService";
import LikedUsername from "../LikedBy/LikedUsername";
import { useLikedByUserMutation } from "@redux/services/reactionService/reactionService";
// import ShowAllComments from "./components/Chunks/showComments";
import { toast } from "react-toastify";
import ReadMore from "../ReadMore";
import { useDebouncedCallback } from "use-debounce";
import { useDispatch, useSelector } from "react-redux";
import { setLoginModel } from "@redux/slices/authSlice/publicSlice";
import CommentsPopUp from "@components/Modal/CommentsPopUp";
import { useMediaQuery } from "react-responsive";

const Comments = ({
  post = {},
  isTablet,
  postId,
  isComponent = false,
  isEvent = true,
}) => {
  /**
   * GET GLOBAL STATE
   */
  const userLoginToken = useSelector(
    (state) => state?.auth?.userAuthInfo?.access?.token
  );
  const navigate = useNavigate();
  const showLoginModel = useSelector((state) => state.loginModel.showModel);
  const dispatch = useDispatch();
  // console.log("post2222", post, isEvent);
  // {API CALLS}

  const [listAllComments, { data: { data = [] } = [], isLoading, isFetching }] =
    useLazyListAllCommentsQuery();
  const [likedByUser] = useLikedByUserMutation();
  //
  // {USESTATES}
  const [isLiked, setIsLiked] = useState(post?.isReacted || false); // Initial state for like status
  const [reactionCount, setReactionCount] = useState(post?.reactionsCount || 0); // Initial state for reaction count
  const [commentsCount, setCommentsCount] = useState(post?.commentsCount || 0); // Initial state for comments count
  const [showComments, setShowComments] = useState(false); // State to toggle comments visibility
  const [allComments, setAllComments] = useState([]);
  const isMobile = useMediaQuery({ maxWidth: "650px" })
  // const [page, setPage] = useState(1);

  //
  // useEffect(() => {
  //   if (page > 1) {
  //     setAllComments((prev) => {
  //       const mergedComments = [...prev, ...data?.docs]; // Prepend new comments to existing ones
  //       const uniqueComments = mergedComments?.reduce((acc, current) => {
  //         const x = acc?.find((item) => item?.id === current?.id);
  //         if (!x) {
  //           return acc?.concat([current]);
  //         } else {
  //           return acc;
  //         }
  //       }, []);
  //       return uniqueComments;
  //     });
  //   }
  // }, [data, page]);

  // { FUNCTIONS }
  const showCommentsList = async (page = 1) => {
    const body = {
      postId: post?.id || postId,
      page: page,
    };
    try {
      const response = await listAllComments(body).unwrap();
      setAllComments((prev) => [...prev, ...response?.data?.docs]);
      // if (page === 1) {
      //   setAllComments(response?.data?.docs);
      // }
      // if (response?.data?.hasNextPage) {
      //   setPage(response?.data?.nextPage);
      // }
    } catch (error) {
      toast.error(error?.data?.message);
    }
  };
  const handleToggle = () => {
    dispatch(setLoginModel(!showLoginModel));
  };
  // Toggle comments visibility
  const toggleCommentsVisibility = () => {
    if (!isEvent) {
      navigate(`/crew-event/detail/${post?._id}`);
    } else {
      if (userLoginToken) {
        setShowComments(!showComments);
        if (data?.length === 0) {
          showCommentsList();
        }
      } else {
        handleToggle();
      }
    }
  };

  // {LIKE AND UNLIKE REACTIONS}
  const likeAndUnlikeReaction = async () => {
    const newIsLiked = !isLiked;
    setIsLiked(newIsLiked);
    setReactionCount(reactionCount + (newIsLiked ? 1 : -1));

    const value = {
      reactionType: newIsLiked ? 1 : 0, // 0 for unlike, 1 for like
      modelId: post?._id,
      model: isEvent ? "post" : "event",
    };

    try {
      const response = await likedByUser(value);
      if (response?.data?.code !== 200) {
        // Revert the state back if the API call fails
        setIsLiked(!newIsLiked);
        setReactionCount(reactionCount + (newIsLiked ? -1 : 1)); // Revert the reaction count
      }
    } catch (error) {
      toast.error(error?.data?.message)

      // Revert the state back if the API call fails
      setIsLiked(!newIsLiked);
      setReactionCount(reactionCount + (newIsLiked ? -1 : 1)); // Revert the reaction count
    }
  };
  // const handleClickScroll = (id) => {
  //   const element = document.getElementById(id);
  //   if (element) {
  //     // 👇 Will scroll smoothly to the top of the next section
  //     element.scrollIntoView({ behavior: "smooth" });
  //   }
  // };
  // Handle updating comments data and count
  // const handleNewComment = () => {
  //   setCommentsCount((prevCount) => prevCount + 1);
  // };
  const PostType = [
    "Profile Post",
    "Vehicle Post",
    "Component Post",
    "Crew Post",
  ];
  // Debounce on like and unlike
  const debouncedLikeAndUnlikeReaction = useDebouncedCallback(
    likeAndUnlikeReaction,
    500
  );
  function handleLikeClick() {
    if (userLoginToken) {
      debouncedLikeAndUnlikeReaction();
    } else {
      handleToggle();
    }
  }
  return (
    <>
      <div className="comments-wrapper" style={{ padding: isMobile && "10px 15px" }}>
        <>
          {reactionCount > 0 && (
            <LikedUsername
              reacted={post}
              reactionCount={reactionCount}
              isLiked={isLiked}
            />
          )}
          {isComponent && post?.title && (
            <div className="show-more-title-post mt-2">
              <ReadMore sliceText={70} isShowMarginTop={false}>
                {post?.title}
              </ReadMore>
            </div>
          )}
          {isComponent && post?.description && (
            <div className="show-more-title mt-2">
              <ReadMore sliceText={140} isShowMarginTop={true}>
                {post?.description}
              </ReadMore>
            </div>
          )}
          <div className="total-coments mt-2" type="button">
            <div className="wrap-buttons" onClick={handleLikeClick}>
              <button
                style={{ border: "none", background: "none" }}
              // disabled={isDisableLike}
              >
                <ThumbsUp fill={isLiked ? "white" : "transparent"} />
              </button>
              {reactionCount}
            </div>
            <span
              onClick={toggleCommentsVisibility}
              className="comments-section"
            >
              <CommentIcon />
              {commentsCount}&nbsp;
            </span>
            {/* <div className="share">
              <Share />
              <span>Share</span>
            </div> */}
          </div>
        </>
        <>
          {isComponent && isEvent && (
            <Link>
              <div
                className="post-type"
                style={{
                  marginTop: "10px",
                }}
              >
                <span
                  style={{
                    color: post?.parentType === 2 ? "#52FF00" : "#387AFF",
                  }}
                >
                  {PostType[post.parentType]}
                </span>
                <span>•</span>
                <span>{dateTimeFromNow(post?.createdAt)}</span>
                {post?.location?.name && (
                  <>
                    <span style={{display:"flex",alignItems:"center",gap:"5px"}}>
                      <LocationIcon />
                      {isTablet
                        ? sliceStringAtWord(post?.location?.name, 10)
                        : post?.location?.name}
                    </span>
                  </>
                )}
              </div>
            </Link>
          )}
          <CommentsPopUp
            loaderOnUpdate={isFetching}
            commentLoading={isLoading}
            commentsCount={commentsCount}
            setCommentsCount={setCommentsCount}
            OnUpdate={showCommentsList}
            showMore={data}
            commentList={allComments}
            userData={post}
            openPopUp={showComments}
            closeModal={() => setShowComments(false)}
            setAllComments={setAllComments}
            postIsLiked={isLiked}
            setPostIsLiked={setIsLiked}
            PostReactionCount={reactionCount}
            setPostReactionCount={setReactionCount}
          />
          {/* <ShowAllComments
            showComments={showComments}
            dataComments={data}
            // storePrevComment={allComments}
            isTablet={isTablet}
            Loading={isLoading}
            postId={postId}
            onNewComment={handleNewComment}
            loadMore={showCommentsList}
            fetchingNextPageData={isFetching}
          /> */}
        </>
      </div>
    </>
  );
};

export default Comments;