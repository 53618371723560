import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import useMediaQueryHook from "@utils/hooks/useMediaQueryHook";
import { Player } from "@lottiefiles/react-lottie-player";
import { Crew, Home, Mods, ProfileLanding } from "@utils/defaultImages";
import ImgNextGen from "@components/imageOptimisation/ImgNextGen";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import ScrollToPlugin from "gsap/ScrollToPlugin";

const StyledIcon = styled.div`
  .logo-icon {
    path {
      fill: #8e8e93;
      transition: fill 0.3s ease;
    }
  }

  &.actived .logo-icon {
    path {
      fill: #ffffff;
    }
  }
`;

const Communities = ({ activeLink, setActiveLink, multiplePost }) => {
  const sliderRef = useRef(null);
  const wrapperRef = useRef(null);
  const timelineRef = useRef(null);
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  }, []);

  const handleNavClick = (index) => {
    if (isAnimating || !timelineRef.current) return;

    setIsAnimating(true);
    const st = ScrollTrigger.getById("slider-trigger");

    if (st) {
      const totalPanels = multiplePost.length;
      const panelWidth = st.end - st.start;
      const scrollPerPanel = panelWidth / (totalPanels - 1);
      const targetScroll = st.start + scrollPerPanel * index;

      gsap.to(window, {
        duration: 0.5,
        scrollTo: {
          y: targetScroll,
          autoKill: false,
        },
        ease: "power2.inOut",
        onUpdate: () => {
          if (isAnimating) {
            setActiveLink(index);
          }
        },
        onComplete: () => {
          setIsAnimating(false);
          setActiveLink(index);
        },
      });
    }
  };

  useEffect(() => {
    const slider = sliderRef.current;
    const panels = gsap.utils.toArray(".panel", slider);

    timelineRef.current = gsap.timeline({
      scrollTrigger: {
        id: "slider-trigger",
        trigger: slider,
        pin: true,
        pinSpacing: true,
        start: "top top",
        end: () => `+=${slider.scrollWidth - window.innerWidth}`,
        scrub: 1,
        snap: {
          snapTo: (value) => {
            // Ensure snapping aligns perfectly with panel divisions
            const snap = 1 / (panels.length - 1);
            return Math.round(value / snap) * snap;
          },
          duration: { min: 0.6, max: 0.4 },
          ease: "power1.inOut",
        },
        onUpdate: (self) => {
          if (!isAnimating) {
            const newActiveIndex = Math.round(
              self.progress * (panels.length - 1)
            );
            if (newActiveIndex >= 0 && newActiveIndex < panels.length) {
              setActiveLink(newActiveIndex);
            }
          }
        },
      },
    });

    timelineRef.current.to(panels, {
      xPercent: -100 * (panels.length - 1),
      ease: "none",
    });

    const handleResize = () => {
      ScrollTrigger.refresh();
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      timelineRef.current.kill();
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
    };
  }, []);

  const { isMobile, isTablet, isMobTab, isMobileSmall } = useMediaQueryHook();
  const responsiveScreen = isMobile || isTablet || isMobTab || isMobileSmall;

  return (
    <div className="slider-communities" ref={sliderRef} >
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12 d-flex justify-content-center">
            <div className="active-navLink my-5">
              {[Home, Crew, Mods, ProfileLanding].map((Icon, index) => (
                <StyledIcon
                  key={index}
                  className={`icons ${activeLink === index ? "actived" : ""}`}
                  onClick={() => handleNavClick(index)}
                  style={{
                    cursor: "pointer",
                    borderBottom:
                      activeLink === index ? "3px solid #387AFF" : "unset",
                    width: "100px",
                    transition: "all 0.3s ease",
                  }}
                >
                  <Icon className="logo-icon" />
                  <span
                    className="pt-2 pb-3"
                    style={{
                      color: activeLink !== index ? "#8e8e93" : "#ffffff",
                      fontWeight: activeLink !== index ? "300" : "600",
                      transition: "all 0.3s ease",
                    }}
                  >
                    {["Home", "Crew", "Mods", "Profile"][index]}
                  </span>
                </StyledIcon>
              ))}
            </div>
          </div>
        </div>

        <div className="slider-wrapper" ref={wrapperRef}>
          {multiplePost.map((post, index) => (
            <div
              key={post.key}
              className="panel container-fluid"
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div className="row" style={{ maxWidth: "1080px" }}>
                <div
                  className={`col-lg-6 col-md-12 col-sm-12 col-12 home-post ${
                    responsiveScreen ? "order-2" : "mb-5"
                  }`}
                >
                  {index === 1 ? (
                    <Player
                      src={post.LinkImg}
                      className="player"
                      loop
                      autoplay
                      style={{ height: !isMobile ? "500px" : "100%" }}
                    />
                  ) : (
                    <ImgNextGen
                      srcWebp={post.WebImg}
                      fallback={post.LinkImg}
                      alt="post"
                      className="img-fluid"
                    />
                  )}
                </div>
                <div
                  className={`col-lg-6 col-md-12 col-sm-12 col-12 d-flex flex-column justify-content-center wrap-links mb-5 ${
                    responsiveScreen ? "order-1" : ""
                  }`}
                >
                  <div className="share">
                    <h3>{post.title}</h3>
                    <p>{post.description}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Communities;
