import React from "react";
import { useSelector } from "react-redux";
import ErrorPage from "./ErrorPage";

const GlobalErrorBoundary = ({ children }) => {
  const error = useSelector((state) => state?.error?.error);
  if (error) {
    return <ErrorPage title={error.message} />; 
  }
  return <>{children}</>;
};

export default GlobalErrorBoundary;
