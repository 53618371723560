import React, { useState } from "react";

export default function ReadMore({ sliceText, isShowMarginTop = false, isBreakWord = false, children ,isUserChats = false}) {
  const [isReadMore, setIsReadMore] = useState(true);

  const getTextLengthAndLineBreaks = (children) => {
    let length = 0;
    let lineBreakCount = 0;

    const addLengthAndLineBreaks = (text) => {
      if (typeof text !== 'string') return;
      length += text.replace(/\n/g, ' ').length;
      lineBreakCount += (text.match(/\n/g) || []).length;
    };

    const validChildren = React.Children.toArray(children).filter(child => child !== '');

    React.Children.forEach(validChildren, (child) => {
      if (typeof child === "string") {
        addLengthAndLineBreaks(child);
      } else if (React.isValidElement(child) && typeof child.props.children === "string") {
        addLengthAndLineBreaks(child.props.children);
      }
    });

    return { length, lineBreakCount };
  };

  const renderTextWithLineBreaks = (text) => {
    if (typeof text !== 'string') return text;
    return text.split('\n').map((line, index) => (
      <React.Fragment key={index}>
        {line}
        {index < text.split('\n').length - 1 && <br />}
      </React.Fragment>
    ));
  };

  const renderGroupedChildren = (children, charLimit, lineBreakLimit) => {
    let currentLength = 0;
    let currentLineBreakCount = 0;
    const groupedChildren = [];

    const validChildren = React.Children.toArray(children).filter(child => child !== '');

    validChildren.forEach((child, index) => {
      if (currentLength >= charLimit || currentLineBreakCount >= lineBreakLimit) return;

      if (typeof child === "string") {
        const lines = child.split('\n');
        lines.forEach((line, lineIndex) => {
          if (currentLength >= charLimit || currentLineBreakCount >= lineBreakLimit) return;

          const remainingChars = charLimit - currentLength;
          if (line.length <= remainingChars) {
            groupedChildren.push(
              <React.Fragment key={`${index}-${lineIndex}`}>
                {lineIndex > 0 && <br />}
                {line}
              </React.Fragment>
            );
            currentLength += line.length;
            if (lineIndex > 0) currentLineBreakCount += 1;
          } else {
            groupedChildren.push(line.slice(0, remainingChars));
            currentLength += remainingChars;
          }
        });
      } else if (React.isValidElement(child)) {
        const innerText = child.props.children;
        if (typeof innerText === "string") {
          const lines = innerText.split('\n');
          lines.forEach((line, lineIndex) => {
            if (currentLength >= charLimit || currentLineBreakCount >= lineBreakLimit) return;

            const remainingChars = charLimit - currentLength;
            if (line.length <= remainingChars) {
              groupedChildren.push(
                <React.Fragment key={`${index}-${lineIndex}`}>
                  {lineIndex > 0 && <br />}
                  {child}
                </React.Fragment>
              );
              currentLength += line.length;
              if (lineIndex > 0) currentLineBreakCount += 1;
            } else {
              groupedChildren.push(
                React.cloneElement(child, { children: line.slice(0, remainingChars) })
              );
              currentLength += remainingChars;
            }
          });
        } else {
          groupedChildren.push(child);
        }
      }
    });

    return groupedChildren;
  };

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  const { length: contentLength, lineBreakCount } = getTextLengthAndLineBreaks(children);
  const showSeeMore = contentLength > sliceText || lineBreakCount > 3;

  const slicedContent = isReadMore
    ? renderGroupedChildren(children, sliceText, 3)
    : React.Children.map(children, (child) =>
        typeof child === "string"
          ? renderTextWithLineBreaks(child)
          : React.cloneElement(child, {
              children: renderTextWithLineBreaks(child.props.children),
            })
      );

  return (
    <p className={`text ${isShowMarginTop ? "" : "mt-3"}`} style={{ wordBreak: isBreakWord ? "break-word" : undefined ,padding:isUserChats &&"0"}}>
      {slicedContent}
      {showSeeMore && (
        <span onClick={toggleReadMore} className="read-or-hide pointer">
          {isReadMore ? " ...See more" : " ...See less"}
        </span>
      )}
    </p>
  );
}