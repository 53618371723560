import React, { useState } from "react";
import { BackArrow, ThreeDots } from "@utils/defaultImages";
import { useNavigate } from "react-router";
import NewMessage from "@assets/new.svg";
import NewMessageModal from "@components/Modal/NewMessageModal";
import { isValidUrl } from "@utils/validUrl";
import UserImg from "@assets/defaultUser.png";
import { Link } from "react-router-dom";
export default function MessengerHeader({ messengerUser }) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(!open);
  };
  const closeModal = () => {
    setOpen(!open);
  };
  const profileData = {
    url: messengerUser?.user?._id ?? messengerUser?._id,
    profileImg:
      messengerUser?.user?.profileImage ?? messengerUser?.profileImage,
    username: messengerUser?.user?.username ?? messengerUser?.username,
  };
  return (
    <>
      <div className="container p-0">
        <div className="messnger-header">
          <div className="messnger-header-items">
            <div className="back-button-mess" onClick={() => navigate(-1)}>
              <BackArrow style={{ cursor: "pointer" }} />
            </div>{" "}
            <Link
              to={`/profile/${profileData?.url}`}
              style={{ display: "flex", gap: "10px" }}
            >
              {messengerUser && (
                <img
                  src={
                    isValidUrl(profileData?.profileImg)
                      ? profileData?.profileImg
                      : UserImg
                  }
                  width={32}
                  height={32}
                  style={{ borderRadius: "32px", objectFit: "cover" }}
                />
              )}
              <h1>
                {profileData?.username ? profileData?.username : "Messenger"}
              </h1>
            </Link>
            <div className="dott">
              {messengerUser ? (
                <ThreeDots  style={{cursor:"not-allowed"}}/>
              ) : (
                <img src={NewMessage} alt="image" onClick={handleClick} />
              )}
            </div>
          </div>
        </div>
      </div>
      {open && <NewMessageModal open={open} closeModal={closeModal} />}
    </>
  );
}
