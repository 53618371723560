import React from "react";
import "./MainLayout.scss";
import Footer from "./Footer";
import Header from "./Header";
import { useMediaQuery } from "react-responsive";
import SideBarMain from "../SideBarMain";
import { useLocation } from "react-router";
import { useSelector } from "react-redux";

export default function MainLayout({ children }) {
  // {GLOBALLY GET USER LOGIN IN DATA}
  const userLoginToken = useSelector(
    (state) => state?.auth?.userAuthInfo?.access?.token
  );

  const isMobile = useMediaQuery({ maxWidth: 599 });
  const isTablet = useMediaQuery({ minWidth: 600, maxWidth: 1023 });
  const { pathname } = useLocation();
  // Dynamically set the container class based on pathname
  const containerClass =
    {
      "/explore/all": "explore-container",
      "/profile": "page-container user-page-container",
      "/crew-post": "page-container user-page-container",
      "/crew-event/detail/": "explore-container",
      "/garage": "garage-container",
      "/serachResults": "serachResults-container",
      "/messenger": "messenger-container",
      "/view-chat": "messenger-container"
    }[
    Object.keys({
      "/explore/all": "explore-container",
      "/profile": "page-container user-page-container",
      "/crew-post": "page-container user-page-container",
      "/crew-event/detail/": "explore-container",
      "/garage": "garage-container",
      "/serachResults": "serachResults-container",
      "/messenger": "messenger-container",
      "/view-chat": "messenger-container"
    }).find((key) => pathname.includes(key))
    ] || "page-container";
  return (
    <>
      <Header />
      <div className={containerClass}>
        {!isMobile && <SideBarMain isMobile={isMobile} isTablet={isTablet} />}
        {children}
      </div>
      {!(pathname === "/stories-list" || pathname === "/spotlight-list" || (isMobile && pathname.includes("view-chat"))) && <Footer />}

    </>
  );
}

// import React from "react";
// import "./MainLayout.scss";
// import Footer from "./Footer";
// import Header from "./Header";
// import SideBarMain from "../SideBarMain";
// import { useMediaQuery } from "react-responsive";
// import { useLayoutClass } from "@utils/hooks/useLayoutClass";

// export default function MainLayout({ children }) {
//   const { containerClass, showFooter } = useLayoutClass();
//   const isMobile = useMediaQuery({ maxWidth: 599 });
//   const isTablet = useMediaQuery({ minWidth: 600, maxWidth: 1023 });

//   return (
//     <>
//       <Header />
//       <div className={containerClass}>
//         {!isMobile && <SideBarMain isMobile={isMobile} isTablet={isTablet} />}
//         {children}
//       </div>
//       {showFooter && <Footer />}
//     </>
//   );
// }
