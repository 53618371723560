import ReadMore from "@components/ReadMore";
import {
  Comment_check,
  LocationIcon,
  ThreeDots,
  User_photo,
  UserImage,
  Reply_Del,
  Reply_flag,
  Repost_Comment,
  Reply_Comment,
  DefaultUserImg,
  PlusMore,
  NewPlus,
} from "@utils/defaultImages";
import { ReactComponent as Like } from "@assets/like.svg";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import LikedUsername from "@components/LikedBy/LikedUsername";
import ReadLikesComments from "@containers/Crew/CrewPostsPage/components/ReadLikesComments";
import Replies from "./components/Replies/Replies";
import ViewLikes from "@containers/Crew/CrewPostsPage/components/ViewLikes";
import Comments from "./Comments";
import NewComments from "./NewComments";
import { isValidUrl } from "@utils/validUrl";
import { dateTimeFromNow } from "@utils/DateFormatter";
import {
  useLazyListAllRepliesQuery,
  useReactionOnCommentsMutation,
} from "@redux/services/commentsService/commentService";
import ListingReplies from "./latestDesign/ListingReplies";
import ListingComments from "./latestDesign/ListingComments";
import CommentLoader from "@components/Skeleton/CommentLoader";
import MediaShow from "@containers/MainFeed/subComponents/mediaShow";
import { useMediaQuery } from "react-responsive";
import NotFound from "@components/NotFound";
import MessengerSkeleton from "@components/Skeleton/MessengerSkeleton";

const CommentsList = ({
  userData,
  commentList,
  setAllComments,
  commentsCount,
  setCommentsCount,
  postIsLiked,
  setPostIsLiked,
  PostReactionCount,
  setPostReactionCount,
  commentLoading,
  showMore,
  OnUpdate = () => {},
  loaderOnUpdate,
}) => {
  const PostType = [
    "Profile Post",
    "Vehicle Post",
    "Component Post",
    "Crew Post",
  ];
  const [selectedId, setSelectedId] = useState(false);
  const [replyData, setreplyData] = useState(null);
  const [selected, setSelected] = useState([]);
  const [addedReplyComment, setAddedREplyComment] = useState(null);
  const isMobile = useMediaQuery({ maxWidth: "700px" });
  return (
    <>
      <div className="container p-0">
        <div
          className="row"
          style={{ height: "100%", margin: "0", display: isMobile && "block" }}
        >
          <div className="col-lg-6 col-md-6 col-12 p-0 img-section">
            {/* <img src={Comment_check} alt="comments" /> */}
            <MediaShow
              feedPost={userData}
              commentsPost={isMobile}
              isHeight={isMobile ? false : true}
              bordertop={true}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-12 list-section position-relative">
            {!isMobile && (
              <div className="user-name-comment d-flex align-items-center justify-content-between p-3">
                <Link to={`/profile/${userData?.user?._id}`} className="wrap-users d-flex align-items-center gap-2">
                  <img
                    src={
                      isValidUrl(userData?.user?.profileImage)
                        ? userData?.user?.profileImage
                        : DefaultUserImg
                    }
                    alt="UserImage"
                  />
                  <h6>{userData?.user?.username}</h6>
                </Link>
                {/* <div>
                  <ThreeDots />
                </div> */}
              </div>
            )}
            {!isMobile && (
              <div className="wrap-all-comments">
                <div className="px-3">
                  {userData?.title && (
                    <div className="popup-title">
                      <ReadMore sliceText={50}>{userData?.title}</ReadMore>
                    </div>
                  )}
                  {userData?.description && (
                    <div className="popup-desc">
                      <ReadMore sliceText={50} isShowMarginTop>
                        {userData?.description}
                      </ReadMore>
                    </div>
                  )}

                  <div
                    className="type-post d-flex align-items-center gap-2"
                    style={{
                      marginTop: "6px",
                    }}
                  >
                    <span
                      style={{
                        color:
                          userData?.parentType == 2 ? "#52FF00" : "#387AFF",
                      }}
                    >
                      {PostType[userData?.parentType]}
                    </span>
                    <div className="width"></div>
                    <span>{dateTimeFromNow(userData?.createdAt)}</span>
                    {userData?.location?.name && (
                      <>
                        <div className="width"></div>
                        <span>
                          <LocationIcon style={{ marginRight: "3px" }} />
                          {userData?.location?.name}
                        </span>
                      </>
                    )}
                  </div>
                </div>

                {commentLoading
                  ? !isMobile &&
                    Array.from({ length: 6 }).map((_, index) => (
                      <MessengerSkeleton key={index} />
                    ))
                  : commentList.length > 0
                  ? !isMobile &&
                    commentList?.map((item, index) => {
                      return (
                        <ListingComments
                          key={item.id}
                          commentData={item}
                          commentsIndex={index}
                          selectedId={selectedId}
                          setSelectedId={setSelectedId}
                          setAllComments={setAllComments}
                          commentList={commentList}
                          commentsCount={commentsCount}
                          setCommentsCount={setCommentsCount}
                          setreplyData={setreplyData}
                          selected={selected}
                          setSelected={setSelected}
                          addedReplyComment={addedReplyComment}
                          replyData={replyData}
                          setAddedREplyComment={setAddedREplyComment}
                        />
                      );
                    })
                  : !isMobile && <NotFound message="Comments" />}
                {showMore?.hasNextPage && !isMobile && (
                  <div className="more-content text-center mt-1">
                    {!loaderOnUpdate ? (
                      <img
                        src={NewPlus}
                        width={50}
                        className="pointer"
                        onClick={() => OnUpdate(showMore?.nextPage)}
                      />
                    ) : (
                      <CommentLoader />
                    )}
                  </div>
                )}
              </div>
            )}
            <div
              className={`px-3  w-100 mention-comments ${
                !isMobile && "position-absolute bottom-0"
              }`}
              // style={{ left: "0", position: !isMobile && "absolute" }}
            >
              <NewComments
                isComponent={true}
                post={userData}
                setAllComments={setAllComments}
                commentList={commentList}
                commentsCount={commentsCount}
                setCommentsCount={setCommentsCount}
                replyData={replyData}
                setreplyData={setreplyData}
                selected={selected}
                setSelected={setSelected}
                addedReplyComment={addedReplyComment}
                setAddedREplyComment={setAddedREplyComment}
                postIsLiked={postIsLiked}
                setPostIsLiked={setPostIsLiked}
                PostReactionCount={PostReactionCount}
                setPostReactionCount={setPostReactionCount}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CommentsList;
