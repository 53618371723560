import WarningModal from "@components/Modal/WarningModal";
import { useDeleteFeedStoriesMutation } from "@redux/services/mainFeed/feedService";
import { deletePost } from "@redux/slices/feeds/feedSlice";
import { Copy, Delete, Edit, Profile, ThreeDots } from "@utils/defaultImages";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { RiUserFollowLine } from "react-icons/ri";
import { RiUserUnfollowLine } from "react-icons/ri";



const DropDownFeed = ({ itemMsg, followUnfollow }) => {
  const navigate = useNavigate();
  /**
   * LOGIN USER
   */
  const dispatch = useDispatch();
  const loginUserInfo = useSelector((state) => state?.auth?.loginUserData);
  //   console.log("loginUserInfo", loginUserInfo);
  const deleteShow = loginUserInfo?._id === itemMsg?.user?._id;
  /**
   * USESTATE
   */
  const [openDelModal, setOpenDelModal] = useState(false);

  /**
   * API CALLS
   */
  const [singleDelPost, { isLoading }] = useDeleteFeedStoriesMutation();

  const svgs = [
    // { component: Edit, label: "Edit" },
    { component: Profile, label: "Profile" },
    ...(deleteShow ? [{ component: Delete, label: "Delete" }] : []),

    ...(itemMsg?.isFollow ? [{ component:()=> <RiUserUnfollowLine/>, label: "unFollow" }] : []),
  ];
  /**
   * FUNCTIONS
   */
  const handleAction = async (value, label) => {
    // console.log('value----??', value)
    switch (label) {
      case "Profile":
        return navigate(`/profile/${value?.userId}`);
      case "Delete":
        return setOpenDelModal(!openDelModal);
      case "unFollow":
        return followUnfollow(itemMsg);
      default:
        return;
    }
  };
  const handleDelete = async () => {
    try {
      const res = await singleDelPost(itemMsg?._id)?.unwrap();
      dispatch(deletePost({ ID: itemMsg?._id }));
      if (res?.code === 200) {
        setOpenDelModal(!openDelModal);
      }
    } catch (error) {
      toast.error(error?.data?.message);
    }
  };

  return (
    <>
      <div className="dropdown drop-wrapper">
        <button
          className="btn btn-secondary dropdown-toggle three-dots"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <ThreeDots />
        </button>
        <ul className="dropdown-menu dropdown-menu-dark wrapp-list">
          {svgs.map(({ component: SvgComponent, label }, index) => (
            <li key={index}>
              <div
                className="dropdown-item"
                onClick={() => handleAction(itemMsg, label)}
              >
                <SvgComponent className="dropdown-icon" />
                <span className="dropdown-label">{label}</span>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <WarningModal
        open={openDelModal}
        closeModal={setOpenDelModal}
        deleteBtn={handleDelete}
        feedSingle={true}
        Loading={isLoading}
      />
    </>
  );
};

export default DropDownFeed;
