import React, { useEffect, useState } from "react";
import "./YourCrews.scss";
import { isValidUrl } from "@utils/validUrl";
import { Link } from "react-router-dom";
import { DefaultImg, NotificationIcon } from "@utils/defaultImages";
import { sliceStringAtWord } from "@utils/sliceStringWord";
import { useLazyGetYourCrewQuery } from "@redux/services/crewService/allCrewService";
import PopularLoader from "../../Auth/SignUp/Loader/PopularLoader";
import useHorizontalScroll from "@utils/hooks/useHorizontalScroll";
import SpinnerLoader from "@components/Skeleton/SpinnerLoader";
import { toast } from "react-toastify";

const UsersCrew = ({ isMobile, isTablet }) => {
  const [page, setPage] = useState(1);
  const [crews, setCrews] = useState([]);
  const [getYourCrew, { data, isLoading }] = useLazyGetYourCrewQuery();
  const scrollRef = useHorizontalScroll();

  const loadMoreCrews = async () => {
    try {
      const response = await getYourCrew(page).unwrap();
      if (response?.data?.docs && response?.data?.hasNextPage) {
        setCrews((prevCrews) => [...prevCrews, ...response?.data?.docs]);
      }
    } catch (error) {
      toast.error(error?.data?.message)
    }
  };
  useEffect(() => {
    loadMoreCrews();
  }, [page]);

  const handleScroll = () => {
    const el = scrollRef.current;
    if (el.scrollLeft + el.clientWidth >= el.scrollWidth) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    const el = scrollRef.current;
    if (el) {
      el.addEventListener("scroll", handleScroll);
      return () => el.removeEventListener("scroll", handleScroll);
    }
  }, [data]);
  

  return (
    <>
      {isLoading && page === 1 ? (
        isMobile || isTablet ? (
          <SpinnerLoader />
        ) : (
          <div style={{ marginTop: "8%" }}>
            <PopularLoader />
          </div>
        )
      ) : (
        <>
          {crews?.length > 0 && (
            <div className="label">
              <div className="text-wrapper">Your Crews</div>
            </div>
          )}

          <div
            className="crew-accounts"
            ref={scrollRef}
            // style={{ width: isMobile ? "40%" : "100%" }}
          >
            {crews?.map(({ name, bannerImage, notificationCount, _id }) => (
              <Link to={`/crew-post/${_id}`} key={_id}>
                <label>
                  <div
                    className="account-img"
                    style={{
                      border:
                        notificationCount > 0
                          ? "3px solid #E56424"
                          : "3px solid #8E8E93",
                    }}
                  >
                    <img
                      alt={name}
                      src={isValidUrl(bannerImage) ? bannerImage : DefaultImg}
                    />
                    {notificationCount > 0 && (
                      <div
                        className="notify-icon"
                        style={{
                          width: "20px",
                          height: "20px",
                          backgroundColor: "#E56424",
                          position: "absolute",
                          top: "0px",
                          right: "0px",
                          display: "flex",
                          borderRadius: "12px",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <NotificationIcon />
                      </div>
                    )}
                  </div>
                  <div className="name">{sliceStringAtWord(name, 3)}</div>
                </label>
              </Link>
            ))}
            {isLoading && (
              <div>
                <PopularLoader />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default UsersCrew;
