import React from "react";
import "../FeedsPage.scss";
import { DefaultImg, DefaultUserImg } from "@utils/defaultImages";
import { isValidUrl } from "@utils/validUrl";
import { useNavigate } from "react-router-dom";

const Stories = React.memo(({ feedStories }) => {
  const navigate = useNavigate();

  /**
   * FUNCTIONS
   */
  const homeStories = feedStories?.filter((item) => item?.stories?.length > 0);
  const handleNavigate = (data) => {
    if (data?.stories?.length) {
      navigate("/stories-list", { state: { stories: data } });
    }
  };
  // const unseenStories = homeStories?.map((item) => {
  //   const unseenStory = item?.stories?.find((story) => story?.isSeen === false);
  //   return {
  //     ...item,
  //     thumbnail: unseenStory
  //       ? isValidUrl(unseenStory?.media?.thumbnail)
  //         ? unseenStory?.media?.thumbnail
  //         : isValidUrl(unseenStory?.media?.path)
  //         ? unseenStory?.media?.path
  //         : DefaultImg
  //       : isValidUrl(item?.stories[0]?.media?.thumbnail)
  //       ? item?.stories[0]?.media?.thumbnail
  //       : isValidUrl(item?.stories[0]?.media?.path)
  //       ? item?.stories[0]?.media?.path
  //       : DefaultUserImg,
  //   };
  // });
  const unseenStories = homeStories?.map((item) => {
    const getMedia = (story) =>
      isValidUrl(story?.media?.thumbnail)
        ? story?.media?.thumbnail
        : isValidUrl(story?.media?.path)
        ? story?.media?.path
        : null;
    const unseenStory = item?.stories?.find((story) => story?.isSeen === false);
    const firstStory = item?.stories?.[0];
    const thumbnail =
      getMedia(unseenStory) || getMedia(firstStory) || DefaultUserImg;
    return {
      ...item,
      thumbnail,
    };
  });

  return (
    <div className="story-container">
      {unseenStories?.map((story) => {
        return (
          // story?.stories?.length > 0 && (
          <div
            key={story?.id}
            // className={`story-div ${
            //   story?.stories?.some((s) => s.isSeen === false) ? "pointer" : ""
            // }`}
            className={`story-div ${story?.stories?.length > 0 && "pointer"}`}
            // onClick={() => togglePopup(story.id)}
            onClick={() => handleNavigate(story)}
          >
            <div className={`story-media`}>
              <img src={story?.thumbnail} alt="Story Thumbnail" />
            </div>
            <div
              className="user-profile-pic"
              style={{
                background: story?.allSeen
                  ? "#323334"
                  : "linear-gradient(to top right, #387aff, #e56424)",
              }}
            >
              <img
                src={
                  isValidUrl(story.profileImage)
                    ? story.profileImage
                    : DefaultUserImg
                }
                alt={story.username}
                height="38px"
                width="38px"
              />
            </div>
            <div className="user-name">{story.username}</div>
          </div>
          // )
        );
      })}
    </div>
  );
});

export default Stories;
