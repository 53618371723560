import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isChevronDown: false,
  // isMuteStory: false,
};

const storySlice = createSlice({
  name: "storyView",
  initialState,
  reducers: {
    setChevronDown(state, action) {
      state.isChevronDown = action.payload;
    },
    // setMuteStory(state, action) {
    //   state.isMuteStory = action.payload;
    // },
  },
});

export const { setChevronDown } = storySlice.actions;
export default storySlice;
