import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { clearAuth, setAuth } from "../../slices/authSlice/loginSlice";
import { SetclearRegToken } from "../../slices/authSlice/signUpSlice";
import ErrorPage from "@containers/Error/ErrorPage";
import { setError } from "@redux/slices/Error/errorSlice";

export const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BASE_URL,
  prepareHeaders: async (headers, { getState }) => {
    const token = await getState()?.signUp?.regRefreshToken?.token;
    const logintoken = await getState()?.auth?.userAuthInfo?.access?.token;
    // console.log("isLoggedInAb", logintoken, "token----??", token);
    const temporaryToken =
      getState()?.temporaryAuth?.userTemporaryAuthInfo?.access?.token;
    if (token || logintoken || temporaryToken) {
      headers.set(
        "Authorization",
        `Bearer ${token ? token : temporaryToken ? temporaryToken : logintoken}`
      );
    }
    return headers;
  },
});
const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result;
  try {
    result = await baseQuery(args, api, extraOptions);
    if (result?.error && result?.error?.status === 401) {
      const refreshToken = api.getState()?.auth?.userAuthInfo?.refresh?.token;
      if (refreshToken) {
        try {
          const refreshResult = await baseQuery(
            {
              url: "/auth/refresh-tokens",
              method: "POST",
              body: { refreshToken },
            },
            api,
            extraOptions
          );
          // console.log("refreshResult---??", args, api, extraOptions);

          if (refreshResult?.data) {
            // Store the new token
            api.dispatch(setAuth(refreshResult?.data?.data));
            // Retry the initial query
            result = await baseQuery(args, api, extraOptions);
          } else {
            api.dispatch(clearAuth(""));
            api.dispatch(SetclearRegToken({}));
          }
        } catch (error) {
          console.error("Error refreshing token", error);
          api.dispatch(clearAuth(""));
          api.dispatch(SetclearRegToken({}));
        }
      } else {
        // api.dispatch(loggedOut());
        api.dispatch(clearAuth(""));
        api.dispatch(SetclearRegToken({}));
      }
    }
    if (
      result?.error &&
      result?.meta?.request?.method === "GET" &&
      (result?.meta?.response?.status === 400 ||
        result?.meta?.response?.status === 404)
    ) {
      api.dispatch(setError(result.error));
    }
  } catch (error) {
    console.error("Error during base query", error);
  }
  return result;
};

export default baseQueryWithReauth;
