import SendComment from "@assets/Triangle.svg";
import { Button } from "@components/index";
import { useUpdateMessageMutation } from "@redux/services/messenger/messengerService";
import { SendChat } from "@utils/defaultImages";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import { useDebounce } from "use-debounce";
const StyledIconChat = styled.div`
  .send-btn {
    path {
      fill: #8e8e93;
    }
  }

  &.actived .send-btn {
    path {
      fill: #387aff;
    }
  }
`;

const SendMessage = ({
  socket,
  loginUser,
  messengerUser,
  editMsg,
  resetEditMsg,
  setTypingUser,
  typingUser,
  chatRoomId
}) => {
  /**
   * USESTATE
   */
  const [isEditing, setIsEditing] = useState(false);
  const [messageText, setMessageText] = useState("");
  const [debouncedSearch] = useDebounce(messageText, 1000);
  /**
   * API CALL
   */
  const [updateMessage] = useUpdateMessageMutation();

  /**
   * FUNCTIONS
   */
  const handleClick = async () => {
    if (isEditing) {
      const body = {
        messageContent: messageText,
        roomId: editMsg?.roomId,
        messageId: editMsg?._id,
      };

      try {
        socket.emit("update-message", {
          userId: editMsg?.senderId,
          roomId: editMsg?.roomId,
          chatId: editMsg?._id,
          messageContent: messageText,
        });
        await updateMessage(body).unwrap();
        resetEditMsg({});
        setMessageText("");
        setIsEditing(false);
      } catch (error) {
        toast.error(error?.data?.message);
      }
    } else {
      socket.emit("message-room", {
        type: 1,
        messageContent: messageText,
        senderId: loginUser?._id,
        roomId: chatRoomId,
        receiverId: messengerUser?.user?._id || messengerUser?._id,
      });
      // setMessageText("");
    }
    // Reset states
    setMessageText("");
    resetEditMsg({});
    setIsEditing(false);
  };
  // const handleChange = (e) => {
  //   if(e.target.value===""){
  //     socket?.emit("is-typing", chatRoomId, loginUser?._id, false);
  //   }
  //   else{
  //     socket?.emit("is-typing", chatRoomId, loginUser?._id, true);
  //   }
  //   setMessageText(e.target.value);

  // };
  const [typingTimeout, setTypingTimeout] = useState(null);
  const [isTyping, setIsTyping] = useState(false);
  
  const handleChange = (e) => {
    const value = e.target.value;
    setMessageText(value);
  
    if (value.length > 0) {
      if (!isTyping) {
        socket?.emit("is-typing", chatRoomId, loginUser?._id, true);
        setIsTyping(true);
      }

      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }

      const timeoutId = setTimeout(() => {
        socket?.emit("is-typing", chatRoomId, loginUser?._id, false);
        setIsTyping(false);
      }, 1000);
      setTypingTimeout(timeoutId);
    } else {
      if (isTyping) {
        socket?.emit("is-typing", chatRoomId, loginUser?._id, false);
        setIsTyping(false); // Reset typing state
      }
  
      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }
      setTypingTimeout(null);
    }
  };
  
  /**
   * UEFFECTS
   */
  useEffect(() => {
    if (Object.keys(editMsg)?.length > 0) {
      setMessageText(editMsg?.messageContent);
      setIsEditing(true);
    } else {
      setMessageText("");
      setIsEditing(false);
    }
  }, [editMsg]);
  // Emit stop typing event after 2 seconds of inactivity
  // useEffect(() => {
  //   let typingTimer;
  //   if (messageText) {
  //     typingTimer = setTimeout(() => {
  //       socket?.emit("is-typing", chatRoomId, loginUser?._id, false);
  //     }, 1000);
  //   } else {
  //     socket?.emit("is-typing", chatRoomId, loginUser?._id, false);
  //   }
  //   return () => {
  //     clearTimeout(typingTimer);
  //   };
  // }, [messageText, socket, chatRoomId, loginUser?._id]);
  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey && messageText?.trim()?.length > 0) {
      e.preventDefault();
      handleClick();
    }
  };

  useEffect(() => {
    socket?.on("typing-status", (data) => {
      setTypingUser(data);
    });



    return () => {
      socket?.off("typing-status");
    };
  }, [socket]);
  return (
    <div className="message-box">
      <textarea
        placeholder="Add a message"
        value={messageText}
        onChange={handleChange}
        onKeyDown={(e) => handleKeyDown(e)}
      />
      <div className="wrap-send-btn">
        <Button className="messageSendBtn" onClick={handleClick} disabled={messageText.trim().length === 0}
        >
          <StyledIconChat
            className={`${messageText.trim().length > 0 ? "actived" : ""}`}
          >
            <SendChat className="send-btn" />
          </StyledIconChat>
        </Button>
      </div>
    </div>
  );
};

export default SendMessage;
