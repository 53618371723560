import React, { useCallback, useEffect, useState } from "react";
import "@containers/searchcomponent/SearchPage.scss";
import { Link } from "react-router-dom";
import {
  useFollowProfileExploreMutation,
  useLazySearchExploreProfileQuery,
  useRemovefollowProfileExploreMutation,
} from "@redux/services/exploreService/exploreService";
import FollowUnFollow from "@components/Button/FollowBtn/FollowUnFollow";
import { toast } from "react-toastify";
import { DefaultUserImg, UserImg } from "@utils/defaultImages";
import { isValidUrl } from "@utils/validUrl";
import InfiniteScroll from "react-infinite-scroll-component";
import SpinnerLoader from "@components/Skeleton/SpinnerLoader";
import { sliceStringAtWord } from "@utils/sliceStringWord";
import { useDebounce } from "use-debounce";
import NotFound from "@components/NotFound";

const ExploreAccounts = ({ categorySearch }) => {
  /**
   * API CALL
   */
  const [
    listAllAccount,
    { data: { data: allExploreAccount } = {}, isLoading, isFetching },
  ] = useLazySearchExploreProfileQuery();
  const [followProfile] = useFollowProfileExploreMutation();
  const [removefollowProfile] = useRemovefollowProfileExploreMutation();
  /**
   * USESTATES
   */
  const [allAccount, setAllAccount] = useState({ docs: [] });
  const [loadingFollow, setLoadingFollow] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [page, setPage] = useState(1);
  /**
   * DEBOUNCE
   */
  const [debouncedSearch] = useDebounce(categorySearch, 1000);
  /**
   * FUNCTIONS
   */
  const fetchAccount = async () => {
    const value = {
      page: page,
      limit: 30,
      search: debouncedSearch,
    };
    try {
      const res = await listAllAccount(value).unwrap();
      setAllAccount((prev) => ({
        ...prev,
        docs: page === 1 ? res?.data?.docs : [...prev.docs, ...res?.data?.docs],
      }));
    } catch (error) {
      toast.error(error?.data?.message);
    }
  };

  const followUnFollowProfile = async (userInfo, index) => {
    const body = { followToUserId: userInfo?._id };
    setLoadingFollow(true);
    setSelectedIndex(index);

    try {
      if (userInfo?.isFollow) {
        await removefollowProfile(userInfo?._id).unwrap();
      } else {
        await followProfile(body).unwrap();
      }

      setAllAccount((prevAccounts) => ({
        ...prevAccounts,
        docs: prevAccounts?.docs?.map((account, i) =>
          i === index ? { ...account, isFollow: !account.isFollow } : account
        ),
      }));
    } catch (error) {
      toast.error(error?.data?.message);
    } finally {
      setLoadingFollow(false);
      setSelectedIndex(null);
    }
  };
  const loadMore = useCallback(() => {
    if (!isFetching && allExploreAccount?.nextPage) {
      setPage(allExploreAccount?.nextPage);
    }
  }, [isFetching, allExploreAccount?.nextPage]);
  /**
   * USEEFFECT
   */
  useEffect(() => {
    fetchAccount();
  }, [page, debouncedSearch]);

  useEffect(() => {
    setPage(1);
    setAllAccount({ docs: [] });
  }, [debouncedSearch]);
  return (
    <>
      <div className="view-all-heading">
        <h2>Accounts</h2>
      </div>
      <InfiniteScroll
        style={{ overflow: "hidden" }}
        dataLength={allAccount?.docs?.length}
        next={loadMore}
        hasMore={!!allExploreAccount?.nextPage}
        loader={page !== 1 && <SpinnerLoader />}
        // endMessage={<p style={{ textAlign: "center" }}>No more results</p>}
      >
        {allAccount?.docs?.length === 0 && isFetching ? (
          <SpinnerLoader />
        ) : allExploreAccount?.totalDocs === 0  ? (
          <NotFound message="Account" />
        ) : (
          <div className="crew-list-content-search">
            {allAccount?.docs?.map((item, index) => (
              <div className="list-block user-list-block" key={index}>
                <Link
                  className="list-block"
                  style={{
                    borderBottom: "none",
                    color: "#fff",
                    padding: "unset",
                    marginBottom: "unset",
                  }}
                  to={`/profile/${item?._id}`}
                >
                  <img
                    src={
                      isValidUrl(item?.profileImage)
                        ? item?.profileImage
                        : DefaultUserImg
                    }
                    alt={item?.username}
                  />
                  <div className="list-info">
                    <h4>
                      {sliceStringAtWord(item?.firstName, 5)}{" "}
                      {sliceStringAtWord(item?.lastName, 5)}
                    </h4>
                    <p>{sliceStringAtWord(item?.username, 10)}</p>
                  </div>
                </Link>
                <FollowUnFollow
                  followUnfollow={(userInfo) =>
                    followUnFollowProfile(userInfo, index)
                  }
                  userInfo={item}
                  loading={loadingFollow && selectedIndex === index}
                  btnIndex={index}
                  selectedIndex={selectedIndex}
                />
              </div>
            ))}
          </div>
        )}
      </InfiniteScroll>
    </>
  );
};

export default ExploreAccounts;
