import React, { useEffect, useState } from "react";
import "./Landing.scss";
import LandingFooter from "@components/layouts/LandingFooter";
import LandingHeader from "@components/layouts/LandingHeader";
import HeroSection from "./HeroSection";
import Communities from "./Communities";
import GarageUpgrade from "./GarageUpgrade";
import SeamlessEvent from "./SeamlessEvent";
import NewCommunities from "./NewCommunities";
import MeetUpEvents from "./MeetUpEvents";
import {
  backgroundLogo,
  CrewPost,
  VehiclePost,
  HomePost,
  ProfilePost,
  HomePostWeb,
  VehiclePostWeb,
  ProfilePostWeb,
} from "@utils/defaultImages";
import useMediaQueryHook from "@utils/hooks/useMediaQueryHook";
import LottieCrew from "@components/Lottie/web-crew.json";
import CommunitiesMob from "./CommunitiesMob";

const multiplePost = [
  {
    key: 1,
    title: "Share, build, socialize",
    description:
      "The home feed is where all of your content will be posted and displayed, and personalized to your exact preferences.",
    LinkImg: HomePost,
    WebImg: HomePostWeb,
  },
  {
    key: 2,
    title: "Find New Communities",
    description:
      "Connect with communities around specific interests, models, or regions.",
    LinkImg: LottieCrew,
  },
  {
    key: 3,
    title: "Your Cars, Your Way",
    description:
      "Craft your automotive identity, showcase your ride's unique personality.",
    LinkImg: VehiclePost,
    WebImg: VehiclePostWeb,
  },
  {
    key: 4,
    title: "Personalize Your Identity",
    description:
      "Your profile page is your central hub for all your vehicles, crews, posts, and more.",
    LinkImg: ProfilePost,
    WebImg: ProfilePostWeb,
  },
];

const Landing = () => {
  const [showHeader, setShowHeader] = useState(false);
  const { isMobile, isMobileSmall } = useMediaQueryHook();
  const [activeLink, setActiveLink] = useState(0);
  const [nexSec, setNextSec] = useState(["next-section", "hero-section"]);

  useEffect(() => {
    const handleScroll = () => {
      const secondSection = document.getElementById("communities");
      if (secondSection) {
        const rect = secondSection.getBoundingClientRect();
        const isVisible = rect.top <= 100;
        setShowHeader(isVisible);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const myStyle = {
    backgroundImage: `url(${backgroundLogo})`,
  };

  return (
    <>
      <div className="landing-container">
        {(showHeader || isMobile || isMobileSmall) && <LandingHeader />}
        <div className="content" style={myStyle} id="hero-section">
          <HeroSection />
        </div>
        <div className="slider" id="communities">
          {!(isMobile || isMobileSmall) ? (
            <Communities
              multiplePost={multiplePost}
              activeLink={activeLink}
              setActiveLink={setActiveLink}
              nexSec={nexSec}
            />
          ) : (
            <CommunitiesMob
              multiplePost={multiplePost}
              activeLink={activeLink}
              setActiveLink={setActiveLink}
              nexSec={nexSec}
            />
          )}
        </div>
        <div className="garage-upgrade">
          <GarageUpgrade />
        </div>
        <div className="seamless">
          <SeamlessEvent />
        </div>
        <div className="new-communities">
          <NewCommunities />
        </div>
        <div className="meet-up">
          <MeetUpEvents />
        </div>
        <LandingFooter />
      </div>
    </>
  );
};

export default Landing;
