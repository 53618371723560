import React from "react";
import WrapperModal from "./WrapperModal";
import FollowersFollowing from "@containers/UserProfile/subComponents/FollowersFollowing";

const FollowersAndFollowingModal = React.memo(({ open, closeModal,otherId="" }) => {
  return (
    <div id="modal">
      <WrapperModal
        open={open}
        closeModal={closeModal}
        style={{
          top: "50%",
          transform: "translateY(-50%)",
          left: 0,
          right: 0,
          margin: "0 auto",
          backgroundColor: "#121313",
          zIndex: "999999",
          overflow: "hidden",
          height: "100%",
          maxHeight: "600px",
        }}
      >
        <FollowersFollowing closeModal={closeModal} otherId={otherId} />
      </WrapperModal>
    </div>
  );
});

export default FollowersAndFollowingModal;
