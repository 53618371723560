import React from "react";
import FeedsPage from "@containers/MainFeed/FeedsPage";
import { useMediaQuery } from "react-responsive";
import SuggestedYou from "@containers/MainFeed/components/SuggestedYou";

const Feed = ({socket}) => {
  // media Query
  const isMobile = useMediaQuery({ maxWidth: 599 });
  const isTablet = useMediaQuery({ minWidth: 600, maxWidth: 1023 });
  return (
    <>
      <div>
        <FeedsPage isMobile={isMobile} isTablet={isTablet} socket={socket} />
      </div>
      {!(isMobile || isTablet) && <SuggestedYou />}
    </>
  );
};

export default Feed;
