import React, { useState } from "react";
import "@containers/searchcomponent/SearchPage.scss";
import FadingLoader from "@components/Skeleton/CrewSkeleton";
import { isValidUrl } from "@utils/validUrl";
import { sliceStringAtWord } from "@utils/sliceStringWord";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import car from "@assets/car.jpg";
import {
  useDisJoinExploreCrewMutation,
  useExploreCrewQuery,
  useJoinExploreCrewMutation,
} from "@redux/services/exploreService/exploreService";
import CrewJoinBtn from "@components/Button/Crewbtn/CrewJoinBtn";
import { toast } from "react-toastify";

const CrewPart = () => {
  // const filteredCrews = resCrew?.filter(
  //   (item) => !joinedCrews?.includes(item._id)
  // );
  /**
   * USESTATES
   */
  const [loadingCrew, setLoadingCrew] = useState(false);
  const [selectedCrewIndex, setSelectedCrewIndex] = useState(null);
  /**
   * GET DATA LOCALLY FROM STORAGE(REDUX PERSIST)
   */
  const loginUserInfo = useSelector((state) => state?.auth?.loginUserData);
  /**
   * API CALLS
   */
  const { data: { data: exploreCrew = [] } = {}, isLoading: exploreLoading } =
    useExploreCrewQuery();
  const [joinCrew] = useJoinExploreCrewMutation();
  const [disJoinCrew] = useDisJoinExploreCrewMutation();

  /**
   * FUNCTIONS
   */
  const joiningCrew = async (crewId) => {
    setLoadingCrew(true);
    try {
      const value = {
        requestTo: loginUserInfo?._id,
      };
      await joinCrew({ crewId, value }).unwrap();
    } catch (error) {
      toast.error(error?.data?.message);
    } finally {
      setLoadingCrew(false);
    }
  };
  const disJoiningCrew = async (crewId) => {
    setLoadingCrew(true);
    try {
      await disJoinCrew(crewId).unwrap();
    } catch (error) {
      toast.error(error?.data?.message);
    } finally {
      setLoadingCrew(false);
    }
  };

  return (
    <>
      <div className="view-all-heading">
        <>
          {/* <h2>{stateCrew?.Crews?.name}</h2> */}
          <h2>Crews in your area</h2>
          {exploreCrew?.docs?.length > 6 && (
            <Link to={`/explore/categories/Crews`} state={{ title: "Crews" }}>
              See More
              <svg
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.25 11.25L10 7.5L6.25 3.75"
                  stroke="#387AFF"
                  stroke-linecap="round"
                />
              </svg>
            </Link>
          )}
        </>
      </div>

      <div className="crew-list-content-search">
        {exploreLoading ? (
          <div className="fadding-loader">
            <FadingLoader />
          </div>
        ) : (
          <>
            {exploreCrew?.docs?.slice(0, 6)?.map((item, index) => {
              // console.log('item22', item)
              return (
                <div className="list-block" key={index}>
                  <Link
                    to={`/crew-post/${item?._id}`}
                    className="list-block"
                    style={{
                      borderBottom: "none",
                      color: "#fff",
                      padding: "unset",
                      marginBottom: "unset",
                    }}
                  >
                    <img
                      src={
                        isValidUrl(item?.bannerImage)
                          ? item?.bannerImage
                          : // : UserImg
                            car
                      }
                      alt={item?.name}
                      className="main-img"
                      style={{ borderRadius: "5px" }}
                    />
                    <div className="list-info">
                      <h4>
                        {sliceStringAtWord(item?.name, 5)}
                        {item?.privacy != 0 && (
                          <svg
                            width="14"
                            height="16"
                            viewBox="0 0 14 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g id="Private group badge">
                              <path
                                id="Path 7"
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M14 10.1053C14 12.3509 11.6667 14.3158 7 16C2.33333 14.3158 0 12.3509 0 10.1053C0 7.85965 0 5.05263 0 1.68421C3.13611 0.561404 5.46945 0 7 0C8.53055 0 10.8639 0.561404 14 1.68421C14 5.05263 14 7.85965 14 10.1053Z"
                                fill="#E56424"
                              />
                              <g id="lock">
                                <rect
                                  id="Rectangle 3"
                                  x="4"
                                  y="6.33105"
                                  width="6"
                                  height="4.66839"
                                  stroke="#F5F5F5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  id="Rectangle 2"
                                  d="M4.66602 5.33333C4.66602 4.04467 5.71068 3 6.99935 3V3C8.28801 3 9.33268 4.04467 9.33268 5.33333V6.33456H4.66602V5.33333Z"
                                  stroke="#F5F5F5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </g>
                            </g>
                          </svg>
                        )}
                      </h4>
                      <p>
                        {/* {item?.description?.length > 20
                          ? `${
                              sliceStringAtWord(item?.description, 20) + "..."
                            }`
                          : `${item?.description}`} */}
                        {sliceStringAtWord(item?.description, 7)}
                      </p>
                    </div>
                  </Link>
                  <CrewJoinBtn
                    btnType={item}
                    joinCrew={joiningCrew}
                    disjoin={disJoiningCrew}
                    loadingValue={loadingCrew}
                    handleCrewIndex={setSelectedCrewIndex}
                    comapreIndex={selectedCrewIndex}
                    listIndex={index}
                  />
                </div>
              );
            })}
          </>
        )}
      </div>
    </>
  );
};

export default CrewPart;
