import React from "react";
import WrapperModal from "./WrapperModal";
import UserList from "./NewMessageUsersList";

const NewMessageModal = React.memo(({ open, closeModal }) => {
  return (
    <div id="modal">
      <WrapperModal
        open={open}
        closeModal={closeModal}
        style={{
          top: "50%",
          transform: "translateY(-50%)",
          left: 0,
          right: 0,
          margin: "0 auto",
          backgroundColor: "#121313",
          zIndex: "999999",
          overflow: "hidden",
          minHeight: "560px",
          maxWidth: "500px",
        }}
      >
        <h1
          style={{
            textAlign: "center",
            padding: "10px 0px",
            fontSize: "15px",
            lineHeight: "20px",
          }}
        >
          New Message
        </h1>
        <UserList />
      </WrapperModal>
    </div>
  );
});

export default NewMessageModal;
