import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithReauth  from "../baseQuery";

export const commentsApi = createApi({
  reducerPath: "commentsApi",
  baseQuery: baseQueryWithReauth,
  keepUnusedDataFor: 60,
  tagTypes: ["comments", "replies"],
  endpoints: (builder) => ({
    
    listAllComments: builder.query({
      query: (values) => ({
        url: `/Comments/get-all/${values?.postId}`,
        method: "GET",
        params: {
          sortBy: "createdAt:desc",
          limit: 10,
          page: values?.page,
        },
      }),
      providesTags: ["comments"],
    }),
    listAllReplies: builder.query({
      query: (values) => ({
        url: `/Comments/get-all-reply/${values?.commentId}`,
        method: "GET",
        params: {
          sortBy: "createdAt:asc",
          limit: 10,
          page: values?.page,
        },
      }),
      providesTags: ["replies"],
    }),
    listSingleComment: builder.query({
      query: (values) => ({
        url: `/Comments/${values?.commentId}`,
        method: "GET",
      }),
    }),
    reactionOnComments: builder.mutation({
      query: (values) => ({
        url: `/Reactions/comment`,
        method: "POST",
        body: values,
      }),
      invalidatesTags: ["comments","replies"],
    }),
    commentOnPost: builder.mutation({
      query: (values) => ({
        url: `Comments`,
        method: "POST",
        body: values,
      }),
      invalidatesTags: ["comments"],
    }),
    commentOnReply: builder.mutation({
      query: (values) => ({
        url: `Comments`,
        method: "POST",
        body: values,
      }),
      invalidatesTags: ["replies"],
    }),
    mentionFollowersList: builder.query({
      query: (values) => ({
        url: `users/followers/list`,
        method: "GET",
        params: {
          userId: values?.userId,
          sortBy: "createdAt:asc",
          limit: 10,
          page: values?.page,
          username: values?.username
        },
      }),
    }),
    removeComments: builder.mutation({
      query: (removeId) => ({
        url: `Comments/${removeId}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useLazyListAllCommentsQuery,
  useLazyListSingleCommentQuery,
  useLazyListAllRepliesQuery,
  useReactionOnCommentsMutation,
  useCommentOnPostMutation,
  useCommentOnReplyMutation,
  useLazyMentionFollowersListQuery,
  useRemoveCommentsMutation
} = commentsApi;
