import NotFound from "@components/NotFound";
import SpinnerLoader from "@components/Skeleton/SpinnerLoader";
import { MultipleUsers } from "@containers/Crew/components/MultipleUsers";
import { useLazySearchExploreCrewQuery } from "@redux/services/exploreService/exploreService";
import { capitalizeAndAddSpace } from "@utils/capitalizeAddSpace";
import {  DefaultImg } from "@utils/defaultImages";
import { sliceStringAtWord } from "@utils/sliceStringWord";
import { isValidUrl } from "@utils/validUrl";
import { AsyncImage } from "loadable-image";
import React, { useCallback, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Blur } from "transitions-kit";
import { useDebounce } from "use-debounce";

const ExploreCrew = ({ categorySearch }) => {
  const isMobile = useMediaQuery({ maxWidth: 599 });
  /**
   * USESTATES
   */
  const [allCrew, setAllCrew] = useState([]);
  const [page, setPage] = useState(1);
  /**
   * DEBOUNCE
   */
  const [debouncedSearch] = useDebounce(categorySearch, 1000);
  /**
   * API CALL
   */
  const [
    listAllCrew,
    { data: { data: allExploreCrew } = {}, isLoading, isFetching },
  ] = useLazySearchExploreCrewQuery();

  /**
   * FUNCTIONS
   */
  const fetchCrew = async () => {
    const value = {
      page: page,
      search: debouncedSearch,
    };
    try {
      const res = await listAllCrew(value).unwrap();
      setAllCrew((prevItem) =>
        page === 1 ? res?.data?.docs : [...prevItem, ...res?.data?.docs]
      );
    } catch (error) {
      toast.error(error?.data?.message);
    }
  };
  const loadMore = useCallback(() => {
    if (!isFetching && allExploreCrew?.nextPage) {
      setPage(allExploreCrew?.nextPage);
    }
  }, [isFetching, allExploreCrew?.nextPage]);
  /**
   * USEEFFECT
   */
  useEffect(() => {
    fetchCrew();
  }, [page, debouncedSearch]);

  useEffect(() => {
    setPage(1);
    setAllCrew([]);
  }, [debouncedSearch]);

  return (
    <>
      <div className="view-all-heading">
        <h2>{capitalizeAndAddSpace("Crews")} </h2>
      </div>{" "}
      <InfiniteScroll
        style={{ overflow: "hidden" }}
        dataLength={allCrew?.length}
        next={loadMore}
        hasMore={!!allExploreCrew?.nextPage}
        loader={page !== 1 && <SpinnerLoader />}
      >
        {allCrew?.length === 0 && isFetching ? (
          <SpinnerLoader />
        ) : allExploreCrew?.totalDocs === 0 ? (
          <NotFound message="crews" />
        ) : (
          <div
            className="crew-garage-row"
            style={{ gridTemplateColumns: "1fr 1fr" }}
          >
            {allCrew?.map((item, index) => (
              <>
                <Link
                  className="garage-block explore-crew-search"
                  style={{ position: "relative" }}
                  to={`/crew-post/${item?._id}`}
                  key={index}
                >
                  <AsyncImage
                    key={index}
                    src={
                      isValidUrl(item?.bannerImage)
                        ? item?.bannerImage
                        : DefaultImg
                    }
                    loading="lazy"
                    Transition={Blur}
                    style={{ height: "220px" }}
                    loader={
                      <img
                        src={item?.bannerImage}
                        style={{ filter: "blur(25px)" }}
                      />
                    }
                  />
                  <div className="info wrap-explore-crew-search">
                    <div
                      className="model"
                      style={{ fontSize: "20px", fontWeight: "bold" }}
                    >
                      {item?.name}
                    </div>
                    <div className="name">
                      {" "}
                      {item?.description?.length > 20
                        ? `${sliceStringAtWord(item?.description, 20)}`
                        : `${item?.description}`}
                    </div>
                  </div>
                  {item?.membersCount > 0 && (
                    <div
                      className="group-2"
                      style={{
                        position: "absolute",
                        top: "10px",
                        left: "10px",
                        display: "flex",
                        flexDirection: "row-reverse",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "10px",
                        color: "#fff",
                      }}
                    >
                      <div
                        className="text-wrapper-3"
                        style={{
                          fontSize: isMobile ? "8px" : "14px",
                          fontWeight: 400,
                        }}
                      >
                        {item?.membersCount} members
                      </div>
                      <div style={{ marginLeft: "10px" }}>
                        <MultipleUsers
                          className="multiple-users-instance"
                          listMultiple={item?.membersList}
                          property1="users-sm"
                        />
                      </div>
                    </div>
                  )}
                </Link>
              </>
            ))}
          </div>
        )}
      </InfiniteScroll>
    </>
  );
};

export default ExploreCrew;
