import { ThreeDots } from "@utils/defaultImages";
import InboxMute from "@assets/InboxMute.svg";
import InboxPin from "@assets/InboxPin.svg";
import messageDelete from "@assets/messageDelete.svg";
import React, { useState } from "react";
import WarningModal from "@components/Modal/WarningModal";
import { useDelChatMutation } from "@redux/services/messenger/messengerService";
import { toast } from "react-toastify";
const UserListThreeDot = ({ itemMsg, filterInboxList }) => {
  const [openDelModal, setOpenDelModal] = useState(false);
  const svgs = [
    // { component: InboxPin, label: "Pin" },
    // { component: InboxMute, label: "Mute" },
    { component: messageDelete, label: "Delete" },
  ];
  /**
   * API CALLS
   */
  const [DeleteChat, {isLoading}] = useDelChatMutation();
  const handleAction = async (label) => {
    switch (label) {
      case "Delete":
        return setOpenDelModal(!openDelModal);
      default:
        return;
    }
  };

  const handleDelete = async () => {
    try {
      const res = await DeleteChat(itemMsg?.roomId)?.unwrap();

      if (res) {
        filterInboxList((prev) =>
          prev?.filter((value) => value?.roomId !== itemMsg?.roomId)
        );
        setOpenDelModal(!openDelModal);
      }
    } catch (error) {
      toast.error(error?.data?.message);
    }
  };
  return (
    <>
      <div className="dropdown drop-wrapper">
        <button
          className="btn btn-secondary dropdown-toggle three-dots"
          type="button"
          id="dropdownMenuButton"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          // onClick={(e)=>e.stopPropagation()}
        >
          <ThreeDots />
        </button>
        <ul
          className="dropdown-menu dropdown-menu-dark wrapp-list"
          aria-labelledby="dropdownMenuButton"
        >
          {svgs.map(({ component: SvgComponent, label }, index) => (
            <li key={index}>
              <div
                className={`dropdown-item ${
                  index === svgs.length - 1 && "m-0"
                }`}
                onClick={(e) => {
                  e.stopPropagation();
                  handleAction(label);
                }}
              >
                <img src={SvgComponent} alt="icon" className="dropdown-icon" />
                <span className="dropdown-label">{label}</span>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <WarningModal
        open={openDelModal}
        closeModal={setOpenDelModal}
        chatMsg={true}
        deleteBtn={handleDelete}
        Loading={isLoading}
      />
    </>
  );
};

export default UserListThreeDot;
