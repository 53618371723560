import {
  ShareImage,
  EditProfile,
  Invite,
  Settings,
  MembershipBoard,
  ThreeDots,
} from "@utils/defaultImages";
import React, { useState } from "react";
import { toast } from "react-toastify";
import MembershipDashboardModal from "./MembershipDashboard/membershipDashboardModal";
const ThreeDotsModal = ({ isFetching, refetch, userInfo }) => {
  const [membershipOpen, setMembershipOpen] = useState(false);
  const svgs = [
    // { component: ShareImage, label: "Share" },
    { component: MembershipBoard, label: "Membership Dashboard" },
    // { component: Settings, label: "Settings" },
    // { component: EditProfile, label: "Edit" },
    // { component: Invite, label: "Invite people to Carvonix" }
  ];

  const handleAction = async (key) => {
    switch (key) {
      case "Membership Dashboard":
        return setMembershipOpen(true);

      // case "Delete":
      //   return setOpenDelModal(!openDelModal);
      default:
        return;
    }
  };
  // const closeThreeDotsModal = (key) => {
  //   console.log(key);
  //   if (key === "membership") {
  //     setMembershipOpen(true);
  //   }
  // };
  return (
    <>
      <div className="dropdown drop-wrapper">
        <button
          className="btn btn-secondary dropdown-toggle three-dots"
          type="button"
          id="dropdownMenuButton" // Add ID to target the dropdown toggle
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <ThreeDots />
        </button>
        <ul
          className="dropdown-menu dropdown-menu-dark wrapp-list"
          aria-labelledby="dropdownMenuButton"
        >
          {svgs.map(({ component: SvgComponent, label }, index) => (
            <li key={index}>
              <div
                className={`dropdown-item ${
                  index === svgs.length - 1 && "m-0"
                }`}
                onClick={() => handleAction(label)}
              >
                <img src={SvgComponent} alt="icon" className="dropdown-icon" />
                <span className="dropdown-label">{label}</span>
              </div>
            </li>
          ))}
        </ul>
      </div>
      {membershipOpen && (
        <MembershipDashboardModal
          open={membershipOpen}
          closeModal={() => setMembershipOpen(false)}
          userInfo={userInfo}
          refetch={refetch}
          isFetching={isFetching}
        />
      )}
    </>
  );
};

export default ThreeDotsModal;
