import React, { useState, useMemo, useEffect, useCallback, useRef } from "react";
import { useInView } from "react-intersection-observer";
import { useLocation, useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { CloseStories, StoriesUp, StoriesDown } from "@utils/defaultImages";
import {
  useLazyListStoriesQuery,
  useSeenStoryMutation,
} from "@redux/services/Stories/storyServices";

import useMediaQueryHook from "@utils/hooks/useMediaQueryHook";
import { toast } from "react-toastify";
import StoriesSkeleton from "@components/Skeleton/StoriesSkeleton";
import "./StoriesView.scss";
import StoryComponent from "../subComponents/StoryComponent";

const StoriesView = () => {
  const { isMobileSmall, isMobile, isTablet, isTabScr } = useMediaQueryHook();
  const loginUserInfo = useSelector((state) => state?.auth?.loginUserData);
  const navigate = useNavigate();
  const location = useLocation();
  const stories = location?.state?.stories;
  const storyRefs = useRef({});
  const isLoadingRef = useRef(false);
  const containerRef = useRef(null);

  const [page, setPage] = useState(1);
  const [storiesFilter, setStoriesFilter] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [listStories, { isLoading, isFetching }] = useLazyListStoriesQuery();
  const [seenUnseen] = useSeenStoryMutation();

  const { ref: loadMoreRef, inView: isLoadMoreVisible } = useInView({
    threshold: 0.1,
    rootMargin: "200px",
  });

  const storyStyles = useMemo(
    () => ({
      innerStyle: { justifyContent: "center" },
      containerStyle: { maxWidth: "482px", maxHeight: "853px" },
      progressStyle: {
        position: "absolute",
        bottom: "0",
        width: "94%",
        borderRadius: "22px",
        zIndex: 9999,
      },
    }),
    []
  );

  const scrollToStory = useCallback((index) => {
    const storyElement = storyRefs.current[index];
    if (storyElement && containerRef.current) {
      const container = containerRef.current;
      const elementTop = storyElement.offsetTop;
      const containerTop = container.scrollTop;
      const containerHeight = container.clientHeight;
      const elementHeight = storyElement.clientHeight;
      
      // Calculate center position
      const targetScrollTop = elementTop - (containerHeight / 2) + (elementHeight / 2);
      
      container.scrollTo({
        top: targetScrollTop,
        behavior: 'smooth'
      });
    }
  }, []);

  const handleNavigateStory = useCallback((direction) => {
    if (direction === 'up' && currentIndex > 0) {
      const newIndex = currentIndex - 1;
      setCurrentIndex(newIndex);
      // Add small delay to ensure DOM is ready
      setTimeout(() => scrollToStory(newIndex), 50);
    } else if (direction === 'down' && currentIndex < storiesFilter.length - 1) {
      const newIndex = currentIndex + 1;
      setCurrentIndex(newIndex);
      // Add small delay to ensure DOM is ready
      setTimeout(() => scrollToStory(newIndex), 50);
      
      if (newIndex === storiesFilter.length - 2 && hasNextPage) {
        handleNextPage();
      }
    }
  }, [currentIndex, storiesFilter.length, hasNextPage, scrollToStory]);

  const handleStoriesWatch = useCallback(
    async (value) => {
      if (!value?.item?.isSeen && loginUserInfo?._id !== value?.getStory?._id) {
        try {
          await seenUnseen(value?.item?._id).unwrap();
        } catch (error) {
          toast.error(error?.data?.message);
        }
      }
    },
    [loginUserInfo, seenUnseen]
  );

  const fetchStories = useCallback(async () => {
    if (isLoadingRef.current) return;
    
    try {
      isLoadingRef.current = true;
      const response = await listStories(page).unwrap();
      setHasNextPage(response?.data?.hasNextPage);

      let filteredStories =
        response?.data?.docs?.filter(
          (item) => item?.stories?.length > 0 && item?._id !== stories?._id
        ) || [];

      if (stories && page === 1) {
        filteredStories.unshift(stories);
      }

      setStoriesFilter((prev) =>
        page > 1 ? [...prev, ...filteredStories] : filteredStories
      );
    } catch (error) {
      toast.error("Failed to fetch stories");
    } finally {
      isLoadingRef.current = false;
    }
  }, [page, stories, listStories]);

  const handleNextPage = useCallback(() => {
    if (hasNextPage && !isLoadingRef.current) {
      setPage((prev) => prev + 1);
    }
  }, [hasNextPage]);

  useEffect(() => {
    if (page === 1) {
      fetchStories();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (page > 1) {
      fetchStories();
    }
  }, [page, fetchStories]);

  useEffect(() => {
    if (isLoadMoreVisible && hasNextPage && !isLoadingRef.current) {
      handleNextPage();
    }
  }, [isLoadMoreVisible, hasNextPage, handleNextPage]);

  const dynamicHeight = useMemo(
    () =>
      isMobileSmall || isMobile
        ? "83vh"
        : isTablet
        ? "88vh"
        : isTabScr
        ? "85vh"
        : "100vh",
    [isMobileSmall, isMobile, isTablet, isTabScr]
  );

  const storyComponents = useMemo(
    () =>
      storiesFilter?.map((storyItem, storyIndex) => (
        <div 
          key={`story-wrapper-${storyIndex}`} 
          ref={el => storyRefs.current[storyIndex] = el}
          className="story-wrapper"
        >
          <StoryComponent
            key={`story-${storyIndex}`}
            storyItem={storyItem}
            storyIndex={storyIndex}
            currentIndex={currentIndex}
            setCurrentIndex={setCurrentIndex}
            handleStoriesWatch={handleStoriesWatch}
            storyStyles={storyStyles}
            dynamicHeight={dynamicHeight}
          />
        </div>
      )),
    [
      storiesFilter,
      currentIndex,
      handleStoriesWatch,
      storyStyles,
      dynamicHeight,
    ]
  );

  if (isLoading && page === 1) return <StoriesSkeleton />;

  return (
    <>
      <div 
        ref={containerRef}
        className="container stories-container"
        style={{ 
          height: '100vh',
          overflowY: 'auto',
          scrollBehavior: 'smooth'
        }}
      >
        {isFetching && page === 1 ? (
          <StoriesSkeleton />
        ) : (
          <div className="row">
            {storyComponents}
            {hasNextPage && (
              <div ref={loadMoreRef} style={{ height: "20px" }} />
            )}
          </div>
        )}
      </div>
      {!(isMobile || isMobileSmall) && (
        <div>
          <CloseStories className="pointer" onClick={() => navigate(-1)} />
          <div className="d-flex flex-column gap-3 position-fixed top-50">
            {currentIndex > 0 && (
              <StoriesUp
                onClick={() => handleNavigateStory('up')}
                className="pointer"
              />
            )}
            {(currentIndex < storiesFilter.length - 1 || hasNextPage) && (
              <StoriesDown 
                onClick={() => handleNavigateStory('down')}
                className="pointer"
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default StoriesView;