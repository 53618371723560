import React, { useEffect } from "react";
import UserImg from "@assets/defaultUser.png";
import { Link } from "react-router-dom";
import { isValidUrl } from "@utils/validUrl";
import FadingLoader from "@components/Skeleton/CrewSkeleton";
import NotFound from "@components/NotFound";
import { useLazyGetModeratorQuery } from "@redux/services/crewService/allCrewService";

const Moderator = ({ crewId }) => {
  /**
   * USESTATES
   */
  // const [loadingState, setLoadingState] = useState({});

  //
  /**
   * API CALLS
   */
  const [getModerator, { data: { data } = {}, isLoading }] =
    useLazyGetModeratorQuery();
  //  const [followMM] = useFollowMMMutation();
  //  const [removefollowMM] = useRemovefollowMMMutation();
  //
  /**
   * FUNCTIONS
   */
  const moderators = async () => {
    try {
      await getModerator(crewId).unwrap();
    } catch (error) {
      console.log("error", error);
    }
  };

  // const followUnFollowAccount = async (item) => {
  //   const body = {
  //     followToUserId: item?.userId,
  //   };
  //   setLoadingState((prev) => ({ ...prev, [item?.userId]: true }));
  //   try {
  //     if (item?.followingStatus === 2) {
  //       const unFollow = await followMM(item?.userId).unwrap();
  //     } else {
  //       const follow = await removefollowMM(body).unwrap();
  //     }
  //     setLoadingState((prev) => ({ ...prev, [item?.userId]: false }));
  //   } catch (error) {
  //     toast.error(error?.data?.message);
  //     setLoadingState((prev) => ({ ...prev, [item?.userId]: false }));
  //   }
  // };
  //
  /**
   * USEEFFECT
   */
  useEffect(() => {
    moderators();
  }, []);
  //

  return (
    <div>
      {isLoading ? (
        <FadingLoader />
      ) : data?.docs?.length > 0 ? (
        data?.docs?.map((item) => {
          return (
            <Link
              className="list-block user-list-block"
              to={`/profile/${item?._id}`}
              key={item?._id}
              style={{
                marginTop: "22px",
                // borderBottom:
                //   likeResult?.postReactionRecords?.docs?.length > 1
                //     ? "1px solid #212225"
                //     : "none",
              }}
            >
              <div
                className="list-block"
                style={{
                  borderBottom: "none",
                  color: "#fff",
                  padding: "unset",
                  marginBottom: "unset",
                }}
              >
                <img
                  src={
                    isValidUrl(item?.profileImage)
                      ? item?.profileImage
                      : UserImg
                  }
                  alt={item?.username}
                  className="main-img"
                />
                <div className="list-info">
                  <h4>{item?.username}</h4>
                  <p>
                    {item?.firstName}
                    {item?.lastName}
                  </p>
                </div>
              </div>
              {/* <button
                className="default-btn"
                style={{
                  backgroundColor:
                    item?.followingStatus === 2 ? "#323334" : "#387aff",
                  borderColor:
                    item?.followingStatus === 2 ? "#8E8E93" : "#387aff",
                }}
                onClick={() => followUnFollowAccount(item)}
                disabled={loadingState[item?.userId]}
              >
                {loadingState[item?.userId] ? (
                  <>
                    <ButtonLoader />
                  </>
                ) : item?.followingStatus === 2 ? (
                  "Following"
                ) : (
                  "Follow"
                )}
              </button> */}
            </Link>
          );
        })
      ) : (
        <div className="mt-5">
          <NotFound message="Moderator" />
        </div>
      )}
    </div>
  );
};

export default Moderator;
