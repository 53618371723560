import LandingFooter from "@components/layouts/LandingFooter";
import LandingHeader from "@components/layouts/LandingHeader";
import React from "react";
import "./Privacy.scss";
import { Link } from "react-router-dom";

const PrivacyPolicy = () => {
  return (
    <>
      <LandingHeader />
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="privacy-policy">
              <h1>
                <strong>Privacy Policy</strong>
              </h1>
              {/* <p>&nbsp;</p> */}
              <p>
                <span size="2">
                  This Privacy Policy (the &ldquo;Privacy Policy&rdquo;)
                  describes how Carvonix, LLC ( &ldquo;we&rdquo;,
                  &ldquo;us&rdquo;, or &ldquo;our&rdquo;) collect, use, and
                  disclose your Personal Information when you visit or make a
                  purchase from our properties or content, including websites,
                  mobile apps, widgets, APIs, emails, and other online products
                  and services (the &ldquo;Site&rdquo; or &ldquo;Sites&rdquo;)
                  or contact us through other communication methods such as
                  through email, and over the phone. This Privacy Policy is
                  governed by and subject to the&nbsp;
                </span>
                <Link to="/terms-and-conditions">
                  <span>
                    <u>Terms of Use</u>
                  </span>
                </Link>{" "}
                (&ldquo;Terms&rdquo;).
              </p>
              <p>
                This Privacy Policy contains detailed information about our data
                privacy practices. For purposes of this Privacy Policy, Personal
                Information means information relating directly or indirectly to
                an identifiable individual, except as may be set forth in
                applicable law.
              </p>
              <p>
                Please read this Privacy Policy along with other agreements and
                policies that may apply to your use of the Sites. By using any
                Site, you agree to our privacy practices set forth in this
                Privacy Policy. IF YOU DO NOT AGREE TO THIS PRIVACY POLICY,
                PLEASE DO NOT ACCESS THE SITES, ENGAGE US FOR ANY SERVICES, OR
                OTHERWISE PROVIDE US PERSONAL INFORMATION.
              </p>
              <p>
                This Privacy Policy does not apply to websites, applications, or
                services that do not display or link to this webpage, are not
                operated by us, or that display or link to different privacy
                statements. Please see Third Party Websites and Applications
                below for details.
              </p>
              <p>&nbsp;</p>
              <h3>
                <strong>
                  Personal Information We Collect and How We Use It
                </strong>
              </h3>
              <p>
                When you visit the Sites and use certain portions of the Sites,
                we may collect certain Personal Information about you. as set
                forth below.
              </p>
              {/* <p>
                <br />
                &nbsp;
              </p> */}
              <p className="my-2">
                <u>Device information</u>
              </p>
              <ul>
                <li>
                  <p>
                    <span size="2">
                      <strong>
                        Examples of Personal Information collected:
                      </strong>
                    </span>{" "}
                    Version of web browser, IP address, time zone, cookie
                    information, what Sites or products on our Sites you view,
                    search engine terms that you entered to reach the Site, how
                    you interact with the Site, and real-time device location.
                  </p>
                </li>
                <li>
                  <p>
                    <span size="2">
                      <strong>Purpose of collection:</strong>
                    </span>{" "}
                    To provide, deliver, secure, improve, optimize, and analyze
                    use of our Sites. Real time device location is used only to
                    enable route-planning features and to direct your
                    device&rsquo;s map application to your current location.
                  </p>
                </li>
                <li>
                  <p>
                    <span size="2">
                      <strong>Source of collection:</strong>
                    </span>{" "}
                    Collected automatically when you access our Site using
                    cookies, log files, web beacons, tags, pixels, or similar
                    technologies. Please see the heading &ldquo;Cookies&rdquo;
                    below for additional details.&nbsp;
                  </p>
                </li>
                <li>
                  <p>
                    <span size="2">
                      <strong>Disclosure for a business purpose:</strong>
                    </span>{" "}
                    We provide access to or disclose this information with
                    select third parties who perform services on our behalf.
                    They have access to your information to perform these
                    services but are prohibited from using your information for
                    other purposes.&nbsp;
                  </p>
                </li>
              </ul>
              {/* <p>
                <br />
                &nbsp;
              </p> */}
              <p className="my-2">
                <u>Customer support information</u>
              </p>
              <ul>
                <li>
                  <p>
                    <span size="2">
                      <strong>
                        Examples of Personal Information collected:&nbsp;
                      </strong>
                    </span>
                    Name, email, and other information you may provide in the
                    context of a support request.
                  </p>
                </li>
                <li>
                  <p>
                    <span size="2">
                      <strong>Purpose of collection:</strong>
                    </span>{" "}
                    To provide customer support and help resolve technical,
                    order-related, product-related, or other questions you may
                    have.
                  </p>
                </li>
                <li>
                  <p>
                    <span size="2">
                      <strong>Source of collection:</strong>
                    </span>{" "}
                    Collected from you.
                  </p>
                </li>
                <li>
                  <p>
                    <span size="2">
                      <strong>Disclosure for a business purpose:</strong>
                    </span>{" "}
                    We provide access to or disclose this information with
                    select third parties who perform services on our behalf.
                    They have access to your information to perform these
                    services but are prohibited from using your information for
                    other purposes.
                  </p>
                </li>
              </ul>
              <p className="my-2">
                <u>Profile Information</u>
              </p>
              <ul>
                <li>
                  <p>
                    Examples of Personal Information collected: Name, profile
                    avatar or photo, banner image, username, email address,
                    gender, month and day of birth, phone number, city and
                    state, vehicle identification number, and any information
                    shared in biography details and social media links.
                  </p>
                </li>
                <li>
                  <p>
                    <span size="2">Purpose of collection:&nbsp;</span>
                    <span size="2">
                      <span>To enable</span>
                    </span>
                    <span size="2">&nbsp;creation</span>
                    <span size="2">
                      <span>&nbsp;of&nbsp;</span>
                    </span>
                    <span size="2">a&nbsp;</span>
                    <span size="2">
                      <span>user profile</span>
                    </span>
                    <span size="2">&nbsp;for you</span>
                    <span>.</span>
                  </p>
                </li>
                <li>
                  <p>
                    <span size="2">Source of collection:&nbsp;</span>
                    <span>Collected from you.</span>
                  </p>
                </li>
                <li>
                  <p>
                    Disclosure for a business purpose: We provide access to or
                    disclose this information with select third parties who
                    perform services on our behalf. They have access to your
                    information to perform these services but are prohibited
                    from using your information for other purposes. Your Profile
                    Information may be made available to all other Users.&nbsp;
                  </p>
                </li>
              </ul>
              <p className="my-2">
                <u>User Submissions</u>
              </p>
              <ul>
                <li>
                  <p>
                    <span size="2">
                      Examples of Personal Information collected:&nbsp;
                    </span>
                    <span size="2">
                      <span>As&nbsp;</span>
                    </span>
                    set forth and defined in the Terms as &ldquo;User
                    Submissions&rdquo; which may include messages sent to other
                    users of the Site, comments, &ldquo;likes,&rdquo; photos,
                    and videos.&nbsp;
                  </p>
                </li>
                <li>
                  <p>
                    Purpose of collection: To enable use of certain Sites of
                    certain functionality provided by Sites.
                  </p>
                </li>
                <li>
                  <p>Source of collection: Collected from you.</p>
                </li>
                <li>
                  <p>
                    Disclosure for a business purpose: Your User Submissions may
                    be made available to all other Users and our affiliates as
                    set forth below under Disclosing Personal Information.&nbsp;
                  </p>
                </li>
              </ul>
              {/* <p>
                <br />
                &nbsp;
              </p> */}
              <p>
                If you provide Personal Information of a third party, you are
                responsible for providing any notices and obtaining any consents
                necessary for us to collect and use such Personal Information as
                described in this Privacy Policy.
              </p>
              <h3>
                <strong>Minors</strong>
              </h3>
              <p>
                <span size="2">
                  The Site is not intended for individuals under the age of
                </span>
                <span size="2">
                  <em>&nbsp;18</em>
                </span>
                <span size="2">
                  . We do not intentionally collect Personal Information from
                  children under 18. If you are the parent or guardian and
                  believe your child has provided us with Personal Information,
                  please contact us at the address below to request deletion. If
                  we become aware that we have inadvertently collected or
                  received Personal Information from children under the age of
                  13, we will take reasonable steps to delete it as soon as
                  possible.&nbsp;
                </span>
                <span>
                  If a minor has posted User Submissions on our Websites, we may
                  not be able to delete them but we may be able to cease
                  displaying them or remove their name from the User
                  Submission.&nbsp;
                </span>
              </p>
              <h3>
                <strong>Disclosing Personal Information</strong>
              </h3>
              <p>
                In addition to the disclosures described above, we may disclose
                your Personal Information as set forth below:
              </p>
              <ul>
                <li>
                  <p>
                    Service Providers: We disclose your Personal Information
                    with service providers to help us provide our services and
                    fulfill our contracts with you, as described above. They
                    have access to your information to perform these services
                    but are prohibited from using your information for other
                    purposes. They provide a variety of services to us,
                    including network access, data storage and/or processing,
                    billing, sales, product content and features, research,
                    customer service, security, fraud prevention, payment
                    processing, marketing and advertising, website and other
                    analytics, user communication, and legal services.&nbsp;
                  </p>
                </li>
                <li>
                  <p>
                    Legal Obligation: We may disclose your Personal Information
                    to comply with applicable laws and regulations, to respond
                    to a subpoena, search warrant or other lawful request for
                    information we receive.
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              Business Transfers: As we continue to develop our
                              business, we may buy, merge, or partner with other
                              companies. In such transactions (including in
                              contemplation of such transactions), user
                              information may be among the transferred assets.
                              If a portion or all of our assets are sold or
                              transferred to a third-party, customer information
                              (including information processed in accordance
                              with this Privacy Policy) may be one of the
                              transferred business assets. If such transfer is
                              subject to additional mandatory restrictions under
                              applicable laws, we will comply with such
                              restrictions.
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </p>
                </li>
              </ul>
              {/* <p>
                <br />
              </p> */}
              <ul>
                <li>
                  <p>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              Consent:&nbsp;We may disclose your information
                              when we have your consent.
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </p>
                </li>
              </ul>
              {/* <p>
                <br />
              </p> */}
              <ul>
                <li>
                  <p>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              Audit and Antifraud: We may disclose your
                              information for audit purposes and to protect our
                              rights, property or personal safety, or that of
                              our users and/or the public. This includes
                              exchanging information with other companies and
                              organizations for fraud protection, spam/malware
                              prevention, and similar purposes.
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </p>
                </li>
              </ul>
              {/* <p>
                <br />
              </p> */}
              <ul>
                <li>
                  <p>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              Aggregated or Deidentified Information:&nbsp;From
                              time to time, we may disclose aggregated or
                              deidentified information about use of the Sites,
                              such as by publishing a report on usage trends.
                              The disclosure of such data is unrestricted.
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </p>
                </li>
              </ul>
              {/* <p>
                <br />
              </p> */}
              <ul>
                <li>
                  <p>
                    Intracompany:&nbsp;We may disclose your information to our
                    affiliates and subsidiaries to operate our business, for
                    example, if we establish other related companies, we may
                    disclose your&nbsp;information to those companies to enable
                    and support their operations.
                  </p>
                </li>
              </ul>
              <p>
                <br />
                &nbsp;
              </p>
              <h3>
                <strong>Using Personal Information</strong>
              </h3>
              <p>
                In addition to the uses described above, we may use your
                Personal Information as set forth below:
              </p>
              <ul>
                <li>
                  <p>
                    <span size="2">
                      <strong>To run and operate our Site and business.</strong>
                    </span>{" "}
                    We may need your information to display content on the Site
                    correctly and to otherwise provide, deliver, improve,
                    secure, optimize, personalize, and analyze use of our Sites.
                    We may also use your personal information to maintain the
                    security of our other products and services as well as our
                    databases, other technology assets, and business.
                  </p>
                </li>
                <li>
                  <p>
                    <span size="2">
                      <strong>To improve customer service.</strong>
                    </span>{" "}
                    Information you provide helps us respond to your customer
                    service requests and support needs more efficiently and
                    perform our contractual obligations.
                  </p>
                </li>
                <li>
                  <p>
                    <span size="2">
                      <strong>To personalize user experience.</strong>
                    </span>{" "}
                    We may use also information in the aggregate to understand
                    how our users as a group use the services and resources
                    provided on our Sites.
                  </p>
                </li>
                <li>
                  <p>
                    <span size="2">
                      <strong>To conduct marketing activities.</strong>
                    </span>{" "}
                    To send you information, in accordance with applicable law,
                    about topics we think will be of interest to you.
                  </p>
                </li>
                <li>
                  <p>
                    <span size="2">
                      <strong>In the event of a business transaction.</strong>
                    </span>{" "}
                    We may use information to in the event of a sale, merger,
                    consolidation, change in control, transfer of substantial
                    assets, reorganization, or liquidation, we may transfer,
                    sell, or assign to third parties information concerning your
                    relationship with us, including, without limitation,
                    personally identifiable information that you provide and
                    other information concerning your relationship with us.
                  </p>
                </li>
                <li>
                  <p>
                    <span size="2">
                      <strong>For recruiting activities.&nbsp;</strong>
                    </span>
                    To conduct recruiting and hiring activities for
                    opportunities with us. If you apply to work with or for us,
                    we will review the information you submit to determine if
                    your qualifications and experience match any available
                    opportunities with us, to verify the information you
                    provide, communicate with you regarding any opportunities,
                    improve our recruiting processes, process your onboarding if
                    hired, and comply with applicable labor and employment laws.
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      <span>
                        <span>
                          <strong>
                            <span>For legal compliance</span>
                          </strong>
                        </span>
                      </span>
                    </span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              . To comply with any procedures, laws, and
                              regulations which apply to us or our processing.
                              For example, we comply with subpoenas and other
                              court orders to process data where we have
                              determined there is a legal requirement for us to
                              do so.
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      <span>
                        <span>
                          <strong>
                            <span>
                              Establish, exercise, or defend our legal rights
                            </span>
                          </strong>
                        </span>
                      </span>
                    </span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              . We may process your personal information in
                              connection with establishing, exercising, or
                              defending our legal rights where it is necessary
                              for our legitimate interests or the legitimate
                              interests of others or for fraud prevention. For
                              example, we may process information you provide to
                              identify any fraudulent, harmful, unauthorized,
                              unethical, or illegal activity such as use of
                              another individual&rsquo;s identity and we may use
                              your information as necessary to defend ourselves
                              in litigation or enforce our rights or agreements
                              with others.
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      <span>
                        <span>
                          <strong>
                            <span>
                              Other legitimate purposes as required or permitted
                              by applicable law.&nbsp;
                            </span>
                          </strong>
                        </span>
                      </span>
                    </span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              We may process your information for other purposes
                              consistent with those in this Privacy Policy, as
                              may be permitted or required by applicable law, as
                              otherwise disclosed to you, or as otherwise with
                              your consent.
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </p>
                </li>
              </ul>
              {/* <p>
                <br />
                &nbsp;
              </p>
              <p>
                <br />
                &nbsp;
              </p>
              <p>
                <br />
                &nbsp;
              </p> */}
              <p>
                <br />
                &nbsp;
              </p>
              <h3>
                <span>
                  <strong>
                    Additional Disclosures for California Residents
                  </strong>
                </span>
              </h3>
              <p>
                <span>
                  These additional disclosures for California residents apply
                  only to individuals who reside in California. The California
                  Privacy Rights Act (&ldquo;CPRA&rdquo;) provides additional
                  rights to know, delete and opt out, and requires businesses
                  collecting or disclosing personal information to provide
                  notices and means to exercise rights.
                </span>
              </p>
              <h4 className="my-3">
                <span>
                  <span>
                    <u>California Notice of Collection</u>
                  </span>
                </span>
              </h4>
              <p>
                <span>
                  We may collect, and may have in the past 12 months collected,
                  the following categories of Personal Information enumerated in
                  the CPRA:
                </span>
              </p>
              <ul>
                <li>
                  <p>
                    <span>
                      <strong>Identifiers,</strong>
                    </span>
                    <span>
                      &nbsp;such as name, email address, phone number, account
                      name, IP address, and an ID or number assigned to your
                      account.
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      <strong>Demographics,</strong>
                    </span>
                    <span>
                      &nbsp;such as your gender. This category includes data
                      that may qualify as protected classifications under other
                      California or federal laws.
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      <strong>Internet activity,</strong>
                    </span>
                    <span>
                      &nbsp;such as your interactions with our Websites.
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      <strong>Audio or visual data,</strong>
                    </span>
                    <span>
                      &nbsp;such as photos or videos you share with us or post
                      on the Websites.
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      <strong>Geolocation data,&nbsp;</strong>
                    </span>
                    <span>
                      such as your location to plan a driving route on the
                      Websites.
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      <strong>Employment and education data,</strong>
                    </span>
                    <span>
                      &nbsp;such as data you provide when you apply for a job
                      with us.
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      <strong>Inferences,</strong>
                    </span>
                    <span>&nbsp;such as information about your interests.</span>
                  </p>
                </li>
              </ul>
              <p>
                <span>
                  For more information on information we collect, including the
                  sources we receive information from, review the
                  &ldquo;Personal Information We Collect and How We Use
                  It&rdquo; section in our Privacy Policy. We collect and use
                  these categories of Personal Information for the business
                  purposes described in the &ldquo;Using Personal
                  Information&rdquo; section.
                </span>
              </p>
              <p>
                <span>
                  We use and partner with different types of entities to assist
                  with our daily operations and manage our Websites. Please
                  review the &ldquo;Disclosing Personal Information&rdquo;
                  section for more detail about the parties to whom we disclose
                  information.&nbsp;
                </span>
              </p>
              <h4 className="my-3">
                <span>
                  <span>
                    <u>Right To Know</u>
                  </span>
                </span>
              </h4>
              <p>
                <span>
                  If you are a California resident, you have the right to know
                  certain information about our data practices. In particular,
                  you have the right to request the following from us:
                </span>
              </p>
              <ul>
                <li>
                  <p>
                    <span>
                      The categories of Personal Information we have collected
                      about you;
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      The categories of sources from which the Personal
                      Information was collected;
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      The categories of Personal Information about you we
                      disclosed for a business purpose, sold, or shared, and the
                      categories of third parties to whom the Personal
                      Information was so disclosed, sold or shared, by category
                      or categories of Personal Information for each category of
                      third parties or persons to whom the Personal Information
                      was disclosed, sold, or shared;
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      The categories of third parties to whom the Personal
                      Information was disclosed for a business purpose, sold, or
                      shared;
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      The business or commercial purpose for collecting,
                      selling, or sharing the Personal Information; and
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      The specific pieces of Personal Information we have
                      collected about you.
                    </span>
                  </p>
                </li>
              </ul>
              <p>
                <span>
                  We do not as a matter of course &ldquo;sell&rdquo; or
                  &ldquo;share&rdquo; Personal Information as defined in the
                  CPRA.
                </span>
              </p>
              <h4 className="my-3">
                <span>
                  <span>
                    <u>Right To Delete</u>
                  </span>
                </span>
              </h4>
              <p>
                <span>
                  You may request deletion of Personal Information by contacting
                  us and providing the required verification information and
                  other details we need in order to process the request
                  appropriately. We may, to the extent permitted under
                  applicable law and regulations, retain and continue to process
                  data where a CPRA or other exception applies. We will inform
                  you if such an exception applies.&nbsp;
                </span>
                <span>
                  If you have posted User Submissions on our Websites, we may
                  not be able to delete them but we may be able to cease
                  displaying them or remove your name from the User
                  Submission.&nbsp;
                </span>
              </p>
              <h4 className="my-3">
                <span>
                  <span>
                    <u>Right To Correct</u>
                  </span>
                </span>
              </h4>
              <p>
                <span>
                  You may request that we correct inaccurate Personal
                  Information. We may, to the extent permitted under applicable
                  law and regulations, decline to correct data where a CPRA or
                  other exception applies. We will inform you if such an
                  exception applies. We may also elect to delete information
                  instead of correcting it if such deletion does not negatively
                  impact you or you consent to the deletion.
                </span>
              </p>
              <h4 className="my-3">
                <span>
                  <span>
                    <u>Right To Non-Discrimination and Non-Retaliation</u>
                  </span>
                </span>
              </h4>
              <p>
                <span>
                  You have the right not to receive discriminatory or
                  retaliatory treatment by us for the exercise of any your
                  rights. If you believe you have received discriminatory or
                  retaliatory treatment by us for exercising your CPRA rights,
                  please contact us.
                </span>
              </p>
              <h4 className="my-3">
                <span>
                  <span>
                    <u>Exercising Your Rights</u>
                  </span>
                </span>
              </h4>
              <p>
                To exercise your CPRA rights, please view our data-related tools
                in the Website (such as a profile page in a mobile application
                and options to revise or delete User Submissions you have made)
                or contact us as set forth below in the Contact section. In your
                request, please specify which right you are seeking to exercise
                and the scope of the request. We may require specific
                information from you to help us verify your identity and process
                your request. If we are unable to verify your identity, we may
                deny your request. You can designate an authorized agent to
                submit requests on your behalf. However, we will require written
                proof of the agent&rsquo;s permission to do so and verify your
                identity directly.
              </p>
              <p>
                When you submit a request, do not send us, directly or
                indirectly, any sensitive or special categories of Personal
                Information (e.g., social security numbers or other national or
                state identifiers, driver licenses, health information,
                biometric data or genetic characteristics, criminal background
                information, financial account numbers, payment card
                information, and so on).
              </p>
              <p>
                Once we have verified a request from a California consumer,
                where required, we will confirm receipt of the request within 10
                business days and explain how we will process the request. We
                will make our best effort to respond to a verifiable consumer
                request within 45 days of its receipt. We may require an
                additional 45 days (for a total of 90 days) to process your
                request, but in those situations, we will provide you a response
                and explanation for the reason it will take more than 45 days to
                respond to the request. Our responses will include required
                information under the CPRA. For right to access requests, we
                will select a format that is readily usable and should allow you
                to transmit the information from one entity to another entity
                without hindrance. We do not charge a fee to process your
                verifiable consumer request unless it is manifestly unfounded or
                excessive. If we determine that the request warrants a fee, we
                will tell you why we made that decision and provide you with a
                cost estimate before completing your request.
              </p>
              {/* <p>
                <br />
                &nbsp;
              </p>
              <p>
                <br />
                &nbsp;
              </p>
              <p>
                <br />
                &nbsp;
              </p> */}
              <h3>
                <strong>Your Rights and Choices</strong>
              </h3>
              <p className="my-3">
                <u>Cookies</u>
              </p>
              <p className="my-3">
                <span size="2">
                  Please see the Cookies section below.&nbsp;
                </span>
              </p>
              <p className="my-3">
                <u>Choosing Not to Share Your Personal Information</u>
              </p>
              <p>
                You may choose not to provide Personal Information. If you
                choose not to provide personal information (or ask us to delete
                it), we may not be able to provide you with our Sites or certain
                functionality of the Sites. We will tell you what information
                you must provide to receive the Sites&rsquo; functionality by
                designating it as required at the time of collection or through
                other appropriate means.
              </p>
              <p className="my-3">
                <u>Marketing Communications and Sharing</u>
              </p>
              <p>
                You may instruct us not to use your contact information to
                contact you by email, postal mail, or phone regarding Sites,
                promotions, surveys, and special events that might appeal to
                your interests by following the instructions located at the
                bottom of any commercial emails you may receive, or by emailing
                us as set forth below. Please note that, regardless of your
                request, we may still use and disclose certain information as
                permitted by applicable law. For example, you may not opt out of
                certain operational or transactional emails, such as those
                reflecting our relationship or transactions with you, or
                important notifications regarding the Sites we are providing to
                you.
              </p>
              <h3>
                <strong>Cookies</strong>
              </h3>
              <p>
                A cookie is a small amount of information that&rsquo;s
                downloaded to your computer or device when you visit our Site.
                We use a number of different cookies, including functional,
                performance, advertising, and social media or content cookies.
                Cookies make your browsing experience better by allowing the
                website to remember your actions and preferences (such as login
                and region selection). This means you don&rsquo;t have to
                re-enter this information each time you return to the site or
                browse from one page to another. Cookies also provide
                information on how people use the website, for instance whether
                it&rsquo;s their first time visiting or if they are a frequent
                visitor.
              </p>
              <p>
                Cookies are also used to track which items are placed in
                shopping carts and which are abandoned. Cookies will be used to
                determine when to trigger shopping cart reminder messages
              </p>
              <p>
                We may use similar technologies such as web beacons, tags, or
                pixels, which are small, clear images embedded in web content
                that download to your computer when a webpage or email is
                loaded.
              </p>
              <p>
                Cookies set by us are called first-party cookies. We also use
                third-party cookies &ndash; which are cookies from a domain
                different than the domain of the website you are visiting
                &ndash; for our advertising, marketing, analytics, and
                communication efforts. More specifically, we use and may
                disclose to third parties data collected using the below
                categories of cookies and other tracking technologies for the
                below purposes.
              </p>
              <p className="my-3">
                <span size="2">
                  <u>Cookies Necessary for the Functioning of the&nbsp;</u>
                </span>
              </p>
              <p>
                <span size="2">
                  These cookies are necessary for the Website to function and
                  cannot be switched off in our systems. They are usually only
                  set in response to actions made by you which amount to a
                  request for services, such as setting your privacy
                  preferences, logging in, or filling in forms. You can set your
                  browser to block or alert you about these cookies, but some
                  parts of the Website will not work.&nbsp;
                </span>
              </p>
              {/* <table cellpadding="11" style="width: 507px;">
                <thead>
                  <tr>
                    <td style="border: none;padding: 0in;">
                      <p style="margin-bottom: 0.1in;line-height: 115%;text-align: left;background: transparent;">
                        <strong>Name</strong>
                      </p>
                    </td>
                    <td style="border: none;padding: 0in;">
                      <p style="margin-bottom: 0.1in;line-height: 115%;text-align: left;background: transparent;">
                        <strong>Function</strong>
                      </p>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style="border: none;padding: 0in;">
                      <p style="margin-bottom: 0.1in;line-height: 115%;text-align: left;background: transparent;">
                        <br />
                      </p>
                    </td>
                    <td style="border: none;padding: 0in;">
                      <p style="margin-bottom: 0.1in;line-height: 115%;text-align: left;background: transparent;">
                        <br />
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td style="border: none;padding: 0in;">
                      <p style="margin-bottom: 0.1in;line-height: 115%;text-align: left;background: transparent;">
                        <br />
                      </p>
                    </td>
                    <td style="border: none;padding: 0in;">
                      <p style="margin-bottom: 0.1in;line-height: 115%;text-align: left;background: transparent;">
                        <br />
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td style="border: none;padding: 0in;">
                      <p style="margin-bottom: 0.1in;line-height: 115%;text-align: left;background: transparent;">
                        <br />
                      </p>
                    </td>
                    <td style="border: none;padding: 0in;">
                      <p style="margin-bottom: 0.1in;line-height: 115%;text-align: left;background: transparent;">
                        <br />
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td style="border: none;padding: 0in;">
                      <p style="margin-bottom: 0.1in;line-height: 115%;text-align: left;background: transparent;">
                        <br />
                      </p>
                    </td>
                    <td style="border: none;padding: 0in;">
                      <p style="margin-bottom: 0.1in;line-height: 115%;text-align: left;background: transparent;">
                        <br />
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td style="border: none;padding: 0in;">
                      <p style="margin-bottom: 0.1in;line-height: 115%;text-align: left;background: transparent;">
                        <br />
                      </p>
                    </td>
                    <td style="border: none;padding: 0in;">
                      <p style="margin-bottom: 0.1in;line-height: 115%;text-align: left;background: transparent;">
                        <br />
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td style="border: none;padding: 0in;">
                      <p style="margin-bottom: 0.1in;line-height: 115%;text-align: left;background: transparent;">
                        <br />
                      </p>
                    </td>
                    <td style="border: none;padding: 0in;">
                      <p style="margin-bottom: 0.1in;line-height: 115%;text-align: left;background: transparent;">
                        <br />
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td style="border: none;padding: 0in;">
                      <p style="margin-bottom: 0.1in;line-height: 115%;text-align: left;background: transparent;">
                        <br />
                      </p>
                    </td>
                    <td style="border: none;padding: 0in;">
                      <p style="margin-bottom: 0.1in;line-height: 115%;text-align: left;background: transparent;">
                        <br />
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td style="border: none;padding: 0in;">
                      <p style="margin-bottom: 0.1in;line-height: 115%;text-align: left;background: transparent;">
                        <br />
                      </p>
                    </td>
                    <td style="border: none;padding: 0in;">
                      <p style="margin-bottom: 0.1in;line-height: 115%;text-align: left;background: transparent;">
                        <br />
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td style="border: none;padding: 0in;">
                      <p style="margin-bottom: 0.1in;line-height: 115%;text-align: left;background: transparent;">
                        <br />
                      </p>
                    </td>
                    <td style="border: none;padding: 0in;">
                      <p style="margin-bottom: 0.1in;line-height: 115%;text-align: left;background: transparent;">
                        <br />
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td style="border: none;padding: 0in;">
                      <p style="margin-bottom: 0.1in;line-height: 115%;text-align: left;background: transparent;">
                        <br />
                      </p>
                    </td>
                    <td style="border: none;padding: 0in;">
                      <p style="margin-bottom: 0.1in;line-height: 115%;text-align: left;background: transparent;">
                        <br />
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table> */}
              <p className="my-3">
                <u>Reporting and Analytics</u>
              </p>
              <p>
                These cookies allow us to measure how the Sites are being used,
                such as counting visits and understanding traffic sources so we
                can analyze and improve the performance of our Sites. They help
                us to know which pages are the most and least popular and see
                how visitors move around the Site.
              </p>
              {/* <table cellpadding="11" style="width: 469px;">
                <tbody>
                  <tr>
                    <td style="border: none;padding: 0in;">
                      <p style="margin-bottom: 0.1in;line-height: 115%;text-align: left;background: transparent;">
                        <strong>Name</strong>
                      </p>
                    </td>
                    <td style="border: none;padding: 0in;">
                      <p style="margin-bottom: 0.1in;line-height: 115%;text-align: left;background: transparent;">
                        <strong>Function</strong>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td style="border: none;padding: 0in;">
                      <p style="margin-bottom: 0.1in;line-height: 115%;text-align: left;background: transparent;">
                        <br />
                      </p>
                    </td>
                    <td style="border: none;padding: 0in;">
                      <p style="margin-bottom: 0.1in;line-height: 115%;text-align: left;background: transparent;">
                        <br />
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td style="border: none;padding: 0in;">
                      <p style="margin-bottom: 0.1in;line-height: 115%;text-align: left;background: transparent;">
                        <br />
                      </p>
                    </td>
                    <td style="border: none;padding: 0in;">
                      <p style="margin-bottom: 0.1in;line-height: 115%;text-align: left;background: transparent;">
                        <br />
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td style="border: none;padding: 0in;">
                      <p style="margin-bottom: 0.1in;line-height: 115%;text-align: left;background: transparent;">
                        <br />
                      </p>
                    </td>
                    <td style="border: none;padding: 0in;">
                      <p style="margin-bottom: 0.1in;line-height: 115%;text-align: left;background: transparent;">
                        <br />
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td style="border: none;padding: 0in;">
                      <p style="margin-bottom: 0.1in;line-height: 115%;text-align: left;background: transparent;">
                        <br />
                      </p>
                    </td>
                    <td style="border: none;padding: 0in;">
                      <p style="margin-bottom: 0.1in;line-height: 115%;text-align: left;background: transparent;">
                        <br />
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td style="border: none;padding: 0in;">
                      <p style="margin-bottom: 0.1in;line-height: 115%;text-align: left;background: transparent;">
                        <br />
                      </p>
                    </td>
                    <td style="border: none;padding: 0in;">
                      <p style="margin-bottom: 0.1in;line-height: 115%;text-align: left;background: transparent;">
                        <br />
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td style="border: none;padding: 0in;">
                      <p style="margin-bottom: 0.1in;line-height: 115%;text-align: left;background: transparent;">
                        <br />
                      </p>
                    </td>
                    <td style="border: none;padding: 0in;">
                      <p style="margin-bottom: 0.1in;line-height: 115%;text-align: left;background: transparent;">
                        <br />
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td style="border: none;padding: 0in;">
                      <p style="margin-bottom: 0.1in;line-height: 115%;text-align: left;background: transparent;">
                        <br />
                      </p>
                    </td>
                    <td style="border: none;padding: 0in;">
                      <p style="margin-bottom: 0.1in;line-height: 115%;text-align: left;background: transparent;">
                        <br />
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td style="border: none;padding: 0in;">
                      <p style="margin-bottom: 0.1in;line-height: 115%;text-align: left;background: transparent;">
                        <br />
                      </p>
                    </td>
                    <td style="border: none;padding: 0in;">
                      <p style="margin-bottom: 0.1in;line-height: 115%;text-align: left;background: transparent;">
                        <br />
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td style="border: none;padding: 0in;">
                      <p style="margin-bottom: 0.1in;line-height: 115%;text-align: left;background: transparent;">
                        <br />
                      </p>
                    </td>
                    <td style="border: none;padding: 0in;">
                      <p style="margin-bottom: 0.1in;line-height: 115%;text-align: left;background: transparent;">
                        <br />
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td style="border: none;padding: 0in;">
                      <p style="margin-bottom: 0.1in;line-height: 115%;text-align: left;background: transparent;">
                        <br />
                      </p>
                    </td>
                    <td style="border: none;padding: 0in;">
                      <p style="margin-bottom: 0.1in;line-height: 115%;text-align: left;background: transparent;">
                        <br />
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table> */}
              <p className="my-3">
                <u>Targeting and Advertising Cookies</u>
              </p>
              {/* <p>
                <br />
              </p> */}
              <p>
                <span size="2">
                  These cookies help us deliver advertisements that are more
                  tailored to you and your interests, as well as limit the
                  number of times you receive the same advertisement. We also
                  use these cookies to measure the effectiveness of our
                  advertising campaigns.&nbsp;
                </span>
              </p>
              {/* <p>
                <br />
                &nbsp;
              </p> */}
              {/* <table cellpadding="11" style="width: 469px;">
                <tbody>
                  <tr>
                    <td style="border: none;padding: 0in;">
                      <p style="margin-bottom: 0.1in;line-height: 115%;text-align: left;background: transparent;">
                        <strong>Name</strong>
                      </p>
                    </td>
                    <td style="border: none;padding: 0in;">
                      <p style="margin-bottom: 0.1in;line-height: 115%;text-align: left;background: transparent;">
                        <strong>Function</strong>
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table> */}
              {/* <p>
                <br />
              </p>
              <p>
                <br />
              </p>
              <p>
                <br />
                &nbsp;
              </p> */}
              <p>
                The length of time that a cookie remains on your computer or
                mobile device depends on whether it is a
                &ldquo;persistent&rdquo; or &ldquo;session&rdquo; cookie.
                Session cookies last until you stop browsing and persistent
                cookies last until they expire or are deleted. Most of the
                cookies we use are persistent and will expire between 30 minutes
                and two years from the date they are downloaded to your device.
              </p>
              <p>
                <span size="2">
                  You can control and manage cookies in various ways. Please
                  keep in mind that removing or blocking cookies can negatively
                  impact your user experience and parts of our Site may no
                  longer be fully accessible or functional. We may use cookies
                  from Google Analytics to understand usage of our
                  Websites.&nbsp;
                </span>
                <span size="2">
                  You can opt-out of Google Analytics by using&nbsp;
                </span>
                <a href="https://tools.google.com/dlpage/gaoptout/" target="_blank">
                  <span>
                    <u>Google Analytics Opt-out Browser Add-on</u>
                  </span>
                </a>
                .
              </p>
              <p>
                <span size="2">
                  Most browsers automatically accept cookies, but you can choose
                  whether or not to accept cookies through your browser
                  controls, often found in your browser&rsquo;s
                  &ldquo;Tools&rdquo; or &ldquo;Preferences&rdquo; menu. For
                  more information on how to modify your browser settings or how
                  to block, manage or filter cookies can be found in your
                  browser&rsquo;s help file or through such sites as&nbsp;
                </span>
                <a href="https://www.allaboutcookies.org/" target="_blank">
                  <span>
                    <u>www.allaboutcookies.org</u>
                  </span>
                </a>
                .
              </p>
              <p>
                Additionally, please note that blocking cookies may not
                completely prevent how we use and disclose information with
                third parties such as our advertising partners. To exercise your
                rights or opt-out of certain uses of your information by these
                parties, please follow the instructions in the &ldquo;Behavioral
                Advertising&rdquo; section above.
              </p>
              <p className="my-3">
                <u>Do Not Track</u>
              </p>
              <p>
                <span size="2">
                  Some web browsers may allow you to enable a do-not-track
                  feature that alerts the websites you visit that you do not
                  want your online activities to be tracked. Our Sites may not
                  recognize or react in response to do-not-track signals. At
                  present, no generally accepted standards exist on how
                  companies must respond to do-not-track signals. In the event a
                  final standard is established, we will assess and provide an
                  appropriate response to these signals.&nbsp;
                </span>
              </p>
              <h2>
                <strong>Data Retention and Security</strong>
              </h2>
              <p>
                <span size="2">
                  We may retain your Personal Information both during and after
                  the time you use our Websites, but we typically keep it no
                  longer than needed. For example, we may retain contact
                  information after you are a Website user to maintain our
                  internal financial or transactional records, to communicate
                  with you about our relationship, or in cooperation with law
                  enforcement agency requests.&nbsp;
                </span>
              </p>
              <p>
                The length of time for which we retain information depends on
                the purposes for which we collected and used it, requirements of
                applicable laws, the amount, nature, and sensitivity of the
                information, the potential risk of harm from unauthorized use or
                disclosure of the information, the resolution of any pending or
                threatened disputes, and enforcement of our agreements.
              </p>
              <p>
                When we no longer require the Personal Information, we will
                either delete or deidentify it or, if this is not possible,
                securely store it in accordance with this Privacy Policy and
                cease use of the Personal Information until deletion is
                possible. If we deidentify your Personal Information (so that it
                can no longer be associated with you and thus is no longer
                considered personal information under applicable laws), we may
                retain this information for longer periods
              </p>
              <p>
                We take technical and organizational security measures to
                protect the information provided we collect from loss, misuse,
                and unauthorized access, disclosure, alteration, or destruction.
                The safety and security of your information also depends on you.
                Where we have given you (or where you have chosen) a password
                for access to certain parts of our Websites, you are responsible
                for keeping this password confidential. Do not share your
                password with anyone.
              </p>
              <p>
                Unfortunately, the transmission of information via the internet
                is not completely secure. Although we use measures to protect
                your Personal Information, we cannot guarantee the security of
                your Personal Information. Any transmission of Personal
                Information is at your own risk. We are not responsible for
                circumvention of any privacy settings or security measures.
              </p>
              <p>
                <br />
                &nbsp;
              </p>
              {/* <p>
                <br />
                &nbsp;
              </p> */}
              <h3>
                <strong>Users Located Outside the United States</strong>
              </h3>
              <p>
                <span size="2">Our&nbsp;</span>
                Sites are hosted in and provided from the United States, the
                Sites are designed only for individuals in the United States,
                your use of our Sites is governed by United States law, and the
                Sites may not be appropriate, applicable, or compliant with laws
                in other regions. Users outside the United States are prohibited
                from using the Sites. If you are nonetheless able to access the
                Sites from outside the United States and elect to use the Sites,
                please be aware that information may be transferred to, stored,
                and processed in the United States where our systems and
                facilities, and those of our third-party providers and
                recipients of information, are located. The data protection and
                other laws of the United States might be different than those in
                your country. By using Sites, you consent to your information
                being transferred to our systems and facilities, and to the
                systems and facilities of those third parties to whom we
                disclose information, as described in this Privacy Policy.
              </p>
              <p>
                <span size="2">
                  IN ADDITION TO THE DISCLAIMERS SET FORTH IN THE TERMS, AND
                  SUBJECT TO ANY LIMITATIONS OF DISCLAIMERS IN THE TERMS, WE
                  DISCLAIM ALL WARRANTIES THAT THE SITES ARE SUITABLE OR
                  APPROPRIATE IN YOUR JURISDICTION OUTSIDE THE UNITED STATES OR
                  THAT THE SITES COMPLY WITH LAWS OUTSIDE THE UNITED STATES
                  RELATED TO THE PROCESSING OF PERSONAL INFORMATION.&nbsp;
                </span>
              </p>
              <h3>
                <strong>Third-Party Websites and Applications</strong>
              </h3>
              <p>
                Our Sites may provide links to or make available integrations
                with third-party websites or applications (&ldquo;Third-Party
                Sites&rdquo;). Such Third-Party Sites are outside our control
                and not covered by this Privacy Policy. We are not liable for
                any information, content, products, services, software, or other
                materials available on or through Third-Party Sites. The use of
                a Third-Party Site or any information or other content found on
                Third-Party Sites is subject to and governed by the terms and
                conditions of such Third-Party Sites. We encourage you to review
                the privacy notices posted on such Third-Party Sites.
              </p>
              <h3>
                <strong>Changes</strong>
              </h3>
              <p>
                We may update this Privacy Policy from time to time in order to
                reflect, for example, changes to our practices or for other
                operational, legal, or regulatory reasons. We will make the
                revised Privacy Policy accessible through the Sites, so you
                should review the Privacy Policy periodically. The &ldquo;Last
                Updated&rdquo; date included at the end of this Privacy Policy
                will indicate when it was last updated. If we make a material
                change to the Privacy Policy, you will be provided with
                appropriate notice in accordance with legal requirements. By
                continuing to access the Sites, you are confirming you have read
                and understand the latest version of this Privacy Policy.
              </p>
              <h3>
                <strong>Contact</strong>
              </h3>
              <p>
                <span size="2">
                  For more information about our privacy practices, if you have
                  questions, or if you would like to make a complaint, please
                  contact us by e-mail at&nbsp;
                </span>
                <span size="2">
                  <a href="mailto:privacy@carvonix.com">
                    privacy@carvonix.com{" "}
                  </a>

                  {/* <!-- This was left blank in the Speed Society version. I would suggest we create an inbox called privacy@carvonix.com that goes to two people in case one person is unavailable or out of the office. Perhaps Sudeep and Michael? --> */}
                </span>
                or by mail using the details provided below:
              </p>
              <p>Carvonix, LLC</p>
              <p>
                <span size="2">Attn: Legal&nbsp;</span>
              </p>
              <p>1400 East 69th Avenue</p>
              <p>Denver, CO 80229</p>
              <p>Last updated: November 2024</p>
              <p>
                <br />
                &nbsp;
              </p>
            </div>
          </div>
        </div>
      </div>
      <LandingFooter />
    </>
  );
};

export default PrivacyPolicy;
