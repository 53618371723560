import {
  combineReducers,
  configureStore,
  createAction,
} from "@reduxjs/toolkit";
import authSlice from "@redux/slices/authSlice/loginSlice";
import { loginApi } from "@redux/services/auth/loginService";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { signUpApi } from "@redux/services/auth/signUpService";
import signUpSlice from "@redux/slices/authSlice/signUpSlice";
import { feedsApi } from "@redux/services/mainFeed/feedService";
import { chatApi } from "@redux/services/messenger/messengerService";
import { commentsApi } from "@redux/services/commentsService/commentService";
import { reactionApi } from "@redux/services/reactionService/reactionService";
import { allCrewApi } from "@redux/services/crewService/allCrewService";
import { UserProfileApi } from "@redux/services/userProfile/UserProfileService";
import { componentPageApi } from "@redux/services/userProfile/BuildComponentService";
import { publicService } from "@redux/services/public/publicService";
import publicSlice from "@redux/slices/authSlice/publicSlice";
import { storyApi } from "@redux/services/Stories/storyServices";
import tokenSlice from "@redux/slices/authSlice/tokenSlice";
import { exploreApi } from "@redux/services/exploreService/exploreService";
import storySlice from "@redux/slices/Story/storySlice";
import feedSlice from "@redux/slices/feeds/feedSlice";
import muteSlice from "@redux/slices/Story/muteSlice";
import errorSlice from "@redux/slices/Error/errorSlice";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth", "signUp", "storyView","muted"],
};
export const resetStore = createAction("resetStore");
const reducers = {
  [authSlice.reducerPath]: authSlice.reducer,
  [signUpSlice.reducerPath]: signUpSlice.reducer,
  [signUpApi.reducerPath]: signUpApi.reducer,
  [feedsApi.reducerPath]: feedsApi.reducer,
  [chatApi.reducerPath]: chatApi.reducer,
  [commentsApi.reducerPath]: commentsApi.reducer,
  [reactionApi.reducerPath]: reactionApi.reducer,
  [allCrewApi.reducerPath]: allCrewApi.reducer,
  [storyApi.reducerPath]: storyApi.reducer,
  [UserProfileApi.reducerPath]: UserProfileApi.reducer,
  [componentPageApi.reducerPath]: componentPageApi.reducer,
  [publicService.reducerPath]: publicService.reducer,
  [publicSlice.reducerPath]: publicSlice.reducer,
  [tokenSlice.reducerPath]: tokenSlice.reducer,
  [exploreApi.reducerPath]: exploreApi.reducer,
  [storySlice.reducerPath]: storySlice.reducer,
  [feedSlice.reducerPath]: feedSlice.reducer,
  [muteSlice.reducerPath]: muteSlice.reducer,
  [errorSlice.reducerPath]: errorSlice.reducer
};

const rootReducer = combineReducers(reducers);

const appReducer = (state, action) => {
  if (action.type === resetStore.type) {
    storage.removeItem("persist:root");
    state = undefined;
  }
  return rootReducer(state, action);
};
const persistedReducer = persistReducer(persistConfig, appReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(
      loginApi.middleware,
      signUpApi.middleware,
      feedsApi.middleware,
      chatApi.middleware,
      storyApi.middleware,
      commentsApi.middleware,
      reactionApi.middleware,
      allCrewApi.middleware,
      UserProfileApi.middleware,
      componentPageApi.middleware,
      publicService.middleware,
      exploreApi.middleware
    ),
});

export const persistor = persistStore(store);

export default store;
