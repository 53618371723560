import React, { useEffect, useState } from "react";
import {
  ThreeDots,
  Reply_flag,
  Repost_Comment,
  Reply_Comment,
  DefaultUserImg,
  PlusMore,
  Delete_Flag,
  NewPlus,
} from "@utils/defaultImages";
import { isValidUrl } from "@utils/validUrl";
import { dateTimeFromNow } from "@utils/DateFormatter";
import ListingReplies from "./ListingReplies";
import { ReactComponent as Like } from "@assets/like.svg";
import {
  useLazyListAllRepliesQuery,
  useReactionOnCommentsMutation,
  useRemoveCommentsMutation,
} from "@redux/services/commentsService/commentService";
import CommentLoader from "@components/Skeleton/CommentLoader";
import { Link } from "react-router-dom";
import MentionsParser from "@components/Mention/MentionParser";
import { useSelector } from "react-redux";
import WarningModal from "@components/Modal/WarningModal";
import { toast } from "react-toastify";

const ListingComments = ({
  commentData,
  commentsIndex,
  setSelectedId,
  selectedId,
  commentList,
  setAllComments,
  setCommentsCount,
  commentsCount,
  setreplyData,
  replyData,
  selected,
  setSelected,
  addedReplyComment,
  setAddedREplyComment,
}) => {
  // {USESTATES}
  const user = useSelector((state) => state?.auth?.loginUserData);
  const [showBox, setShowBox] = useState(false);
  const [showReplies, setShowReplies] = useState(false); // State to toggle replies visibility
  const [removeComment, { isLoading: isDeleteLoading }] =
    useRemoveCommentsMutation();
  const [showDelete, setShowDelete] = useState(false);
  const [reactionCount, setReactionCount] = useState(
    commentData?.reactionCount || 0
  ); // Initial state for reaction count
  const [selectedReplyId, setselectedReplyId] = useState(null);

  const [isLikedReply, setIsLikedReply] = useState(
    commentData?.isReacted || false
  ); // Initial state for like status
  const [listReplies, setListReplies] = useState([]);
  const [currentReplyCount, setCurrentReplyCount] = useState(
    commentData?.replyCount
  );

  // {API CALLS}

  const [reactionOnComments, { data }] = useReactionOnCommentsMutation();
  const [
    listAllReplies,
    { data: { data: repliesData = [] } = [], isLoading, isFetching },
  ] = useLazyListAllRepliesQuery();
  // {FUNCTION CALL}
  const reactionComments = async (item, index) => {
    // Optimistically update the state
    const optimisticIsLiked = !isLikedReply;
    const optimisticReactionCount = isLikedReply
      ? reactionCount - 1
      : reactionCount + 1;
    setIsLikedReply(optimisticIsLiked);
    setReactionCount(optimisticReactionCount);
    const updatedParentComment = commentList.map((item) => {
      if (item.id === commentData.id) {
        return {
          ...item,
          isReacted: optimisticIsLiked,
          reactionCount: optimisticReactionCount,
        };
      }
      return item;
    });
    setAllComments(updatedParentComment);
    const body = {
      reactionType: !isLikedReply ? 1 : 0, // 0 for unlike, 1 for like
      modelId: item?.modelId,
      model: item?.model,
      commentId: item?.parentCommentId || item?._id,
    };

    try {
      const response = await reactionOnComments(body).unwrap();
      if (response?.code !== 200) {
        setIsLikedReply(!optimisticIsLiked);
        setReactionCount(reactionCount);
      }
    } catch (error) {
      toast.error(error?.data?.message);
    }
  };
  const listingReplies = async (_id, page = 1) => {
    const body = {
      commentId: _id,
      page: page,
    };
    try {
      const response = await listAllReplies(body).unwrap();
      if (page > 1) {
        const newReplies = response?.data?.docs.filter(
          (newReply) =>
            !listReplies.some(
              (existingReply) => existingReply.id === newReply.id
            )
        );
        setListReplies((prev) => [...prev, ...newReplies]);
      } else {
        setListReplies(response?.data?.docs);
      }
    } catch (error) {
      toast.error(error?.data?.message);
    }
  };
  // Toggle replies visibility
  const toggleRepliesVisibility = (_id) => {
    // setShowReplies(!showReplies);
    if (selected.includes(_id)) {
      const updatedSelected = selected.filter((item) => item !== _id);
      setSelected(updatedSelected);
      setListReplies([]);
    } else {
      setSelected((prevItems) => [...prevItems, _id]);
      // if (repliesData?.length === 0) {
      listingReplies(_id);
      // }
    }
  };

  const toggleBox = (id) => {
    if (selectedId === id) {
      setSelectedId(null);
      setShowBox(false);
    } else {
      setSelectedId(id);
      setShowBox(true);
    }
  };
  // console.log("repliesData", commentData);
  const handleAction = (action) => {
    if (action === "Delete") {
      setShowDelete(true);
    } else if (action === "Reply") {
      setreplyData(commentData);
      setSelectedId(null);
    }
  };
  const handleCloseDelete = (key) => {
    if (key === "sure") {
      handleDeleteComment();
    } else {
      setShowDelete(false);
    }
  };
  const handleDeleteComment = async () => {
    const id = selectedId;
    setShowDelete(false);
    setSelectedId(null);

    const previousComments = [...commentList];
    const previousCommentCount = commentsCount;

    try {
      const updatedComments = commentList.filter((item) => item.id !== id);
      const deletedComment = commentList.filter((item) => item.id === id);

      setAllComments(updatedComments);

      if (deletedComment[0]?.replyCount > 0) {
        const decreasedCount =
          commentsCount - (deletedComment[0].replyCount + 1);
        setCommentsCount(decreasedCount);
      } else {
        setCommentsCount(commentsCount - 1);
      }
      if (replyData?.id === id) {
        setreplyData(null);
      }
      await removeComment(id).unwrap();
    } catch (error) {
      toast.error(error?.data?.message);
      setAllComments(previousComments);
      setCommentsCount(previousCommentCount);
    }
  };

  useEffect(() => {
    if (
      selected.includes(commentData.id) &&
      addedReplyComment?.parentCommentId === commentData.id
    ) {
      const updatedList = [addedReplyComment, ...listReplies];
      setListReplies(updatedList);
      setAddedREplyComment(null);
    }
  }, [addedReplyComment]);

  return (
    <div className="user-comments px-3 mt-2 pt-2" key={commentData.id}>
      <div className="signle-comment">
        <div className="user_name d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center gap-2">
            <img
              src={
                isValidUrl(commentData?.user?.profileImage)
                  ? commentData?.user?.profileImage
                  : DefaultUserImg
              }
              alt="user"
              width={32}
              height={32}
            />
            <Link to={`/profile/${commentData?.user?._id}`}>
              <h5>{commentData?.user?.username}</h5>
            </Link>
            <div className="width"></div>
            <span>{dateTimeFromNow(commentData?.createdAt)}</span>
          </div>
          <div className="pointer">
            <ThreeDots
              onClick={() => {
                toggleBox(commentData.id);
              }}
            />
            {commentData.id === selectedId && (
              <div className="box">
                {[
                  Reply_Comment,
                  // Repost_Comment,
                  // user._id !== commentData.user.id ? Reply_flag : Delete_Flag,
                ].map((Icon, index) => (
                  <div
                    key={index}
                    className="d-flex align-items-center gap-2 mb-2 pointer"
                    onClick={() =>
                      handleAction(
                        [
                          "Reply",
                          "Repost",
                          user._id !== commentData.user.id
                            ? "Report"
                            : "Delete",
                        ][index]
                      )
                    }
                  >
                    <Icon />
                    <p>
                      {
                        [
                          "Reply",
                          "Repost",
                          user._id !== commentData.user.id
                            ? "Report"
                            : "Delete",
                        ][index]
                      }
                    </p>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        <div className="comment-desc d-flex justify-content-between">
          <div className="para">
            <p className="pb-2">
              <MentionsParser
                text={commentData?.commentText}
                mentionIds={commentData?.mentionList}
              />
            </p>
            <span className="pointer" onClick={() => setreplyData(commentData)}>
              Reply
            </span>
            {/* {commentData?.replyCount > 0 && (
              <div className="replies pt-2">
                <span className="horizontol">_____</span>
                <span
                  className="pointer"
                  onClick={() => {
                    toggleRepliesVisibility(commentData?._id);
                    // setSelected(commentData.id);
                  }}
                >
                  {
                    selected.includes(commentData.id)?
                     `Hide ${commentData?.replyCount} 
                     ${commentData?.replyCount > 1 ?"Replies" : "Reply"}`:
                    `View ${commentData?.replyCount}  ${
                      commentData?.replyCount > 1 ? "replies" : "reply"
                    } `
                  }
                </span>
              </div>
            )} */}
          </div>
          <div className="svg-like pt-2 d-flex flex-column gap-1">
            {/* <Like
              className={`pointer ${isLikedReply ? "thumbWhite" : ""}`}
              fill={`${isLikedReply ? "#387AFF" : "transparent"}`}
              onClick={() => {
                reactionComments(commentData);
              }}
            /> */}
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => {
                reactionComments(commentData);
              }}
              className={`pointer ${!isLikedReply && "ThumbSimple"}`}
            >
              <rect
                width="24"
                height="24"
                rx="12"
                fill={isLikedReply ? "#387AFF" : "transparent"}
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9.48149 9.43827C9.17078 9.80075 9 10.2624 9 10.7398V16.25C9 17.3546 9.89543 18.25 11 18.25H15.5808C16.5474 18.25 17.3757 17.5588 17.5486 16.6078L18.3213 12.3578C18.5445 11.1301 17.6014 10 16.3536 10H13.5L14.7209 6.33726C14.8811 5.85684 14.6636 5.33178 14.2106 5.10531C13.793 4.89649 13.2868 4.99871 12.9829 5.35323L9.48149 9.43827Z"
                fill={isLikedReply && "white"}
                stroke="white"
              />
              <path d="M6 17.5V10.75" stroke="white" stroke-linecap="round" />
            </svg>

            {reactionCount > 0 && <span>{reactionCount}</span>}
          </div>
        </div>
      </div>

      {/* TESTING REPLIES */}
      {isLoading || (isFetching && listReplies.length === 0) ? (
        <CommentLoader />
      ) : (
        selected.includes(commentData.id) && (
          <>
            {listReplies?.map((repley, index) => (
              <ListingReplies
                key={repley.id}
                repley={repley}
                repleyIndex={index}
                setreplyData={setreplyData}
                selectedReplyId={selectedReplyId}
                setselectedReplyId={setselectedReplyId}
                setSelectedId={setSelectedId}
                selectedId={selectedId}
                toggleBox={toggleBox}
                handleCloseDelete={handleCloseDelete}
                handleAction={handleAction}
                listReplies={listReplies}
                setListReplies={setListReplies}
                setCommentsCount={setCommentsCount}
                commentsCount={commentsCount}
                commentList={commentList}
                setAllComments={setAllComments}
                setSelected={setSelected}
                selected={selected}
              />
            ))}
            {repliesData?.hasNextPage && (
              <div className="more-content text-center mt-1">
                {!isFetching ? (
                  <img
                    src={NewPlus}
                    width={50}
                    className="pointer"
                    onClick={() =>
                      listingReplies(commentData?.id, repliesData?.nextPage)
                    }
                  />
                ) : (
                  <CommentLoader />
                )}
              </div>
            )}
          </>
        )
      )}
      {commentData?.replyCount > 0 && (
        <div className="replies pb-2">
          {!selected.includes(commentData.id) && (
            <span className="horizontol">_____</span>
          )}

          <span
            className="pointer"
            onClick={() => {
              toggleRepliesVisibility(commentData?._id);
              // setSelected(commentData.id);
            }}
          >
            {selected.includes(commentData.id)
              ? `Hide  ${commentData?.replyCount > 1 ? "replies" : "reply"}`
              : `View  ${commentData?.replyCount > 1 ? "replies" : "reply"} `}
          </span>
        </div>
      )}
      {showDelete && (
        <WarningModal
          open={showDelete}
          closeModal={handleCloseDelete}
          Loading={isDeleteLoading}
        />
      )}
    </div>
  );
};

export default ListingComments;
