import React, { useCallback, useEffect, useState } from "react";
import "../../containers/UserProfile/subComponents/TabSwitch/TabSwitch.scss";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useLazyGetUserFollowersQuery } from "@redux/services/userProfile/UserProfileService";
import { isValidUrl } from "@utils/validUrl";
import { useSelector } from "react-redux";
import FadingLoader from "@components/Skeleton/CrewSkeleton";
import { toast } from "react-toastify";
import NotFound from "@components/NotFound";
import { useDebounce } from "use-debounce";
import {
  DefaultUserPng,
  SearchIcon,
  ChevronRight,
  SeeData,
} from "@utils/defaultImages";
import { sliceStringAtWord } from "@utils/sliceStringWord";
import SpinnerLoader from "@components/Skeleton/SpinnerLoader";

const UserList = () => {
  /**
   * GLOBALLY STATES
   */
  const selfUser = useSelector((state) => state?.auth?.loginUserData);
  const { Id } = useParams();
  /**
   * USESTATES
   */
  const [searchResult, setSearchResult] = useState([]);
  const [inputSearch, setInputSearch] = useState("");
  const [page, setPage] = useState(1);
  /**
   * DEBOUNCE
   */
  const [debouncedSearch] = useDebounce(inputSearch, 1000);

  /**
   * SEARCH API
   */
  const [listAllFollowers, { data: { data } = [], isFetching }] =
    useLazyGetUserFollowersQuery();
  /**
   * FUNCTIONS CALL
   */

  const showFollowersList = async (searchTerm = "") => {
    const body = {
      page: page,
      userId: Id,
      search: searchTerm,
    };
    try {
      const response = await listAllFollowers(body).unwrap();
      setSearchResult((prevItems) =>
        page === 1
          ? response?.data?.docs
          : [...prevItems, ...response?.data?.docs]
      );
    } catch (error) {
      toast.error(error?.data?.message);
    }
  };

  const handleChange = (e) => {
    setInputSearch(e.target.value);
  };

  /**
   * USEEFFECT
   */
  useEffect(() => {
    // setIsApiComplete(false);
    showFollowersList(debouncedSearch);
  }, [debouncedSearch, page]);

  return (
    <>
      {/* Search bar */}
      <div className="full-search">
        <div className="search-form">
          <div className="search-fld">
            <SearchIcon />
            <input
              value={inputSearch}
              type="search"
              className="form-control"
              placeholder="Search people"
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
      {
        <div className="crew-list-content">
          {isFetching && page < 2 ? (
            <>
              <div className="list-block position-relative">
                <div className="container-fluid p-0">
                  <SpinnerLoader />
                </div>
              </div>
            </>
          ) : (
            <>
              {!isFetching && data?.totalDocs < 1 ? (
                <NotFound message="User" />
              ) : (
                searchResult?.map((crew) => (
                  <Link
                    to={`/view-chat/${crew?._id}`}
                    state={{ userObj: crew }}
                    className="d-flex justify-content-between align-items-center"
                    key={crew?._id}
                    style={{ cursor: "pointer" }}
                  >
                    <div
                      to={`/view-chat/${crew?._id}`}
                      state={{ userObj: crew }}
                      className="list-block-foll"
                    >
                      <img
                        src={
                          isValidUrl(crew?.profileImage)
                            ? crew?.profileImage
                            : DefaultUserPng
                        }
                        alt={crew?.username}
                        className="main-img"
                      />

                      <div className="list-info">
                        {/* <h4>{crew?.username}</h4> */}
                        <h4>
                          {crew?.username.length > 25
                            ? `${sliceStringAtWord(crew?.username, 25)}...`
                            : crew?.username}
                        </h4>

                        {/* <p style={{ whiteSpace: "nowrap" }}>
                            {crew?.firstName} {crew?.lastName}
                          </p> */}
                      </div>
                    </div>
                    <ChevronRight />
                  </Link>
                ))
              )}
              {searchResult?.length > 0 && data?.hasNextPage && (
                <div
                  className="text-center py-3"
                  onClick={() => setPage(data?.nextPage)}
                >
                  <SeeData role="button" />
                </div>
              )}
            </>
          )}
        </div>
      }
    </>
  );
};

export default UserList;
