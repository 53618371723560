import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { store, persistor } from "./store";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import "./css/index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "./responsive.scss";
import "react-toastify/dist/ReactToastify.css";
import CookiesWeb from "@containers/Cookies/CookiesWeb";
import { HelmetProvider } from "react-helmet-async";
import GlobalErrorBoundary from "@containers/Error/GlobalErrorBoundary";

// import 'bootstrap/dist/css/bootstrap.css';

const root = ReactDOM.createRoot(document.getElementById("root"));

// change modal to root

root.render(
  // <HelmetProvider>
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <SkeletonTheme baseColor="#313131" highlightColor="#525252">
            <GlobalErrorBoundary>
              <App />
              <CookiesWeb />
            </GlobalErrorBoundary>
          </SkeletonTheme>
        </PersistGate>
      </Provider>
    </BrowserRouter>
  // </HelmetProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
