/* global google */
import React, { useState } from "react";
import GoogleMapComponent from "components/Googlemap/GoogleMapComponent";
import "./UpComingCrewEvent.scss";
import { MultipleUsers } from "./MultipleUsers";
import { isValidUrl } from "@utils/validUrl";
import UpComingEventSkeleton from "components/Skeleton/UpComingEventSkeleton";
import moment from "moment";
import { UserImage } from "@utils/defaultImages";
import { useUpComingEventsQuery } from "@redux/services/crewService/allCrewService";
import { sliceStringAtWord } from "@utils/sliceStringWord";
import { Link } from "react-router-dom";
import useNavigateMap from "@utils/hooks/useNavigateMap";
window.google = window.google ? window.google : {};
export default function UpComingCrewEvents() {
  const { data: { data: { events } = {} } = {}, isLoading } =
    useUpComingEventsQuery();
  return (
    <>
      {isLoading ? (
        <UpComingEventSkeleton />
      ) : (
        events?.docs?.length > 0 && (
          <div className="sticky-clm-2">
            <div className="text-wrapper">Featured Events</div>
            <p className="para-wrapper">Crews with upcoming events near you.</p>
            {events?.docs?.slice(0, 3)?.map((item, index) => {
              return (
                <Link
                  to={`/crew-event/detail/${item?._id}`}
                  key={index}
                  className="pointer"
                >
                  <div className="event-tile">
                    <GoogleMapComponent
                      detailEventData={item}
                      eventMapHeightType={1}
                    />
                    <div className="frame">
                      <div className="div">
                        <div className="sept">
                          {moment(item?.startDateTime).format("MMM").toUpperCase()}
                        </div>
                        <div className="text-wrapper">
                          {" "}
                          {moment(item?.startDateTime).format("D")}
                        </div>
                      </div>
                      {/* <img className="line" alt="Line" src="line-166.svg" /> */}
                      <div style={{
                        borderLeft: "1px solid #323334",
                        height: "60px"
                      }}></div>
                      <div className="group">
                        <div className="text-wrapper-2">
                          {sliceStringAtWord(item?.title, 20)}
                        </div>
                        <p className="p">
                          {item?.location[0]?.name || item?.location?.name}
                        </p>
                        {item?.attendeeCount > 0 && (
                          <div className="group-2">
                            <div className="text-wrapper-3">
                              {item?.attendeeCount} attending
                            </div>
                            <MultipleUsers
                              crewMembers={item?.attendeeProfiles}
                              className="multiple-users-instance"
                              property1="users-sm"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="frame-2">
                      <img
                        className="rectangle-2"
                        alt={item?.crew?.name}
                        src={
                          isValidUrl(item?.crew?.bannerImage)
                            ? item?.crew?.bannerImage
                            : UserImage
                        }
                      />
                      <div className="text-wrapper-4">{item?.crew?.name}</div>
                    </div>
                  </div>
                </Link>
              );
            })}
          </div>
        )
      )}
    </>
  );
}
