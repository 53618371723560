import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithReauth from "../baseQuery";

export const UserProfileApi = createApi({
  reducerPath: "UserProfileApi",
  tagTypes: ["followbutton", "getbuild"],
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getUserProfile: builder.query({
      query: (id) => ({
        url: "users/get-profile",
        method: "GET",
        params: {
          userId: id,
        },
      }),
      providesTags: ["followbutton"],
    }),
    getOtherProfile: builder.query({
      query: (id) => ({
        url: "users/get-profile",
        method: "GET",
        params: {
          userId: id,
        },
      }),
    }),
    getUserVehicle: builder.query({
      query: (id) => ({
        url: "build",
        method: "GET",
        params: {
          userId: id,
          sortBy: "createdAt:asc",
          limit: 4,
          page: 1,
        },
      }),
    }),
    getUserCrews: builder.query({
      query: (id) => ({
        url: "crew", // API of user's crews
        method: "GET",
        params: {
          userId: id,
          sortBy: "createdAt:asc",
          limit: 3,
          page: 1,
        },
      }),
    }),
    getUserModeratorCrews: builder.query({
      query: (value) => ({
        url: "/crew/join-crew/list/member-moderator", //api for moderator in modal
        method: "GET",
        params: {
          userId: value?.userId,
        },
      }),
    }),
    getModeratorCrews: builder.query({
      query: (id) => ({
        url: "/crew/join-crew/list/member-moderator", //api for moderator in modal
        method: "GET",
        params: {
          userId: id,
        },
      }),
    }),
    getUserJoinedCrews: builder.query({
      query: () => ({
        url: "/crew/join/list", //Api for user as a member
        method: "GET",
        params: {
          sortBy: "createdAt:asc",
          limit: 6,
          page: 1,
        },
      }),
    }),
    getUserPosts: builder.query({
      query: ({ pageCountForPost, userId }) => ({
        url: `posts/get/by-user`,
        method: "GET",
        params: {
          userId: userId,
          sortBy: "createdAt:asc",
          limit: 6,
          page: pageCountForPost,
        },
      }),
    }),
    getUserFollowers: builder.query({
      query: ({ page, userId, search }) =>
        // console.log("page--->", page),
        ({
          url: `users/followers/list`,
          method: "GET",
          params: {
            page: page,
            userId: userId,
            username: search,
          },
        }),
    }),
    getUserFolowings: builder.query({
      query: ({ page, userId, search }) => ({
        url: `/users/following/list`,
        method: "GET",
        params: {
          userId: userId,
          page: page,
          username: search,
        },
      }),
    }),
    getBuildFollowVehicles: builder.query({
      query: ({ page, userId, search }) => ({
        url: `/build/following/list`,
        method: "GET",
        params: {
          userId: userId,
          page: page,
          name: search,
        },
      }),
    }),
    followProfile: builder.mutation({
      query: (value) => ({
        url: `/users/follow/user`,
        method: "POST",
        body: value,
      }),
      invalidatesTags: ["followbutton"],
    }),
    removefollowProfile: builder.mutation({
      query: (removeId) => ({
        url: `/users/remove-following/${removeId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["followbutton"],
    }),
    getBuildDataById: builder.query({
      query: (buildId) => ({
        url: `build/${buildId}`,
        method: "GET",
      }),
      providesTags: ["getbuild"],
    }),
    getFollowCountOfBuild: builder.query({
      query: (buildId) => ({
        url: `/build/follow/count/${buildId}`,
        method: "GET",
      }),
    }),
    getAllPostsForBuildId: builder.query({
      query: ({ buildId, page = 1 }) => ({
        url: `/posts/get/by-parent`,
        method: "GET",
        params: {
          parentType: 1,
          buildId: buildId,
          sortBy: "createdAt:desc",
          limit: 6,
          page: page,
        },
      }),
    }),
    getAllModsCategories: builder.query({
      query: (buildId) => ({
        url: `/posts/category/list?buildId=${buildId}`,
        method: "GET",
      }),
    }),
    getModsByCategoriesId: builder.query({
      query: ({ buildId, CategoryId, page }) => ({
        url: `/posts/category/component/list/${buildId}/${CategoryId}`,
        method: "GET",
        params: {
          sortBy: "createdAt:desc",
          limit: 10,
          page: page,
        },
      }),
    }),
    followBuildVehicle: builder.mutation({
      query: (value) => ({
        url: `/build/follow`,
        method: "POST",
        body: value,
      }),
      invalidatesTags: ["getbuild"],
    }),
    UnfollowBuildVehicle: builder.mutation({
      query: (Id) => ({
        url: `/build/unfollow/${Id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["getbuild"],
    }),
    LinkToSpeedSociety: builder.mutation({
      query: () => ({
        url: `/users/link/speed-society`,
        method: "POST",
      }),
    }),
    UnlinkToSpeedSociety: builder.mutation({
      query: () => ({
        url: `/users/un-link/speed-society`,
        method: "POST",
      }),
    }),
    buildFollowersList: builder.query({
      query: ({ buildId, page, search }) => ({
        url: `build/followers/list/${buildId}`,
        method: "GET",
        params: {
          limit: 10,
          page: page,
          username: search,
        },
      }),
    }),
  }),
});
export const {
  useGetUserProfileQuery,
  useGetUserVehicleQuery,
  useGetUserCrewsQuery,
  useGetUserJoinedCrewsQuery,
  useLazyGetUserPostsQuery,
  useFollowProfileMutation,
  useRemovefollowProfileMutation,
  useLazyGetUserModeratorCrewsQuery,
  useGetModeratorCrewsQuery,
  useLazyGetUserFollowersQuery,
  useLazyGetUserFolowingsQuery,
  useLazyGetBuildFollowVehiclesQuery,
  useGetBuildDataByIdQuery,
  useGetFollowCountOfBuildQuery,
  useLazyGetAllPostsForBuildIdQuery,
  useGetAllModsCategoriesQuery,
  useLazyGetModsByCategoriesIdQuery,
  useFollowBuildVehicleMutation,
  useUnfollowBuildVehicleMutation,
  useLinkToSpeedSocietyMutation,
  useUnlinkToSpeedSocietyMutation,
  useLazyBuildFollowersListQuery,
  useLazyGetOtherProfileQuery
} = UserProfileApi;
