import React from "react";
import Followlist from "../FollowAccount/FollowList.js";

const ShowLikedUserModal = ({ modelId }) => {
  return (
    <>
      <div
        className="crew-list-content-search"
        style={{
          height: "485px",
          overflowY: "scroll",
          gridTemplateColumns: "1fr",
          display: "block",
          padding: "20px",
        }}
      >
        <Followlist modelId={modelId} />
      </div>
    </>
  );
};

export default ShowLikedUserModal;
