// import React, { Suspense, useEffect, useMemo, useState } from "react";
// import ReactInstaStories from "react-insta-stories";
// import { isValidUrl } from "@utils/validUrl";
// import { dateTimeFromNow } from "@utils/DateFormatter";
// import { useInView } from "react-intersection-observer";
// import { DefaultUserImg } from "@utils/defaultImages";
// import { useSelector } from "react-redux";
// const CustomFooter = React.lazy(() => import("../storiesView/CustomFooter"));

// const StoryComponent = ({
//   storyItem,
//   storyIndex,
//   currentIndex,
//   setCurrentIndex,
//   handleStoriesWatch,
//     // handleStoriesEnd,
//   storyStyles,
//   dynamicHeight,
// }) => {
//   const [isVisible, setIsVisible] = useState(false);
//   const [hasInitialized, setHasInitialized] = useState(false);
//   const mutestory = useSelector((state) => state.muted.isMuteStory);

//   const { ref } = useInView({
//     threshold: 0.7,
//     onChange: (inView) => {
//       setIsVisible(inView);
//       if (inView && !hasInitialized) {
//         setHasInitialized(true);
//         setCurrentIndex(storyIndex);
//       } else if (inView) {
//         setCurrentIndex(storyIndex);
//       }
//     },
//   });

//   const firstUnseenStoryIndex = useMemo(
//     () => storyItem?.stories?.findIndex((story) => story?.isSeen === false),
//     [storyItem?.stories]
//   );

//   const storyConfig = useMemo(
//     () =>
//       storyItem?.stories?.map((storyData) => ({
//         header: {
//           heading: storyItem?.username,
//           subheading: dateTimeFromNow(storyData?.createdAt),
//           profileImage: isValidUrl(storyItem?.profileImage)
//             ? storyItem?.profileImage
//             : DefaultUserImg,
//         },
//         seeMore: ({ close }) => (
//           <div className="custom-header">
//             <div className="wrap-footer">
//               <Suspense fallback={<div>Loading footer...</div>}>
//                 <CustomFooter storyList={storyData} />
//               </Suspense>
//             </div>
//           </div>
//         ),
//         seeMoreCollapsed: () => (
//           <div className="custom-header">
//             <div
//               className="wrap-footer"
//               style={{ display: "flex", justifyContent: "flex-end" }}
//             >
//               <Suspense fallback={<div>Loading footer...</div>}>
//                 <CustomFooter storyList={storyData} />
//               </Suspense>
//             </div>
//           </div>
//         ),
//         url: storyData?.media?.path,
//         type: storyData?.media?.type === "2" ? "video" : "image",
//         duration: (Math.round(storyData?.media?.duration) || 5) * 1000,
//         item: storyData,
//         getStory: storyItem,
//       })),
//     [storyItem]
//   );
//   /**
//    * USEEFFECT
//    */
//   useEffect(() => {
//     const handleVideoElements = () => {
//       const videoElements = document.querySelectorAll("video");
//       videoElements.forEach((video) => {
//         video.muted = mutestory;
//       });
//     };

//     handleVideoElements();

//     const observer = new MutationObserver((mutations) => {
//       mutations.forEach((mutation) => {
//         if (mutation.addedNodes.length) {
//           handleVideoElements();
//         }
//       });
//     });

//     observer.observe(document.body, {
//       childList: true,
//       subtree: true,
//     });

//     return () => {
//       observer.disconnect();
//     };
//   }, [mutestory]);

//   if (!hasInitialized) {
//     return (
//       <div
//         ref={ref}
//         className="story-placeholder"
//         style={{ height: dynamicHeight }}
//       />
//     );
//   }

//   return (
//     <div
//       ref={ref}
//       className="col-lg-12 my-3 d-flex justify-content-end for-video"
//     >
//       <ReactInstaStories
//         key={`story-${storyIndex}`}
//         stories={storyConfig}
//         onStoryStart={(index, story) => handleStoriesWatch(story)}
//         // onAllStoriesEnd={handleStoriesEnd}
//         storyInnerContainerStyles={storyStyles.innerStyle}
//         progressContainerStyles={storyStyles.progressStyle}
//         storyContainerStyles={storyStyles.containerStyle}
//         width="100%"
//         height={dynamicHeight}
//         currentIndex={firstUnseenStoryIndex !== -1 ? firstUnseenStoryIndex : 0}
//         // currentIndex={0}
//         isPaused={!isVisible || currentIndex !== storyIndex}
//       />
//     </div>
//   );
// };

// export default StoryComponent;
import React, { Suspense, useEffect, useMemo, useState } from "react";
import ReactInstaStories from "react-insta-stories";
import { isValidUrl } from "@utils/validUrl";
import { dateTimeFromNow } from "@utils/DateFormatter";
import { useInView } from "react-intersection-observer";
import { DefaultUserImg } from "@utils/defaultImages";
import { useSelector } from "react-redux";
const CustomFooter = React.lazy(() => import("../storiesView/CustomFooter"));

const StoryComponent = ({
  storyItem,
  storyIndex,
  currentIndex,
  setCurrentIndex,
  handleStoriesWatch,
  // handleStoriesEnd,
  storyStyles,
  dynamicHeight,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [hasInitialized, setHasInitialized] = useState(false);
  const mutestory = useSelector((state) => state.muted.isMuteStory);

  const { ref } = useInView({
    threshold: 0.3, // Lower threshold for better detection
    rootMargin: "50px 0px",
    onChange: (inView) => {
      setIsVisible(inView);
      if (inView && !hasInitialized) {
        setHasInitialized(true);
        setCurrentIndex(storyIndex);
      } else if (inView) {
        setCurrentIndex(storyIndex);
      }
    },
  });

  // Add effect to handle initialization when story becomes current
  useEffect(() => {
    if (storyIndex === currentIndex) {
      setHasInitialized(true);
      setIsVisible(true);
    }
  }, [storyIndex, currentIndex]);

  const firstUnseenStoryIndex = useMemo(
    () => storyItem?.stories?.findIndex((story) => story?.isSeen === false),
    [storyItem?.stories]
  );

  const storyConfig = useMemo(
    () =>
      storyItem?.stories?.map((storyData) => ({
        header: {
          heading: storyItem?.username,
          subheading: dateTimeFromNow(storyData?.createdAt),
          profileImage: isValidUrl(storyItem?.profileImage)
            ? storyItem?.profileImage
            : DefaultUserImg,
        },
        seeMore: ({ close }) => (
          <div className="custom-header">
            <div className="wrap-footer">
              <Suspense fallback={<div>Loading footer...</div>}>
                <CustomFooter storyList={storyData} />
              </Suspense>
            </div>
          </div>
        ),
        seeMoreCollapsed: () => (
          <div className="custom-header">
            <div
              className="wrap-footer"
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Suspense fallback={<div>Loading footer...</div>}>
                <CustomFooter storyList={storyData} />
              </Suspense>
            </div>
          </div>
        ),
        url: storyData?.media?.path,
        type: storyData?.media?.type === "2" ? "video" : "image",
        duration: (Math.round(storyData?.media?.duration) || 5) * 1000,
        item: storyData,
        getStory: storyItem,
      })),
    [storyItem]
  );

  useEffect(() => {
    const handleVideoElements = () => {
      const videoElements = document.querySelectorAll("video");
      videoElements.forEach((video) => {
        video.muted = mutestory;
      });
    };

    handleVideoElements();

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.addedNodes.length) {
          handleVideoElements();
        }
      });
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });

    return () => {
      observer.disconnect();
    };
  }, [mutestory]);

  if (!hasInitialized) {
    return (
      <div
        ref={ref}
        className="story-placeholder"
        style={{ height: dynamicHeight, minHeight: dynamicHeight }}
      />
    );
  }

  return (
    <div
      ref={ref}
      className="col-lg-12 my-3 d-flex justify-content-end for-video"
      style={{ minHeight: dynamicHeight }}
    >
      <ReactInstaStories
        key={`story-${storyIndex}`}
        stories={storyConfig}
        onStoryStart={(index, story) => handleStoriesWatch(story)}
        // onAllStoriesEnd={handleStoriesEnd}
        storyInnerContainerStyles={storyStyles.innerStyle}
        progressContainerStyles={storyStyles.progressStyle}
        storyContainerStyles={storyStyles.containerStyle}
        width="100%"
        height={dynamicHeight}
        currentIndex={firstUnseenStoryIndex !== -1 ? firstUnseenStoryIndex : 0}
        // currentIndex={0}
        isPaused={!isVisible || currentIndex !== storyIndex}
      />
    </div>
  );
};

export default StoryComponent;
