import { isValidUrl } from "@utils/validUrl";
import React, { useState } from "react";
import { sliceStringAtWord } from "@utils/sliceStringWord";
import { UserImg } from "@utils/defaultImages";
import { Link } from "react-router-dom";
import UserListThreeDot from "./UserListThreeDot";
import useMediaQueryHook from "@utils/hooks/useMediaQueryHook";

const ViewInbox = ({ items ,filterInboxList}) => {
  const { isMobile } = useMediaQueryHook();
  return (
    <>
      {items?.map((item) => {
        return (
          <div className="list-users" key={item?.roomId}>
            <Link
              className="list-item"
              to={`/view-chat/${item?.user?._id}`}
              state={{ userObj: item }}
            >
              <img
                src={
                  isValidUrl(item?.user?.profileImage)
                    ? item?.user?.profileImage
                    : UserImg
                }
                alt={item?.user?.username}
                className="main-img"
              />
              <div className="list-info">
                <h4>
                  {item?.user?.username}
                  {/* {item?.privacy !== 0 && (
                    <img className="status-image" src={Pin} alt="image" />
                  )} */}
                </h4>
                <p
                  style={{
                    color: item?.unseenMessageCount > 0 ? "#F5F5F5" : "#8E8E93",
                    fontWeight: item?.unseenMessageCount > 0 && "400",
                  }}
                >
                  {/* {sliceStringAtWord(item?.latestMessage?.messageContent, 30)} */}
                  {item?.latestMessage?.messageContent?.length > 30
                    ? `${(item?.latestMessage?.messageContent).slice(0, 27)}...`
                    : item?.latestMessage?.messageContent}
                </p>
              </div>
            </Link>

            <div className="ThreeDostInbox">
              {/* <div
                className={`messageThreeDots ${
                  openRoomId === item?.roomId ? "active" : ""
                }`}
                onClick={(e) => {
                  e.stopPropagation();
                  handleClick(item?.roomId);
                }}
              >
                <ThreeDots />
                {openRoomId === item?.roomId && (
                  <MessageInboxThreeDotsModal
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  />
                )}
              </div> */}
              {item?.unseenMessageCount > 0 && (
                <div className="message-count">
                  <p>{item?.unseenMessageCount}</p>
                </div>
              )}
              <UserListThreeDot itemMsg={item} filterInboxList={filterInboxList}/>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default ViewInbox;
