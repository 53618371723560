import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { isValidUrl } from "@utils/validUrl";
import _debounce from "lodash/debounce";
import SpinnerLoader from "components/Skeleton/SpinnerLoader";
import { useLazySearchAllCrewsQuery } from "@redux/services/crewService/allCrewService";
import { DefaultUserPng, SearchBarIcon } from "@utils/defaultImages";
import NotFound from "@components/NotFound";
import InfiniteScroll from "react-infinite-scroll-component";

const SearchAllCrew = () => {
  const [searchResult, setSearchResult] = useState([]);
  const [inputSearch, setInputSearch] = useState("");
  const [page, setPage] = useState(1);
  const [searchAllCrews, { data: { data } = {}, isLoading, isFetching }] =
    useLazySearchAllCrewsQuery();
  /**
   * SEARCH API ALL CREW
   */

  const handleSearch = useCallback(
    async (inputValue) => {
      if (inputValue !== "") {
        const body = {
          value: inputValue,
          page: page,
        };
        try {
          const response = await searchAllCrews(body).unwrap();
          setSearchResult((prevItems) =>
            page === 1
              ? response?.data?.docs
              : [...prevItems, ...response?.data?.docs]
          );
          // console.log("data???", response);
        } catch (error) {
          console.log("error", error);
        }
      }
    },
    [page, searchAllCrews]
  );
  /**
   * FUNCTIONS CALL
   */
  const debouncedHandleSearch = useCallback(
    _debounce((inputValue) => handleSearch(inputValue), 1000),
    []
  );

  const handleChange = (e) => {
    const inputValue = e.target.value;
    setInputSearch(inputValue);
    debouncedHandleSearch(inputValue);
  };

  const loadMore = useCallback(() => {
    if (!isFetching && data?.nextPage) {
      setPage(data?.nextPage);
    }
  }, [isFetching, data?.nextPage]);

  /**
   * USEEFFECT
   */
  useEffect(() => {
    handleSearch(inputSearch);
  }, [page, handleSearch]);
  return (
    <div className="full-search-crew">
      <div className="search-form">
        <div className="search-fld">
          <input
            value={inputSearch}
            type="search"
            className="form-control"
            placeholder="Search Carvonix Crews"
            onChange={(e) => handleChange(e)}
          />
          <Link to={inputSearch != "" ? "/crews/catalog" : undefined}>
            <button>
              <SearchBarIcon />
            </button>
          </Link>
        </div>
        <div
          className="search-dropdown"
          style={{
            padding: inputSearch ? "10px 0px" : "unset",
            zIndex: 111,
          }}
          id="search-dropdown"
        >
          {isLoading ? (
            <SpinnerLoader />
          ) : inputSearch && data?.docs?.length === 0 ? (
            <NotFound message="Result" />
          ) :

            inputSearch.length > 0 &&
            (
              <InfiniteScroll
                // style={{ overflow: "hidden" }}
                dataLength={searchResult?.length}
                next={loadMore}
                hasMore={!!data?.nextPage}
                loader={<SpinnerLoader />}
                // endMessage={
                //   <p style={{ textAlign: "center" }}>No more results</p>
                // }
                scrollableTarget="search-dropdown"
              >
                {inputSearch &&
                  searchResult?.map((searchItem) => {
                    return (
                      <Link to={`/crew-post/${searchItem?._id}`}>
                        <div className="result-loop">
                          <div className="search-profile">
                            <img
                              src={
                                isValidUrl(searchItem?.profileImage)
                                  ? searchItem?.profileImage
                                  : searchItem?.bannerImage
                                    ? searchItem?.bannerImage
                                    : DefaultUserPng
                              }
                              style={{
                                borderRadius: "20px",
                                width: "40px",
                                height: "40px",
                                objectFit: "cover",
                              }}
                              alt={searchItem?._id}
                              className="main-img"
                            />
                          </div>


                          <p style={{ margin: "unset" }}>{searchItem?.name}</p>

                          <svg
                            className="arrow-svg"
                            width="16"
                            height="17"
                            viewBox="0 0 16 17"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g id="back-right">
                              <path
                                id="Path 3"
                                d="M12.6673 9.16675V3.83341H7.33398"
                                stroke="white"
                                stroke-linecap="round"
                              />
                              <path
                                id="Path 31"
                                d="M12.6673 3.83325L3.33398 13.1666"
                                stroke="white"
                                stroke-linecap="round"
                              />
                            </g>
                          </svg>
                        </div>
                      </Link>
                    );
                  })}
              </InfiniteScroll>
            )}
        </div>
      </div>
    </div>
  );
};

export default SearchAllCrew;
