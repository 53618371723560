import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isMuteStory: true,
};

const muteSlice = createSlice({
  name: "muted",
  initialState,
  reducers: {
    setMuteStory(state, action) {
      state.isMuteStory = action.payload;
    },
  },
});

export const { setMuteStory } = muteSlice.actions;
export default muteSlice;
