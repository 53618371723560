import React from "react";
import "./headerFooter.scss";
import FooterLogo from "@assets/footer-logo.svg";
import Envlope from "@assets/envelope-alt.svg";
import Phone from "@assets/phone.svg";
import AppStore from "@assets/appstore.svg";
import GooglePlay from "@assets/googleplay.svg";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <footer
    // style={{ position: "relative", bottom: "0" }}
    >
      <div className="container">
        <div className="row footer">
          <div className="col-lg-3 col-md-6 mb-5">
            <div className="footerContent">
              <Link
                to="https://www.carvonix.com"
                target="blank"
                referrerPolicy="no-referrer"
              >
                <img src={FooterLogo} alt="" className="mb-3" />
              </Link>
              <div className="d-flex mb-2">
                <img src={Envlope} alt="" className="me-2" />
                <Link to="mailto:support@carvonix.com">
                  support@carvonix.com
                </Link>
              </div>
              <div className="d-flex">
                <img src={Phone} alt="" className="me-2" />
                <Link to="tel:+1 234-555-2222">+1 234-555-2222</Link>
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-md-6 mb-5">
            <div className="footerContent d-flex justify-content-between">
              {/* <div className="col ">
                <h4>Community</h4>
                <ul>
                  <li>
                    <Link to="/login">Crews</Link>
                  </li>
                  <li>
                    <Link to="/login">Builds</Link>
                  </li>
                  <li>
                    <Link to="/login">Parts & Components</Link>
                  </li>
                </ul>
              </div> */}
              {/* <div className="col ms-3">
                <h4>Company</h4>
                <ul>
                  <li>
                    <Link to="/login">About</Link>
                  </li>
                  <li to="/login">Support</li>
                  <li to="/login">Contact</li>
                </ul>
              </div> */}
            </div>
          </div>

          <div className="col-lg-4 col-md-6 mb-5">
            <div className="footerContent">
              <h4>Download the app</h4>
              <div className="d-flex">
                <Link to="/">
                  <img src={AppStore} alt="" className="me-3" />
                </Link>
                {/* <Link to="/">
                  <img src={GooglePlay} alt="" className="" />
                </Link> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{backgroundColor:"#121313"}}>
        <div className="container">
          <div className="row copyRights m-0">
            <div className="col-lg-5 col-md-5 col-sm-6 col-12">
              <p className="copyRightContent">
                © 2025 CARVONIX. All Rights Reserved.
              </p>
            </div>
            <div className="col-lg-7 col-md-7 col-sm-6 col-12">
              <ul className="termConditions d-flex float-end me-4">
                <li>
                  <Link to="/terms-and-conditions">Terms of use</Link>
                </li>
                <li>
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </li>
                {/* <li>
                  <Link to="/login">Cookie Policy</Link>
                </li> */}
              </ul>
            </div>
          </div>
        </div>

      </div>
    </footer>
  );
}
