import { dateTimeFromNow } from "@utils/DateFormatter";
import {
  DefaultUserImg,
  Reply_Comment,
  Reply_flag,
  Repost_Comment,
  ThreeDots,
  Delete_Flag,
} from "@utils/defaultImages";
import { ReactComponent as Like } from "@assets/like.svg";
import { isValidUrl } from "@utils/validUrl";
import React, { useState } from "react";
import {
  useReactionOnCommentsMutation,
  useRemoveCommentsMutation,
} from "@redux/services/commentsService/commentService";
import { Link } from "react-router-dom";
import MentionsParser from "@components/Mention/MentionParser";
import { useSelector } from "react-redux";
import WarningModal from "@components/Modal/WarningModal";
import { toast } from "react-toastify";

const ListingReplies = ({
  repley,
  repleyIndex,
  setreplyData,
  setSelectedId,
  selectedId,
  toggleBox,
  listReplies,
  setListReplies,
  setCommentsCount,
  commentsCount,
  setAllComments,
  commentList,
  setSelected,
  selected,
}) => {
  // {USESTATES}
  const [showBoxReply, setShowBoxReply] = useState(false);
  const user = useSelector((state) => state?.auth?.loginUserData);
  const [showDelete, setShowDelete] = useState(false);
  const [removeComment, { isLoading: isDeleteLoading }] =
    useRemoveCommentsMutation();

  const [reactionCountReply, setReactionCountReply] = useState(
    repley?.reactionCount || 0
  ); // Initial state for reaction count
  const [isLikedReplyNested, setIsLikedReplyNested] = useState(
    repley?.isReacted || false
  ); // Initial state for like status
  // {API CALLS}

  const [reactionOnComments, { data }] = useReactionOnCommentsMutation();
  // {FUNCTION CALL}
  const reactionCommentsReply = async () => {
    // Optimistically update the state
    const optimisticIsLiked = !isLikedReplyNested;
    const optimisticReactionCount = isLikedReplyNested
      ? reactionCountReply - 1
      : reactionCountReply + 1;
    setIsLikedReplyNested(optimisticIsLiked);
    setReactionCountReply(optimisticReactionCount);

    const body = {
      reactionType: !isLikedReplyNested ? 1 : 0, // 0 for unlike, 1 for like
      modelId: repley?.modelId,
      model: repley?.model,
      commentId: repley?._id,
    };
    try {
      const response = await reactionOnComments(body).unwrap();
      if (response?.code !== 200) {
        setIsLikedReplyNested(!optimisticIsLiked);
        setReactionCountReply(reactionCountReply);
      }
    } catch (error) {
      toast.error(error?.data?.message)

    }
  };
  // const toggleBoxReply = () => {

  // setShowBoxReply(!showBoxReply);
  // };

  const handleAction = (action) => {
    if (action === "Delete") {
      setShowDelete(true);
    } else if (action === "Reply") {
      setreplyData(repley);
      setSelectedId(null);
    }
  };
  const handleCloseDelete = (key) => {
    if (key === "sure") {
      handleDeleteComment();
    } else {
      setShowDelete(false);
    }
  };

  // const handleDeleteComment = async () => {
  //   const id = selectedId;
  //   setShowDelete(false);
  //   setSelectedId(null);
  //   const previousListReplies = [...listReplies];
  //   const previousCommentsCount = commentsCount;

  //   try {
  //     const updatedComments = listReplies.filter((item) => item.id !== id);
  //     setListReplies(updatedComments);
  //     setCommentsCount(prevCount => prevCount - 1);
  //     await removeComment(id).unwrap();

  //   } catch (error) {
  //     console.log(error);

  //     setListReplies(previousListReplies);
  //     setCommentsCount(previousCommentsCount);
  //   }
  // };

  // const handleDeleteComment = async () => {
  //   const id = selectedId;
  //   setShowDelete(false);
  //   setSelectedId(null);

  //   const previousListReplies = [...listReplies];
  //   const previousCommentsCount = commentsCount;
  //   const previousAllComments = [...commentList];

  //   try {
  //     const commentToDelete = listReplies.find((item) => item.id === id);
  //     const parentCommentId = commentToDelete.parentCommentId;

  //     const updatedComments = listReplies.filter((item) => item.id !== id);
  //     setListReplies(updatedComments);

  //     const updatedParentComment = commentList.map((item) => {
  //       if (item.id === parentCommentId) {
  //         return {
  //           ...item,
  //           replyCount: item.replyCount > 0 ? item.replyCount - 1 : 0,
  //         };
  //       }
  //       return item;
  //     });
  //     setSelected()
  //     setAllComments(updatedParentComment);

  //     setCommentsCount((prevCount) => prevCount - 1);

  //     // Attempt to remove the comment from the server
  //     await removeComment(id).unwrap();

  //   } catch (error) {
  //     console.log(error);

  //     // Rollback to previous states if error occurs
  //     setListReplies(previousListReplies);
  //     setCommentsCount(previousCommentsCount);
  //     setAllComments(previousAllComments); // Restore the previous state of all comments
  //   }
  // };
  const handleDeleteComment = async () => {
    const id = selectedId;
    setShowDelete(false);
    setSelectedId(null);

    const previousListReplies = [...listReplies];
    const previousCommentsCount = commentsCount;
    const previousAllComments = [...commentList];
    const previousSelected = [...selected];

    try {
      const commentToDelete = listReplies.find((item) => item.id === id);
      const parentCommentId = commentToDelete?.parentCommentId;

      // Filter out the deleted reply
      const updatedComments = listReplies.filter((item) => item.id !== id);
      setListReplies(updatedComments);

      // Update the parent comment's reply count
      const updatedParentComment = commentList.map((item) => {
        if (item.id === parentCommentId) {
          return {
            ...item,
            replyCount: item.replyCount > 0 ? item.replyCount - 1 : 0,
          };
        }
        return item;
      });

      setAllComments(updatedParentComment);

      // Update the selected array to remove the parentCommentId if replyCount becomes 0
      const parentComment = updatedParentComment.find(
        (item) => item.id === parentCommentId
      );
      if (parentComment?.replyCount === 0) {
        setSelected((prevSelected) =>
          prevSelected.filter((item) => item !== parentCommentId)
        );
      }

      setCommentsCount((prevCount) => prevCount - 1);

      // Attempt to remove the comment from the server
      await removeComment(id).unwrap();
    } catch (error) {
      toast.error(error?.data?.message)


      // Rollback to previous states if error occurs
      setListReplies(previousListReplies);
      setCommentsCount(previousCommentsCount);
      setAllComments(previousAllComments);
      setSelected(previousSelected); // Restore previous selected state
    }
  };

  return (
    <>
      <div className="wrap-all-replies" key={repleyIndex}>
        <div className="user_reply d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center gap-2">
            <img
              src={
                isValidUrl(repley?.user?.profileImage)
                  ? repley?.user?.profileImage
                  : DefaultUserImg
              }
              alt="user"
              width={32}
              height={32}
            />
            <Link to={`/profile/${repley?.user?._id}`}>
              <h5>{repley?.user?.username}</h5>
            </Link>
            <div className="width"></div>
            <span>{dateTimeFromNow(repley?.createdAt)}</span>
          </div>
          <div className="pointer">
            <ThreeDots
              onClick={() => {
                toggleBox(repley.id);
              }}
            />
          </div>
        </div>
        <div className="comment-reply d-flex justify-content-between">
          <div className="reply">
            <p className="pb-2">
              <MentionsParser
                text={repley?.commentText}
                mentionIds={repley?.mentionList}
              />
            </p>
            <span className="pointer" onClick={() => setreplyData(repley)}>
              Reply
            </span>
          </div>
          <div className="svg-like-reply pt-2 d-flex flex-column gap-1">
            <Like
              fill={`${isLikedReplyNested ? "#387AFF" : "transparent"}`}
              onClick={reactionCommentsReply}
              className="pointer"
            />
            <span>{reactionCountReply}</span>
          </div>
        </div>
        {/* {selectedIndex === repleyIndex && showBoxReply && (
          <div className="box">
            {[Reply_Comment, Repost_Comment, Reply_flag].map((Icon, index) => (
              <div
                key={index}
                className="d-flex align-items-center gap-2 p-2 pointer"
              >
                <Icon />
                <p>{["Reply", "Repost", "Report"][index]}</p>
              </div>
            ))}
          </div>
        )} */}
        {repley.id === selectedId && (
          <div className="box">
            {[
              Reply_Comment,
              Repost_Comment,
              user._id !== repley.user.id ? Reply_flag : Delete_Flag,
            ].map((Icon, index) => (
              <div
                key={index}
                className="d-flex align-items-center gap-2 p-2 pointer"
                onClick={() =>
                  handleAction(
                    [
                      "Reply",
                      "Repost",
                      user._id !== repley.user.id ? "Report" : "Delete",
                    ][index]
                  )
                }
              >
                <Icon />
                <p>
                  {
                    [
                      "Reply",
                      "Repost",
                      user._id !== repley.user.id ? "Report" : "Delete",
                    ][index]
                  }
                </p>
              </div>
            ))}
          </div>
        )}
        {showDelete && (
          <WarningModal open={showDelete} closeModal={handleCloseDelete} />
        )}
      </div>
    </>
  );
};

export default ListingReplies;
