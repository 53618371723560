import PopularLoader from "@containers/Auth/SignUp/Loader/PopularLoader";
import { DefaultImg, NotificationIcon } from "@utils/defaultImages";
import { sliceStringAtWord } from "@utils/sliceStringWord";
import { isValidUrl } from "@utils/validUrl";
import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import useHorizontalScroll from "@utils/hooks/useHorizontalScroll";
import { useListOnlineUserQuery } from "@redux/services/messenger/messengerService";

const UserScroll = () => {
  /**
   * API CALL
   */
  const { data: { data: onlineList } = [] } = useListOnlineUserQuery();

  const scrollRef = useHorizontalScroll();
  const handleScroll = () => {
    const el = scrollRef.current;
    if (el.scrollLeft + el.clientWidth >= el.scrollWidth) {
      // setPage((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    const el = scrollRef.current;
    if (el) {
      el.addEventListener("scroll", handleScroll);
      return () => el.removeEventListener("scroll", handleScroll);
    }
  }, []);

  // const handleScroll = (event) => {
  //   const container = event.target;
  //   const scrollAmount = event.deltaY;
  //   container.scrollTo({
  //     top: 0,
  //     left: container.scrollLeft + scrollAmount,
  //     behavior: 'smooth'
  //   });
  // };
  return (
    <>
      <div
        className="users"
        ref={scrollRef}
        // style={{ width: isMobile ? "40%" : "100%" }}
      >
        {onlineList?.map((item, index) => (
          <Link to={`/profile/${item?._id}`} key={item?._id}>
            <label>
              <div className="account-img">
                <img
                  alt={item?.username}
                  src={
                    isValidUrl(item?.profileImage)
                      ? item?.profileImage
                      : DefaultImg
                  }
                />
                {item?.isOnline && (
                  <div
                    className="notify-icon"
                    style={{
                      backgroundColor: item?.isOnline ? "#52FF00" : "grey",
                    }}
                  ></div>
                )}
              </div>
              {item?.username && (
                <div className="name">
                  {sliceStringAtWord(item?.username, 5)}
                  {/* {item?.username} */}
                </div>
              )}
            </label>
          </Link>
        ))}
      </div>
    </>
  );
};

export default UserScroll;
