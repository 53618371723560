import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { DefaultUserImg } from "@utils/defaultImages";
import { isValidUrl } from "@utils/validUrl";
import { sliceStringAtWord } from "@utils/sliceStringWord";
import FollowUnFollow from "@components/Button/FollowBtn/FollowUnFollow";
import DropDownFeed from "@components/DropDownProfile/DropMenuFeed";
import {
  useFollowProfileExploreMutation,
  useRemovefollowProfileExploreMutation,
} from "@redux/services/exploreService/exploreService";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { updateFollowStatus } from "@redux/slices/feeds/feedSlice";
import { useEffect } from "react";

const FeedHeader = React.memo(({ feedPost, isMobile, isTablet, index }) => {
  const dispatch = useDispatch();
  /**
   * LOGIN USER
   */
  const loginUserInfo = useSelector((state) => state?.auth?.loginUserData);
  /**
   * USESTATES
   */
  const [LoadingFollow, setLoadingFollow] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);

  /**
   * API CALLS
   */

  const [followProfile] = useFollowProfileExploreMutation();
  const [removefollowProfile] = useRemovefollowProfileExploreMutation();
  /**
   * FUNCTIONS
   */
  const followUnFollowProfile = async (userInfo, index) => {
    const body = {
      followToUserId: userInfo?.user?._id,
    };
    setLoadingFollow(true);
    setSelectedIndex(index);

    try {
      if (feedPost?.isFollow) {
        await removefollowProfile(userInfo?.user?._id).unwrap();
        dispatch(
          updateFollowStatus({ userId: userInfo?.user?._id, followerStatus: 0 })
        );
      } else {
        await followProfile(body).unwrap();

        dispatch(
          updateFollowStatus({ userId: userInfo?.user?._id, followerStatus: 1 })
        );
      }
    } catch (error) {
      toast.error(error?.data?.message);
    } finally {
      setLoadingFollow(false);
    }
  };

  return (
    <>
      <div className="feed-header">
        <div className="username">
          {feedPost?.parentType !== 2 ? (
            <>
              <Link
              // className="profile-link"
              // to={feedPost?.user?._id && `/profile/${feedPost?.user?._id}`}
              >
                <img
                  src={
                    isValidUrl(feedPost?.user?.profileImage)
                      ? feedPost?.user?.profileImage
                      : DefaultUserImg
                  }
                  alt=""
                />
              </Link>
              <div>
                <Link
                  // to={feedPost?.user?._id && `/profile/${feedPost?.user?._id}`}
                  to={feedPost?.user?._id && `/profile/${feedPost?.user?._id}`}
                  className="profile-link"
                >
                  <h5>
                    {feedPost?.user?.username?.length > 0 &&
                    (isTablet || isMobile)
                      ? sliceStringAtWord(feedPost?.user?.username, 15)
                      : feedPost?.user?.username}
                  </h5>
                </Link>
              </div>
            </>
          ) : (
            <div className="avtar-in">
              <div className="profile-link">
                <div className="car-image">
                  <img
                    src={
                      isValidUrl(feedPost?.build?.bannerImage)
                        ? feedPost?.build?.bannerImage
                        : DefaultUserImg
                    }
                    alt={`${feedPost?.build?.make}/${feedPost?.build?.model}/${feedPost?.build?.year?.value}`}
                    className="main-img"
                  />
                  <Link to={`/profile/${feedPost?.userId}`}>
                    <div className="user-image">
                      <img
                        src={
                          feedPost?.user?.profileImage
                            ? feedPost?.user?.profileImage
                            : DefaultUserImg
                        }
                        alt={feedPost?.user?.username}
                        className="main-img"
                      />
                    </div>
                  </Link>
                </div>
              </div>
              <div>
                <div className="name" style={{ wordBreak: "unset" }}>
                  <Link
                    to={`/profile/${feedPost?.userId}`}
                    style={{ color: "#f5f5f5" }}
                  >
                    <div className="profile-link">
                      {feedPost?.user?.username?.length > 0 &&
                      (isTablet || isMobile)
                        ? sliceStringAtWord(feedPost?.user?.username, 15)
                        : feedPost?.user?.username}
                    </div>
                  </Link>
                </div>
                <div className="d-flex align-items-center">
                  {/* {component?.user?.username} */}
                  {feedPost?.build?.name}
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="header-btn">
          {!feedPost?.refreshFollow && loginUserInfo?._id !== feedPost?.user?._id && (
            <FollowUnFollow
              followUnfollow={() => followUnFollowProfile(feedPost, index)}
              userInfo={feedPost}
              loading={LoadingFollow && selectedIndex === index}
              btnIndex={index}
              selectedIndex={selectedIndex}
            />
          )}
          <DropDownFeed
            itemMsg={feedPost}
            followUnfollow={() => followUnFollowProfile(feedPost, index)}
          />
        </div>
      </div>
    </>
  );
});

export default FeedHeader;
