// import { LoginApple } from "@utils/defaultImages";
// import React from "react";
// import AppleSignin, { appleAuthHelpers } from "react-apple-signin-auth";

// /** Apple Sign-in Button Component */
// const MyAppleSigninButton = () => {
//   const handleSignIn = async () => {
//     try {
//       const response = await appleAuthHelpers.signIn({
//         authOptions: {
//           clientId: "com.carvonix.webauthservice", // Replace with your client ID
//           scope: "email name", // Scopes separated by space
//           redirectURI: "https://carvonix.com/get-auth-information", // Replace with your redirect URI
//           state: "state",
//           nonce: "nonce",
//           usePopup: true, // Enables popup for client-side auth flow
//         },
//       });
//       if (response) {
//         console.log("Apple Sign-in Success:", response);
//       } else {
//         console.error("Apple Sign-in Error: Undefined response");
//       }
//     } catch (error) {
//       console.error("Apple Sign-in Error:", error);
//     }
//   };

//   return (
//     <AppleSignin
//       // authOptions={{
//       //   clientId: "com.carvonix.webauthservice", // Replace with your client ID
//       //   scope: "email name", // Scopes separated by space
//       //   redirectURI: "https://carvonix.com/get-auth-information", // Replace with your redirect URI
//       //   state: "state",
//       //   nonce: "nonce",
//       //   usePopup: true, // Enables popup for client-side auth flow
//       // }}
//       uiType="dark" // Button UI type: dark or light
//       className="apple-auth-btn"
//       noDefaultStyle={false}
//       buttonExtraChildren="Continue with Apple"
//       onSuccess={(response) => console.log("Sign-in Success:", response)}
//       onError={(error) => console.error("Sign-in Error:", error)}
//       skipScript={false}
//       iconProp={{ style: { marginTop: "10px" } }}
//       render={(props) => (
//         <LoginApple {...props} onClick={handleSignIn} />
//         // <button {...props} onClick={handleSignIn}>
//         //   My Custom Apple Button

//         // </button>
//       )}
//     />
//   );
// };

// export default MyAppleSigninButton;

// import React from "react";
// import AppleSignin, { appleAuthHelpers } from "react-apple-signin-auth";
// import { LoginApple } from "@utils/defaultImages";
// import { useDispatch } from "react-redux"; // Assuming Redux is being used
// import { setAuth, setLoginData } from "@redux/slices/authSlice/loginSlice.js";
// import {
//   setTokenAuth,
//   setTemporaryLoginData,
//   clearTokenAuth,
// } from "@redux/slices/authSlice/tokenSlice"; // Update with your correct imports
// import { useNavigate } from "react-router-dom"; // If you're using react-router
// import { useGoogleLoginMutation } from "@redux/services/auth/loginService.js";

// /** Apple Sign-in Button Component */
// const MyAppleSigninButton = () => {
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const [socialGoogle] = useGoogleLoginMutation();

//   const handleSignIn = async () => {
//     try {
//       const response = await appleAuthHelpers.signIn({
//         authOptions: {
//           clientId: "com.carvonix.webauthservice", // Replace with your client ID
//           scope: "email name", // Scopes separated by space
//           redirectURI: "https://carvonix.com/get-auth-information", // Replace with your redirect URI
//           state: "state",
//           nonce: "nonce",
//           usePopup: true, // Enables popup for client-side auth flow
//         },
//       });
//       console.log("response", response);
//       if (response) {
//         const {
//           user: { name, email },
//         } = response;
//         const sendData = {
//           // firstName: response?.user?.name?.firstName || "",
//           // lastName: response?.user?.name?.lastName || "",
//           // email: response?.user?.email || "",
//           firstName: name?.firstName || "",
//           lastName: name?.lastName || "",
//           email: email || "",
//           type: 3, // Assuming this is constant for your app
//           token: response?.authorization?.id_token,
//           socialId: response?.authorization?.code,
//           profileImage: "", // Apple doesn't provide a profile picture by default
//         };
//         console.log("Formatted Data for Backend:", sendData, response);
//         const loginResponse = await socialGoogle(sendData);

//         if (loginResponse?.data?.data?.tokens) {
//           if (loginResponse?.data?.data?.user?.isCompleted) {
//             dispatch(clearTokenAuth());
//             dispatch(setAuth(loginResponse?.data?.data?.tokens));
//             dispatch(setLoginData(loginResponse?.data?.data?.user));
//           } else {
//             dispatch(setTokenAuth(loginResponse?.data?.data?.tokens));
//             dispatch(setTemporaryLoginData(loginResponse?.data?.data?.user));
//             navigate("/congratulations-view");
//           }
//         } else {
//           navigate(`/waiting-list/${loginResponse?.data?.data?.waitingNumber}`);
//         }
//       } else {
//         console.error("Apple Sign-in Error: Undefined response");
//       }
//     } catch (error) {
//       console.error("Apple Sign-in Error:", error);
//     }
//   };

//   return (
//     <AppleSignin
//       uiType="dark" // Button UI type: dark or light
//       className="apple-auth-btn"
//       noDefaultStyle={false}
//       buttonExtraChildren="Continue with Apple"
//       onSuccess={(response) => console.log("Sign-in Success:", response)}
//       onError={(error) => console.error("Sign-in Error:", error)}
//       skipScript={false}
//       iconProp={{ style: { marginTop: "10px" } }}
//       render={(props) => <LoginApple {...props} onClick={handleSignIn} />}
//     />
//   );
// };

// export default MyAppleSigninButton;

// import React from "react";
// import AppleSignin, { appleAuthHelpers } from "react-apple-signin-auth";
// import { LoginApple } from "@utils/defaultImages";
// import { useDispatch } from "react-redux"; // Assuming Redux is being used
// import { setAuth, setLoginData } from "@redux/slices/authSlice/loginSlice.js";
// import {
//   setTokenAuth,
//   setTemporaryLoginData,
//   clearTokenAuth,
// } from "@redux/slices/authSlice/tokenSlice"; // Update with your correct imports
// import { useNavigate } from "react-router-dom"; // If you're using react-router
// import { useGoogleLoginMutation } from "@redux/services/auth/loginService.js";

// /** Apple Sign-in Button Component */
// const MyAppleSigninButton = () => {
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const [socialGoogle] = useGoogleLoginMutation();

//   const handleSignIn = async () => {
//     try {
//       const response = await appleAuthHelpers.signIn({
//         authOptions: {
//           clientId: "com.carvonix.webauthservice", // Replace with your client ID
//           scope: "email name", // Scopes separated by space
//           redirectURI: "https://carvonix.com/get-auth-information", // Replace with your redirect URI
//           state: "state",
//           nonce: "nonce",
//           usePopup: true, // Enables popup for client-side auth flow
//         },
//       });

//       console.log("Apple Response:", response);

//       // Extract user and authorization data
//       const { user = {}, authorization = {} } = response || {};
//       const { name = {}, email = "" } = user;

//       // Prepare data to send to the backend
//       const sendData = {
//         firstName: name?.firstName || "", // Fallback to empty string
//         lastName: name?.lastName || "",
//         email: email || "",
//         type: 3, // Assuming this is constant for your app
//         token: authorization?.id_token,
//         socialId: authorization?.code,
//         profileImage: "", // Apple doesn't provide a profile picture by default
//       };

//       console.log("Data Sent to Backend:", sendData);

//       // Hit the API regardless of whether name or email is present
//       const loginResponse = await socialGoogle(sendData);

//       if (loginResponse?.data?.data?.tokens) {
//         if (loginResponse?.data?.data?.user?.isCompleted) {
//           dispatch(clearTokenAuth());
//           dispatch(setAuth(loginResponse?.data?.data?.tokens));
//           dispatch(setLoginData(loginResponse?.data?.data?.user));
//         } else {
//           dispatch(setTokenAuth(loginResponse?.data?.data?.tokens));
//           dispatch(setTemporaryLoginData(loginResponse?.data?.data?.user));
//           navigate("/congratulations-view");
//         }
//       } else {
//         navigate(`/waiting-list/${loginResponse?.data?.data?.waitingNumber}`);
//       }
//     } catch (error) {
//       if (error?.user_cancelled) {
//         console.warn("User canceled Apple sign-in.");
//       } else {
//         console.error("Apple Sign-in Error:", error);
//       }
//     }
//   };

//   return (
//     <AppleSignin
//       uiType="dark" // Button UI type: dark or light
//       className="apple-auth-btn"
//       noDefaultStyle={false}
//       buttonExtraChildren="Continue with Apple"
//       onSuccess={(response) => console.log("Sign-in Success:", response)}
//       onError={(error) => console.error("Sign-in Error:", error)}
//       skipScript={false}
//       iconProp={{ style: { marginTop: "10px" } }}
//       render={(props) => <LoginApple {...props} onClick={handleSignIn} />}
//     />
//   );
// };

// export default MyAppleSigninButton;

import React, { useEffect, useCallback } from "react";
import AppleSignin, { appleAuthHelpers } from "react-apple-signin-auth";
import { LoginApple } from "@utils/defaultImages";
import { useDispatch } from "react-redux";
import { setAuth, setLoginData } from "@redux/slices/authSlice/loginSlice.js";
import {
  setTokenAuth,
  setTemporaryLoginData,
  clearTokenAuth,
} from "@redux/slices/authSlice/tokenSlice";
import { useNavigate } from "react-router-dom";
import { useGoogleLoginMutation } from "@redux/services/auth/loginService.js";
import { jwtDecode } from "jwt-decode"; // Make sure to install this package

const MyAppleSigninButton = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [socialGoogle] = useGoogleLoginMutation();

  const handleAuthResponse = useCallback(
    async (loginResponse) => {
      if (loginResponse?.data?.data?.tokens) {
        if (loginResponse?.data?.data?.user?.isCompleted) {
          dispatch(clearTokenAuth());
          dispatch(setAuth(loginResponse?.data?.data?.tokens));
          dispatch(setLoginData(loginResponse?.data?.data?.user));
        } else {
          dispatch(setTokenAuth(loginResponse?.data?.data?.tokens));
          dispatch(setTemporaryLoginData(loginResponse?.data?.data?.user));
          navigate("/congratulations-view");
        }
      } else {
        navigate(`/waiting-list/${loginResponse?.data?.data?.waitingNumber}`);
      }
    },
    [dispatch, navigate]
  );

  const handleSignIn = async () => {
    try {
      const response = await appleAuthHelpers.signIn({
        authOptions: {
          clientId: "com.carvonix.webauthservice",
          scope: "email name",
          redirectURI: "https://carvonix.com/get-auth-information",
          state: `apple_${Date.now()}`,
          nonce: `nonce_${Date.now()}`,
          usePopup: true,
        },
      });

      console.log("Apple Response:", response);

      if (!response) {
        throw new Error("No response from Apple Sign-in");
      }

      const { user = {}, authorization = {} } = response;

      // Decode the ID token to get the persistent user ID
      const decodedToken = jwtDecode(authorization?.id_token);
      console.log("decodedToken", decodedToken);
      const persistentUserId = decodedToken?.sub; // This is the persistent Apple user ID

      const sendData = {
        type: 3,
        token: authorization?.id_token,
        socialId: persistentUserId, // Use the persistent user ID instead of authorization code
        profileImage: "",
        email: user?.email || "test@gmail.com",
        firstName: user.name?.firstName || "first",
        lastName: user.name?.lastName || "last",
      };
      // Only add user info if it exists
      // if (user.name || user.email) {
      //   sendData.firstName = user.name?.firstName || "";
      //   sendData.lastName = user.name?.lastName || "";
      //   sendData.email = user.email || "";
      // }

      console.log("Data Sent to Backend:", sendData);

      const loginResponse = await socialGoogle(sendData);
      await handleAuthResponse(loginResponse);
    } catch (error) {
      if (error?.user_cancelled) {
        console.warn("User canceled Apple sign-in.");
      } else {
        console.error("Apple Sign-in Error:", error);
      }
    }
  };

  return (
    <AppleSignin
      uiType="dark"
      className="apple-auth-btn"
      noDefaultStyle={false}
      buttonExtraChildren="Continue with Apple"
      onSuccess={(response) => console.log("Sign-in Success:", response)}
      onError={(error) => console.error("Sign-in Error:", error)}
      skipScript={false}
      iconProp={{ style: { marginTop: "10px" } }}
      render={(props) => <LoginApple {...props} onClick={handleSignIn} />}
    />
  );
};

export default MyAppleSigninButton;
