import React, { useEffect, useState } from "react";
import "./SearchPage.scss";
import { useLocation } from "react-router";
import CategoryGaragePart from "@containers/ExplorePage/components/categoryBase/CategoryGaragePart";
import CategoryCrewArea from "@containers/ExplorePage/components/categoryBase/CategoryCrewArea";
import CategoryCrewAccounts from "@containers/ExplorePage/components/categoryBase/CategoryCrewAccounts";
import { Link } from "react-router-dom";
import { useLazyCategoriesBasedOnSearchQuery } from "@redux/services/exploreService/exploreService";
import { toast } from "react-toastify";
import { useDebounce } from "use-debounce";
import CategoryPosts from "@containers/ExplorePage/components/categoryBase/CategoryPosts";
import NotFound from "@components/NotFound";
import SpinnerLoader from "@components/Skeleton/SpinnerLoader";

const SearchResults = () => {
  const location = useLocation();
  const searchExplore = location?.state?.searchExplore || "";
  const initialvalues = {
    Builds: {},
    Crews: {},
    Accounts: {},
    Posts: {},
  };

  /**
   * USESTATES
   */
  const [userinfo, setUserInfo] = useState({
    years: [],
  });
  const [search, setSearch] = useState(searchExplore || "");
  const [activeCategory, setActiveCategory] = useState("");
  const [listCategories, setListCategories] = useState(initialvalues);
  /**
   * DEBOUNCE
   */
  const [debouncedSearch] = useDebounce(search, 1000);
  /**
   * API CALLS
   */
  const [exploreCategories, { isFetching }] =
    useLazyCategoriesBasedOnSearchQuery();
  /**
   * FUNCTIONS
   */
  const handleCategoryClick = (category) => {
    setActiveCategory(category);
  };

  const fetchCategories = async () => {
    const payload = {
      searchCategory: debouncedSearch,
    };
    try {
      const response = await exploreCategories(payload).unwrap();
      setListCategories({
        Builds: response?.data[0]?.value,
        Crews: response?.data[1]?.value,
        Accounts: response?.data[2]?.value,
        Posts: response?.data[3]?.value,
      });
    } catch (error) {
      toast.error(error?.data?.message);
    }
  };
  // console.log("response--->>", listCategories?.Builds);

  /**
   * USEEFFECT
   */

  useEffect(() => {
    fetchCategories();
  }, [debouncedSearch]);

  // useEffect(() => {
  //   const toggleIcons = (event) => {
  //     const icon = event.currentTarget.querySelector("i");
  //     if (icon.classList.contains("fa-chevron-down")) {
  //       icon.classList.remove("fa-chevron-down");
  //       icon.classList.add("fa-chevron-up");
  //     } else {
  //       icon.classList.remove("fa-chevron-up");
  //       icon.classList.add("fa-chevron-down");
  //     }

  //     const allIcons = document.querySelectorAll(".acc-btn i");
  //     allIcons.forEach((otherIcon) => {
  //       if (otherIcon !== icon) {
  //         otherIcon.classList.remove("fa-chevron-up");
  //         otherIcon.classList.add("fa-chevron-down");
  //       }
  //     });
  //   };

  //   const accordionButtons = document.querySelectorAll(".acc-btn");
  //   accordionButtons.forEach((button) => {
  //     button.addEventListener("click", toggleIcons);
  //   });

  //   return () => {
  //     accordionButtons.forEach((button) => {
  //       button.removeEventListener("click", toggleIcons);
  //     });
  //   };
  // }, []);
  const notFoundRecord = [
    !listCategories?.Builds?.docs?.length &&
      !listCategories?.Crews?.docs?.length &&
      !listCategories?.Accounts?.docs?.length &&
      !listCategories?.Posts?.docs?.length,
  ];
  return (
    <>
      <div
        className="searchResults-container"
        style={{
          gridColumn:
            // ((activeCategory === "" || activeCategory === "Accounts") &&
            //   !isMobile) ||
            // isTablet
            //   ?
            "2 / 4",
          // : "",
        }}
      >
        <div className="full-search">
          <div className="search-form-explore">
            <div className="search-fld">
              <input
                type="search"
                className="form-control"
                placeholder="Search for anything"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              {activeCategory !== "" && (
                <>
                  <span className="active-tag">
                    {activeCategory}
                    <svg
                      onClick={() => setActiveCategory("")}
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                    >
                      <path
                        d="M8.82869 8.82828L3.17184 3.17142"
                        stroke="white"
                        stroke-linecap="round"
                      />
                      <path
                        d="M8.82816 3.17142L3.17131 8.82828"
                        stroke="white"
                        stroke-linecap="round"
                      />
                    </svg>
                  </span>
                </>
              )}

              <button>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                >
                  <path
                    d="M12.0094 12.5102L16.666 17.1667"
                    stroke="#F5F5F5"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8.33398 13.8333C11.0954 13.8333 13.334 11.5947 13.334 8.83325C13.334 6.07183 11.0954 3.83325 8.33398 3.83325C5.57256 3.83325 3.33398 6.07183 3.33398 8.83325C3.33398 11.5947 5.57256 13.8333 8.33398 13.8333Z"
                    stroke="#F5F5F5"
                    stroke-width="1.5"
                  />
                </svg>
              </button>
            </div>
          </div>
          {/* <div className="search-cate">
              <span className="active">Builds</span>
              <span>Crews</span>
              <span>Accounts</span>
              <span>Businesses</span>
              <span>Tags</span>
              <span>Posts</span>
              <span>HowTo</span>
              <span>Reviews</span>
            </div> */}
          <div className="search-cate">
            {["Builds", "Crews", "Accounts", "Posts"].map((category) => (
              <Link
                to={`/explore/categories/${category}`}
                state={{ title: category, searchByName: searchExplore }}
                key={category}
                className={`${
                  category === activeCategory ? "active" : ""
                } active-category`}
                onClick={() => handleCategoryClick(category)}
              >
                {category}
              </Link>
            ))}
          </div>
          {/* {activeCategory == "Builds" && (
            <>
              <div className="view-all-heading">
                <h2>Builds</h2>
              </div>

              <ExploreBuild />
            </>
          )}
          {activeCategory == "Crews" && <ExploreCrew />}
          {activeCategory == "Accounts" && <ExploreAccounts />} */}
          {/* {activeCategory === "" && ( */}
          {isFetching ? (
            <SpinnerLoader />
          ) : notFoundRecord[0] ? (
            <NotFound message="Categories" />
          ) : (
            <>
              {/* Garage Row */}
              {listCategories?.Builds?.docs?.length > 0 && (
                <CategoryGaragePart listBuild={listCategories?.Builds} />
              )}
              {/* Crew list heading  */}
              {listCategories?.Crews?.docs?.length > 0 && (
                <CategoryCrewArea listCrews={listCategories?.Crews} />
              )}
              {/* Accounts details */}
              {listCategories?.Accounts?.docs?.length > 0 && (
                <CategoryCrewAccounts listAccount={listCategories?.Accounts} />
              )}
              {/* post details */}
              {listCategories?.Posts?.docs?.length > 0 && (
                <CategoryPosts listPost={listCategories?.Posts} />
              )}
            </>
          )}

          {/* )} */}
        </div>
      </div>
      {/* {activeCategory !== "" && activeCategory !== "Accounts" && (
        <ExploreFilters
          activeCategory={activeCategory}
          userinfo={userinfo}
          setUserInfo={setUserInfo}
        />
      )} */}
    </>
  );
};

export default SearchResults;
