import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Mousewheel, Navigation, Pagination, Scrollbar } from "swiper/modules";
import Bolt from "@assets/membershipDashboard/bolt.svg";
import UserImg from "@assets/defaultUser.png";
import Diamond from "@assets/membershipDashboard/Diamond.png";
import Locked from "@assets/lock@2x.png";

import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { isValidUrl } from "@utils/validUrl";

const IntractionMemberDashboard = ({ currentRank, medalList, userInfo }) => {
  const getRankName = (rank) => {
    const interactionRankName = [
      "Newbie",
      "Bronze Member",
      "Silver Member",
      "Gold Member",
      "Platinum Member",
      "Diamond Member",
    ];
    const interactionRankColors = [
      "rgba(142, 142, 147, 1)",
      "rgba(216, 172, 100, 1)",
      "#C5C5C5",
      "#FFAC0F",
      "#A7DAF8",
      "#8076FF",
    ];
    const lockedRankName = [
      "Newbie",
      "Bronze Membership",
      "Silver Membership",
      "Gold Membership",
      "Platinum Membership",
      "Diamond Membership",
    ];
    return {
      name: interactionRankName[rank - 1],
      color: interactionRankColors[rank - 1],
      lockedRankName: lockedRankName[rank - 1],
    };
  };

  return (
    <>
      <div className="memberLevelHeader">
        <div className="image-username">
          <img
            src={
              isValidUrl(userInfo?.profileImage)
                ? userInfo?.profileImage
                : UserImg
            }
            alt="user-image"
          />
          <div className="username">
            <p>{userInfo.username ? userInfo.username : ""}</p>
            <p
              style={{
                color:
                  getRankName(userInfo?.interactionPoint?.ranks)?.color ||
                  "#D8AC64",
              }}
            >
              {getRankName(userInfo?.interactionPoint?.ranks)?.name ||
                "Bronze Member"}
            </p>
          </div>
        </div>
        <div className="circularProgress">
          <CircularProgressbar
            value={userInfo.carvonixPointStats.percentage || 50}
            text={`${
              userInfo.carvonixPointStats.percentage
                ? Math.floor(userInfo.carvonixPointStats.percentage)
                : 50
            }%`}
            styles={{
              path: {
                stroke:
                  getRankName(userInfo?.interactionPoint?.ranks)?.color ||
                  "#D8AC0F",
              },
              text: {
                fill: "#FFFFFF",
                fontSize: "20px",
              },
              trail: {
                stroke: "#323334",
              },
            }}
          />
        </div>
      </div>
      <div className="memberLevelBody">
        {currentRank === 1 ? (
          <div className="newbie">
            <div className="newbie-paragraph">
              <p>Get Started Earning Carvonix Points</p>
            </div>
            <div className="newbie-button">
              <button>Watch Tutorial</button>
            </div>
            <div className="learn-more">
              <p>Learn more</p>
            </div>
          </div>
        ) : (
          <Swiper
            slidesPerView={3}
            centeredSlides={true}
            initialSlide={currentRank - 2}
            modules={[Scrollbar, Navigation, Mousewheel]}
            scrollbar={{ draggable: true }}
            mousewheel={{ forceToAxis: true }}
            className="mySwiper"
            autoplay={false}
            allowTouchMove={true}
            onSwiper={(swiper) => {
              if (swiper.activeIndex === 0) {
                swiper.slideTo(1);
              }
            }}
          >
            {medalList.slice(1).map((medal, index) => (
              <SwiperSlide
                key={index + 1}
                style={{ opacity: index < currentRank - 1 ? "1" : "0.5" }}
              >
                {index === medalList.length - 2 && currentRank === 6 ? (
                  <img src={Diamond} alt="Diamond" />
                ) : (
                  <>
                    <img src={medal.src} alt={medal.name} />
                    <div className="tier-information">
                      {index + 1 < currentRank - 1 ? (
                        <>
                          <p>Completed</p>
                          <div className="Bullets">
                            {Array.from({ length: medal.totalTiers }).map(
                              (_, i) => (
                                <div
                                  className="Bullet-Item"
                                  style={{ backgroundColor: "white" }}
                                  key={i}
                                ></div>
                              )
                            )}
                          </div>
                        </>
                      ) : index + 1 === currentRank - 1 ? (
                        index !== medalList.length - 2 && (
                          <>
                            <p>Tier {userInfo?.interactionPoint?.rankTiers}</p>
                            <div className="Bullets">
                              {Array.from({ length: medal.totalTiers }).map(
                                (_, i) => (
                                  <div
                                    className="Bullet-Item"
                                    key={i}
                                    style={{
                                      backgroundColor:
                                        i <
                                        userInfo?.interactionPoint?.rankTiers
                                          ? "white"
                                          : "#121313",
                                    }}
                                  ></div>
                                )
                              )}
                            </div>
                          </>
                        )
                      ) : (
                        <>
                          <div className="lockedRanks">
                            <img src={Locked} alt="Locked" />
                            <p>{getRankName(index + 2).lockedRankName}</p>
                          </div>
                        </>
                      )}
                    </div>
                  </>
                )}
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </div>
      <div className="memberFooter">
        <button disabled={true}>
          <img src={Bolt} alt="" />
          <p>
            {userInfo.carvonixPointStats.pointsToNextTier
              ? userInfo.carvonixPointStats.pointsToNextTier
              : 50}{" "}
            more points needed to advance to tier
          </p>
        </button>
      </div>
    </>
  );
};

export default IntractionMemberDashboard;
