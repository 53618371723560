import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { clearAuth } from "@redux/slices/authSlice/loginSlice";
import { SetclearRegToken } from "@redux/slices/authSlice/signUpSlice";
import Drawer from "./Drawer";
import "./SideBarMain.scss"; // Import your CSS file for additional styles
import store, { resetStore } from "store";
import { useLogoutAppMutation } from "@redux/services/auth/loginService";

function SideBarMain({ isMobile, isTablet, closeSidebar }) {
  /**
   * GET GLOBAL STATE
   */
  // {GLOBALLY GET USER LOGIN IN DATA}
  const userLoginToken = useSelector(
    (state) => state?.auth?.userAuthInfo?.access?.token
  );
  // const {isMobile} = useMediaQueryHook();
  const user = useSelector((state) => state?.auth?.userAuthInfo);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [openDrawer, setOpenDrawer] = useState(false);
  const listSideBar = [
    {
      path: "/main-feed",
      title: "Home",
    },
    {
      path: "/explore/all",
      title: "Explore",
    },
    {
      path: "/crews/all",
      title: "Crews",
    },
    {
      path: "/messenger",
      title: "Messenger",
    },
    // {
    //   path: "",
    //   title: "Events",
    // },
    // {
    //   path: "",
    //   title: "Notifications",
    // },
    // {
    //   path: "",
    //   title: "Settings",
    // },
    {
      path: "/profile",
      title: "Profile",
    },
    ...(isMobile
      ? [
          {
            path: "/logout",
            title: "Log Out",
          },
        ]
      : []),
  ];
  const [logoutApp] = useLogoutAppMutation();

  // Scroll to top function
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const handleLogout = async (e) => {
    e.preventDefault();
    // user?.tokens?.refresh?.token ||
    const body = {
      refreshToken: user?.refresh?.token,
    };
    if (user?.access?.token) {
      dispatch(clearAuth(""));
      dispatch(SetclearRegToken({}));
      store.dispatch(resetStore());
      navigate("/login");
      // dispatch(baseQueryWithReauth.util.resetApiState());

      await logoutApp(body).unwrap();

      // dispatch(storeUserId(""));
    }
  };

  const handleSidebar = (e, item) => {
    scrollToTop();
    isMobile && closeSidebar();
    item.path === "/logout" && handleLogout(e);
  };
  return (
    <>
      <div className={isMobile && userLoginToken && "sideBarMain"}>
        {userLoginToken && (
          <ul className={"profile-links-side"} >
            {listSideBar?.map((item, index) => (
              <li key={index}>
                <Link
                  to={item.path}
                  onClick={(e) => handleSidebar(e, item)}
                  className={`svg-container ${
                    location.pathname === item.path ? "activeStatus" : ""
                  }`}
                  style={{ justifyContent: isTablet && "center" }}
                >
                  {!item.isImage ? (
                    <img
                      src={require("assets/sideBarImages/" +
                        item.title +
                        ".svg")}
                      alt={item.title}
                      height={20}
                      width={20}
                    />
                  ) : (
                    <img
                      src={item?.Image}
                      alt={item.title}
                      height={20}
                      width={20}
                    />
                  )}
                  {!isTablet && (
                    <span className="sidebarLinks">{item.title}</span>
                  )}
                </Link>
              </li>
            ))}
          </ul>
        )}
      </div>
      {openDrawer && <Drawer closeDrawer={() => setOpenDrawer(false)} />}
    </>
  );
}

export default SideBarMain;
