import WarningModal from "@components/Modal/WarningModal";
import {
  useLazyDelSingleMsgQuery,
} from "@redux/services/messenger/messengerService";
import { Delete, Edit, VerticalDots } from "@utils/defaultImages";
import React, { useState } from "react";
import { toast } from "react-toastify";

const DropDown = ({ itemMsg, stateUpdateMsg, socket }) => {
  /**
   * USESTATE
   */
  const [openDelModal, setOpenDelModal] = useState(false);

  /**
   * API CALLS
   */
  const [singleDelChat] = useLazyDelSingleMsgQuery();

  const svgs = [
    { component: Edit, label: "Edit message" },
    // { component: Copy, label: "Copy" },
    { component: Delete, label: "Delete" },
  ];
  /**
   * FUNCTIONS
   */
  const handleAction = async (value, label) => {
    switch (label) {
      case "Edit message":
        return stateUpdateMsg(value);

      case "Delete":
        return setOpenDelModal(!openDelModal);
      default:
        return;
    }
  };
  const handleDelete = async () => {
    try {
      const res = await singleDelChat(itemMsg?._id)?.unwrap();
      if (res?.code === 200) {
        setOpenDelModal(!openDelModal);
        socket.emit("delete-message", {
          userId: itemMsg?.senderId,
          roomId: itemMsg?.roomId,
          chatId: itemMsg?._id,
          messageContent: itemMsg?.messageContent
        });
      }
    } catch (error) {
      toast.error(error?.data?.message);
    }
  };

  return (
    <>
      <div className="dropdown drop-wrapper">
        <button
          className="btn btn-secondary dropdown-toggle three-dots"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <VerticalDots />
        </button>
        <ul className="dropdown-menu dropdown-menu-dark wrapp-list">
          {svgs.map(({ component: SvgComponent, label }, index) => (
            <li key={index}>
              <div
                className="dropdown-item"
                onClick={() => handleAction(itemMsg, label)}
              >
                <SvgComponent className="dropdown-icon" />
                <span className="dropdown-label">{label}</span>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <WarningModal
        open={openDelModal}
        closeModal={setOpenDelModal}
        chatMsg={true}
        deleteBtn={handleDelete}
      />
    </>
  );
};

export default DropDown;
