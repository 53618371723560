import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  items: [],
};

const feedSlice = createSlice({
  name: "feed",
  initialState,
  reducers: {
    setFeedItems: (state, action) => {
      state.items = action.payload;
    },
    updateFollowStatus: (state, action) => {
      const { userId, followerStatus } = action.payload;
      state.items = state.items.map((post) =>
        post?.user?._id === userId
          ? { ...post, isFollow: followerStatus }
          : post
      );
    },
    deletePost: (state, action) => {
      const { ID } = action.payload;
      state.items = state.items.filter((post) => post?._id !== ID);
    },
    // handleLatestPost: (state, action) => {
    //   state.items = action.payload;
    // },
  },
});

export const {
  setFeedItems,
  updateFollowStatus,
  deletePost,
  handleLatestPost,
} = feedSlice.actions;

export default feedSlice;
