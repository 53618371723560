import React from "react";
import "../../UserProfile/UserProfile.scss";
import { BackArrow, ThreeDots } from "@utils/defaultImages";
import { useNavigate } from "react-router";

export default function ProfileHeaderById({ firstName, lastName }) {
  const navigate = useNavigate();

  return (
    <div className="container">
      <div className="head" style={{right:"0px"}}>
        <div className="back-button-byId" onClick={() => navigate(-1)}>
          <BackArrow />
        </div>
        <h1>
          {firstName} {lastName}
        </h1>
        <div className="dott">

        <ThreeDots />
        </div>
      </div>
    </div>
  );
}
