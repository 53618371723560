import React, { useEffect } from "react";
import "./Error.scss";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { clearError } from "@redux/slices/Error/errorSlice";
import ErrorHeader from "./component/ErrorHeader";
import Footer from "@components/layouts/Footer";

const ErrorPage = ({ title = "page" }) => {
  const dispatch = useDispatch();

  const handleDismiss = () => {
    dispatch(clearError());
  };
  return (
    <>
      <ErrorHeader />
      <div className="main-error">
        <div class="fof">
          <h1>Sorry, this {title} isn't available.</h1>
          <p>
            The link you followed may be broken, or the page may have been
            removed.{" "}
            <Link to="/" onClick={handleDismiss}>
              Go back to Carvonix.
            </Link>
          </p>
        </div>
      </div>
      <Footer/>
    </>
  );
};
export default ErrorPage;
