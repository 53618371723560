import { Link } from "react-router-dom";
import logo from "@assets/logo-header.svg";

function ErrorHeader() {
  return (
    <>
      <header>
        <div className="container headerContent justify-content-between p-3">
          <div className="row m-0 p-0 align-items-center">
            <div className="col-lg-3 col-md-4 col-sm-4 col-4 logo">
              <Link to="/" className="headerLogo">
                <img src={logo} alt="Carvonix Logo" />
              </Link>
            </div>

            {/* <div className="col-lg-9 col-md-8 col-sm-8 col-8 rightbarContent">
              <div className="rightbar" style={{ textAlign: "right" }}>
                <Link className="px-4" to="/login">
                  Log In
                </Link>
                <Link className="px-3 bg-primary" to="/user-info">
                  Sign Up
                </Link>
              </div>
            </div> */}
          </div>
        </div>
      </header>
    </>
  );
}

export default ErrorHeader;
