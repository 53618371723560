import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithReauth from "../baseQuery";

export const storyApi = createApi({
  reducerPath: "storyApi",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    listStories: builder.query({
      query: (page) => ({
        url: `/story/get-story/list`,
        method: "GET",
        // params: {
        //   ownStory: true,
        // },
        params: {
          page: page,
          limit: 10,
        },
      }),
    }),
    listSpotlight: builder.query({
      query: (page = 1) => ({
        url: `/posts/reels/video-link`,
        method: "GET",
        params: {
          sortBy: "createdAt:asc",
          limit: 10,
          page: page,
        },
      }),
    }),
    listUserStories: builder.query({
      query: (value) => ({
        url: `/story/user/own`,
        method: "GET",
        params: {
          userId: value.id,
          type: 3,
        },
      }),
    }),
    seenStory: builder.mutation({
      query: (id) => ({
        url: `story/seen-story/${id}`,
        method: "PATCH",
      }),
    }),
  }),
});

export const {
  useLazyListStoriesQuery,
  useLazyListSpotlightQuery,
  useLazyListUserStoriesQuery,
  useSeenStoryMutation,
} = storyApi;
